export default {
  draft: {
    key: 'Draft',
    name: 'Draft',
  },
  awaitingToSend: {
    key: 'AwaitingToSend',
    name: 'Awaiting To Send',
  },
  sent: {
    key: 'Sent',
    name: 'Sent',
  },
  expired: {
    key: 'Expired',
    name: 'expired',
  },
};
