<template>
  <ul class="soc">
    <li
      v-for="item in items"
      :key="item.id"
    >
      <a
        :href="item.link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          :src="item.icon"
          alt=""
          width="20"
        >
      </a>
    </li>
  </ul>
</template>

<script>
import FacebookIcon from '@/assets/images/landing/icons/facebook.svg';
import InstagramIcon from '@/assets/images/icons/instagram.svg';
import YoutubeIcon from '@/assets/images/landing/icons/youtube.svg';

export default {
  name: 'SocialNetworksList',
  data() {
    return {
      items: [
        {
          id: '0',
          icon: FacebookIcon,
          link: 'https://facebook.com/it.eon.official',
        },
        {
          id: '1',
          icon: YoutubeIcon,
          link: 'https://www.youtube.com/@it-eon',
        },
        {
          id: '2',
          icon: InstagramIcon,
          link: 'https://www.instagram.com/iteoncapsule/',
        },
      ],
    };
  },
};
</script>
