import Axios from 'axios';

const baseControllerRoute = 'time-capsules';

function getAvailableForPurchase() {
  return Axios.get(`${baseControllerRoute}`);
}

function getPurchased() {
  return Axios.get(`${baseControllerRoute}/purchased`);
}

function getCapsulePurchased(capsuleId) {
  return Axios.get(`${baseControllerRoute}/${capsuleId}/purchased`);
}

function getFiles(capsuleId) {
  return Axios.get(`${baseControllerRoute}/${capsuleId}/files`);
}

function getCertificate(capsuleId) {
  return Axios.get(`${baseControllerRoute}/${capsuleId}/certificate`, {
    responseType: 'blob',
  });
}

function postFiles(capsuleId, payload) {
  return Axios.post(`${baseControllerRoute}/${capsuleId}/files`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export default {
  getCertificate,
  getAvailableForPurchase,
  getPurchased,
  getFiles,
  postFiles,
  getCapsulePurchased,
};
