import './validation.bootstrap';
import './axios.bootstrap';
import './vue-scroll-to.bootstrap';
import './vue2-perfect-scrollbar.bootstrap';
import './constants.bootstrap';
import './vue-the-mask.bootstrap';
import './vue-inline-svg.bootstrap';
import './bootstrap-vue.bootstrap';
import './metronic.bootstrap';
import './config-debug.bootstrap';
import './vue-search-select.bootstrap';
import './vue-apex-charts.bootstrap';
import './vue-form-wizard.bootstrap';
import './loader.bootstrap';
import './vue-select.bootstrap';
import './vue-b-table-auto.bootstrap';

import Vue from 'vue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

import { SocialNetworksList } from '@/app/components';

Vue.component('DatePicker', DatePicker);
Vue.component('TimePicker', VueTimepicker);
Vue.component('SocialNetworksList', SocialNetworksList);
