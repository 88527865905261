import { admin } from '@constants/route-names';
import { layouts } from '@constants';
import component from './reset-password.page.admin';

export default [
  {
    path: '/admin/reset-password',
    name: admin.resetPassword,
    component,
    meta: {
      layout: layouts.adminBlank,
    },
  },
];
