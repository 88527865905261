<template>
  <section class="section section--fullpage">
    <div class="section__container container">
      <div class="section__wrap">
        <b-row class="justify-content-center">
          <b-col
            md="10"
            lg="8"
            xl="6"
          >
            <b-row>
              <b-col
                v-for="(capsule, capsuleIndex) in capsules"
                :key="capsuleIndex"
                sm="6"
                class="mb-6"
              >
                <capsule-card :capsule="capsule" />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import CapsuleTimeImg from '@/assets/images/landing/hero/main.png';
import CapsuleCloudImg from '@/assets/images/user-portal/capsule-cloud.png';

import { routes } from '@app/constants';
import { mapGetters } from 'vuex';
import CapsuleCard from './capsule-card';

export default {
  name: 'CapsulesCardsSection',
  components: {
    CapsuleCard,
  },
  data() {
    return {
      capsules: [
        {
          type: 'time',
          title: 'Time Capsule',
          img: CapsuleTimeImg,
          detailsLink: this.$constants.routes.user.capsules.time,
          detailsText: 'Go to Available Capsules',
        },
        {
          type: 'cloud',
          title: 'Cloud Capsule',
          img: CapsuleCloudImg,
          detailsLink: this.$constants.routes.user.capsules.cloud,
          detailsText: 'Details',
        },
      ],
      routes,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthorized',
    ]),
  },
};
</script>
