// Keenthemes plugins
import KTUtil from '@assets/js/components/util';
import KTCookie from '@assets/js/components/cookie';
import KTMenu from '@assets/js/components/menu';
import KTOffcanvas from '@assets/js/components/offcanvas';
import KTToggle from '@assets/js/components/toggle';
import KTScrolltop from '@assets/js/components/scrolltop';
// Metronic layout base js
import KTLayoutAside from '@assets/js/layout/base/aside';
import KTLayoutAsideMenu from '@assets/js/layout/base/aside-menu';
import KTLayoutAsideToggle from '@assets/js/layout/base/aside-toggle';
import KTLayoutBrand from '@assets/js/layout/base/brand';
import KTLayoutContent from '@assets/js/layout/base/content';
import KTLayoutHeader from '@assets/js/layout/base/header';
import KTLayoutHeaderMenu from '@assets/js/layout/base/header-menu';
import KTLayoutHeaderTopbar from '@assets/js/layout/base/header-topbar';

// Keenthemes plugins
window.KTUtil = KTUtil;
window.KTCookie = KTCookie;
window.KTMenu = KTMenu;
window.KTOffcanvas = KTOffcanvas;
window.KTToggle = KTToggle;
window.KTScrolltop = KTScrolltop;
// Metronic layout base js
window.KTLayoutAside = KTLayoutAside;
window.KTLayoutAsideMenu = KTLayoutAsideMenu;
window.KTLayoutAsideToggle = KTLayoutAsideToggle;
window.KTLayoutBrand = KTLayoutBrand;
window.KTLayoutContent = KTLayoutContent;
window.KTLayoutHeader = KTLayoutHeader;
window.KTLayoutHeaderMenu = KTLayoutHeaderMenu;
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar;
