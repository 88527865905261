<template>
  <section class="signin-page">
    <div class="signin-page__container container">
      <div class="signin-page__wrap">
        <b-row class="justify-content-center">
          <b-col
            md="6"
            xl="4"
          >
            <validation-observer
              ref="observer"
              tag="div"
            >
              <b-form
                class="card"
                @submit.prevent="signup"
              >
                <div class="card-body">
                  <h2 class="text-center text-primary font-weight-bold mb-5 mb-md-10">
                    Sign Up
                  </h2>

                  <b-alert
                    variant="danger"
                    :show="!!errorMessage"
                  >
                    {{ errorMessage }}
                  </b-alert>

                  <b-alert
                    variant="light-primary"
                    :show="!!infoMessage"
                  >
                    {{ infoMessage }}
                  </b-alert>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="First Name"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-6"
                  >
                    <b-form-input
                      id="first-name"
                      v-model="model.firstName"
                      type="text"
                      placeholder="First Name"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Last Name"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-6"
                  >
                    <b-form-input
                      id="last-name"
                      v-model="model.lastName"
                      type="text"
                      placeholder="Last Name"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="Email"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-6"
                  >
                    <b-form-input
                      id="email"
                      v-model="model.email"
                      type="text"
                      placeholder="Enter your email"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Password"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-8"
                  >
                    <b-form-input
                      id="password"
                      v-model="model.password"
                      type="password"
                      placeholder="Enter your password"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <div class="mb-6">
                    <b-btn
                      type="submit"
                      variant="user"
                      class="w-100"
                    >
                      Sign Up
                    </b-btn>
                  </div>

                  <div class="d-flex align-items-center justify-content-between">
                    <div class="mr-3">
                      <google-signin-btn @failed="googleSigninFailed" />
                    </div>

                    <router-link
                      :to="{ name: $constants.routes.user.signin, query: { returnUrl: returnUrl } }"
                      class="text-white font-weight-bold"
                    >
                      Sign In
                    </router-link>
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script lang="babel">
import { SIGNUP_USER } from '@services/store/profile.module';
import store from '@store';
import { messages } from '@constants';
import { GoogleSigninBtn } from '@components';

export default {
  name: 'SignInPage',
  components: {
    GoogleSigninBtn,
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
      },
      errorMessage: '',
      infoMessage: '',
      returnUrl: this.$route.query.returnUrl,
    };
  },
  watch: {
    '$route.params.infoMessage': {
      handler(val) {
        this.infoMessage = val;
      },
      immediate: true,
    },
  },
  methods: {
    async signup() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      try {
        this.clearMessages();
        await store.dispatch(SIGNUP_USER, this.model);
        if (this.returnUrl) {
          await this.$router.push(this.returnUrl);
        } else {
          await this.$router.push({ name: this.$constants.routes.user.capsules.index });
        }
      } catch (err) {
        if (err.response?.data?.errorMessages) {
          this.errorMessage = err.response?.data?.errorMessages.join('. ');
        } else {
          this.errorMessage = messages.unexpectedError;
        }
      }
    },
    googleSigninFailed(errorMessage) {
      this.errorMessage = errorMessage;
    },
    clearMessages() {
      this.errorMessage = '';
      this.infoMessage = '';
    },
  },
};
</script>
