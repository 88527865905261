<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <b-card>
          <b-form @submit.prevent="handleFilter">
            <b-row>
              <b-col md="3">
                <b-form-group label="Name">
                  <b-form-input
                    id="name"
                    v-model="filterModel.name"
                    type="text"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Location">
                  <b-form-input
                    id="location"
                    v-model="filterModel.location"
                    type="text"
                    placeholder="Location"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Sending Date">
                  <b-form-datepicker
                    id="dateSending"
                    v-model="filterModel.dateSending"
                    :date-format-options="dateFormat"
                    placeholder="Sending Date"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Status">
                  <v-select
                    id="status"
                    v-model="filterModel.status"
                    placeholder="Status"
                    :options="capsuleStatusesOptions"
                    label="text"
                    :searchable="false"
                    :reduce="status => status.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="d-flex flex-wrap border-top pt-5">
              <b-btn
                type="submit"
                variant="primary"
                class="mr-2"
              >
                Search
              </b-btn>

              <b-btn @click="clear">
                Clear
              </b-btn>
            </div>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-5">
      <b-col>
        <b-card>
          <b-btn
            variant="primary"
            @click="createNew"
          >
            Create New
          </b-btn>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-table-auto
            show-empty
            responsive
            hover
            outlined
            caption-top
            clickable
            show-total-items
            :paginate="true"
            :fields="fields"
            :load-data-callback="loadDataCallback"
            :filter-model.sync="filterModel"
            :filter-settings="filterSettings"
            @row-clicked="openDetails"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { capsulesApi } from '@services/api/admin';
import { TableEventBus } from '@utils/table';
import { capsuleStatuses } from '@app/constants';
import { selectHelper } from '@app/utils';
import { DateFormat, HumanBytesFormat } from '@app/formatters';

function getDefaultFilterModel() {
  return {
    name: '',
    location: '',
    dateSending: '',
    status: '',
  };
}

const FilterSettings = {
  placeholder: 'Search',
  searchBtnText: 'Search',
  isClearBtn: true,
  clearBtnText: 'Clear',
  key: 'filter',
  isComplex: true,
};

export default {
  name: 'AdminCapsulesPage',
  data() {
    return {
      filterModel: getDefaultFilterModel(),
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'location',
          label: 'Location',
          sortable: true,
        },
        {
          key: 'dateSending',
          label: 'Sending Date',
          sortable: true,
          formatter: (value) => DateFormat(value, 'MMM DD YYYY'),
        },
        {
          key: 'size',
          label: 'Size',
          sortable: true,
          formatter: (value) => HumanBytesFormat.humanize(value),
        },
        {
          key: 'used',
          label: 'Loaded',
          sortable: true,
          formatter: (value) => HumanBytesFormat.humanize(value),
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
      ],
      capsuleStatusesOptions: selectHelper.createSelectOptions(Object.values(capsuleStatuses), { valueKey: 'key', textKey: 'name' }),
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
    };
  },
  computed: {
    filterSettings() {
      return FilterSettings;
    },
  },
  methods: {
    handleFilter() {
      TableEventBus.$emit('updateFilterModel', this.filterModel);
    },
    clear() {
      this.filterModel = getDefaultFilterModel();
      this.handleFilter();
    },
    loadDataCallback(payload) {
      return capsulesApi.get(payload);
    },
    async openDetails(item) {
      await this.$router.push({
        name: this.$constants.routes.admin.capsuleEdit,
        params: { id: item.id },
      });
    },
    async createNew() {
      await this.$router.push({
        name: this.$constants.routes.admin.capsuleCreate,
      });
    },
  },
};
</script>
