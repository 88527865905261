import Axios from 'axios';

const baseControllerRoute = 'admin/cloud-capsules';

function update(payload) {
  return Axios.patch(`${baseControllerRoute}`, payload);
}

export default {
  update,
};
