import Axios from 'axios';

const baseControllerRoute = 'admin/auth';

function signin(payload) {
  return Axios.post(`${baseControllerRoute}/signin`, payload);
}

function signup(payload) {
  return Axios.post(`${baseControllerRoute}/signup`, payload);
}

function reauthenticate(payload) {
  return Axios.post(`${baseControllerRoute}/reauthenticate`, payload);
}

function forgotPassword(payload) {
  return Axios.post(`${baseControllerRoute}/forgot-password`, payload);
}

function validateResetToken(payload) {
  return Axios.post(`${baseControllerRoute}/reset-password-token-validate`, payload);
}

function resetPassword(payload) {
  return Axios.post(`${baseControllerRoute}/reset-password`, payload);
}

function getProfileInfo() {
  return Axios.get(`${baseControllerRoute}/profile-info`);
}

export default {
  signin,
  signup,
  reauthenticate,
  forgotPassword,
  validateResetToken,
  resetPassword,
  getProfileInfo,
};
