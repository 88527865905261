const selectHelper = {
  createSelectOptions(array, { valueKey = 'value', textKey = 'text' } = {}) {
    const options = array.map((el) => {
      if (typeof el === 'string' || typeof el === 'number' || !el) {
        return { text: `${el || el === 0 ? el : ''} `, value: el };
      }
      return { ...el, text: `${el[textKey]} `, value: el[valueKey] };
    });
    return options;
  },
  createSelectOptionsWithAll(array) {
    const options = this.createSelectOptions(array);
    return [{ text: 'All', value: '' }, ...options];
  },
};

export default selectHelper;
