<template>
  <div>
    <b-row>
      <b-col>
        Admin Dashboard
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'AdminDashboard',
  data() {
    return {
    };
  },
};
</script>
