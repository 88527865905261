<template>
  <div
    id="googleSigninBtn"
    class="w-100"
  />
</template>

<script>
import { SIGNIN_USER_WITH_GOOGLE } from '@services/store/profile.module';
import store from '@store';
import { messages } from '@constants';

export default {
  name: 'GoogleSigninBtn',
  data() {
    return {
      scriptElement: null,
    };
  },
  async mounted() {
    await this.initGoogleAuth();
  },
  methods: {
    async googleAuthCallback(response) {
      try {
        await store.dispatch(SIGNIN_USER_WITH_GOOGLE, { idToken: response.credential });

        const routeName = this.$constants.routes.user.capsules.index;

        await this.$router.push({
          name: routeName,
        });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          this.$emit('failed', messages.unauthorizeErrorMessage);
        } else {
          this.$emit('failed', messages.unexpectedError);
        }
      }
    },
    initGoogleAuth() {
      this.scriptElement = document.createElement('script');

      this.scriptElement.addEventListener('load', () => {
        window.google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          callback: this.googleAuthCallback,
        });

        window.google.accounts.id.renderButton(
          document.getElementById('googleSigninBtn'),
          {
            size: 'medium',
          },
        );
      });

      this.scriptElement.src = 'https://accounts.google.com/gsi/client';
      this.scriptElement.async = true;
      this.scriptElement.defer = true;
      document.head.appendChild(this.scriptElement);
    },
  },
};
</script>
