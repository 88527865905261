import { layouts } from '@constants';

const component = () => import('./terms-and-conditions.page');

export default [
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions.index',
    component,
    meta: {
      auth: false,
      layout: layouts.landing,
      dark: true,
    },
  },
];
