<template>
  <div>
    <b-row>
      <b-col>
        <b-tabs lazy>
          <b-tab
            v-loader="updateCapsulePromise"
            title="Main Info"
          >
            <b-card class="border-left-0">
              <validation-observer
                ref="observer"
                tag="div"
              >
                <b-form @submit.prevent="save">
                  <b-row v-if="errorMessage">
                    <b-col
                      class="d-flex justify-content-center"
                      md="12"
                    >
                      <b-alert
                        variant="danger"
                        show
                      >
                        {{ errorMessage }}
                      </b-alert>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="Name"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="name"
                          class="l-required"
                        >
                          Name
                        </label>

                        <b-form-input
                          id="name"
                          v-model="model.name"
                          type="text"
                          placeholder="Name"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="Location"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="location"
                          class="l-required"
                        >
                          Location
                        </label>

                        <b-form-input
                          id="location"
                          v-model="model.location"
                          type="text"
                          placeholder="Location"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Size"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="size"
                          class="l-required"
                        >
                          Size
                        </label>

                        <b-form-input
                          id="size"
                          v-model="model.size"
                          type="text"
                          placeholder="Size"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="MinimalDisplayedOccupiedSize"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="MinimalDisplayedOccupiedSize"
                        >
                          Minimal Displayed Occupied Size, bytes
                        </label>

                        <b-form-input
                          id="MinimalDisplayedOccupiedSize"
                          v-model="model.minimalDisplayedOccupiedSize"
                          type="number"
                          placeholder="Minimal Displayed Occupied Size"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Sending Date"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="sendingDate"
                          class="l-required"
                        >
                          Sending Date
                        </label>

                        <b-form-datepicker
                          id="sendingDate"
                          v-model="model.dateSending"
                          :min="minSendingDate"
                          :date-format-options="dateFormat"
                          placeholder="Sending Date"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Status"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="status"
                          class="l-required"
                        >
                          Status
                        </label>

                        <v-select
                          id="status"
                          v-model="model.status"
                          placeholder="Status"
                          :options="capsuleStatusesOptions"
                          label="text"
                          :reduce="status => status.value"
                          :clearable="false"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="9"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        :rules="`max:${$constants.fieldLengths.extraLarge}`"
                        name="Description"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="description"
                        >
                          Description
                        </label>

                        <b-form-textarea
                          id="description"
                          v-model="model.description"
                          placeholder="Description"
                          rows="3"
                          max-rows="6"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        ref="iconValidationProvider"
                        rules="image|size:5000"
                        name="Icon"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="iconInput"
                        >
                          Icon
                        </label>

                        <b-form-file
                          id="iconInput"
                          ref="fileIcon"
                          type="file"
                          accept="image/*"
                          @change="handleIconUploads($event, 'iconValidationProvider')"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>
                    <b-col
                      md="6"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        ref="imageValidationProvider"
                        rules="image|size:5000"
                        name="Image"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="imageInput"
                        >
                          Image
                        </label>

                        <b-form-file
                          id="imageInput"
                          ref="fileImage"
                          type="file"
                          accept="image/*"
                          @change="handleImageUploads($event, 'imageValidationProvider')"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12">
                      <div class="d-flex justify-content-end border-top pt-5">
                        <b-btn
                          v-if="model.status === capsuleStatuses.draft.key"
                          class="mr-2"
                          variant="danger"
                          @click="remove"
                        >
                          Delete
                        </b-btn>
                        <b-btn
                          variant="secondary"
                          class="mr-2"
                          @click="$router.back()"
                        >
                          Cancel
                        </b-btn>

                        <b-btn
                          type="submit"
                          variant="primary"
                        >
                          Save
                        </b-btn>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-tab>

          <b-tab title="Files">
            <b-card>
              <capsule-files
                show-totals
                :capsule-id="model.id"
              />
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { capsulesApi } from '@services/api/admin';
import { capsuleStatuses, errorMessages } from '@app/constants';
import { selectHelper } from '@app/utils';
import { DateFormat } from '@app/formatters';

export default {
  name: 'AdminCapsuleEditPage',
  components: {
    // eslint-disable-next-line import/extensions
    CapsuleFiles: () => import('./components/capsule-files.vue'),
  },
  data() {
    return {
      model: {
        id: '',
        name: '',
        size: '',
        minimalDisplayedOccupiedSize: '',
        dateSending: '',
        location: '',
        status: '',
        description: '',
        image: null,
        icon: null,
      },
      capsuleStatusesOptions: selectHelper.createSelectOptions(Object.values(capsuleStatuses), { valueKey: 'key', textKey: 'name' }),
      capsuleStatuses,
      updateCapsulePromise: null,
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
      errorMessage: '',
    };
  },
  computed: {
    minSendingDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const minDate = new Date(today);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    },
  },
  async mounted() {
    this.model.id = this.$route.params.id;
    await this.loadCapsuleDetails();
  },
  methods: {
    async loadCapsuleDetails() {
      const { data: { data } } = await capsulesApi.getById(this.model.id);

      this.model.name = data.name;
      this.model.size = data.size;
      this.model.minimalDisplayedOccupiedSize = data.minimalDisplayedOccupiedSize;
      this.model.dateSending = DateFormat(data.dateSending, 'YYYY-MM-DD');
      this.model.location = data.location;
      this.model.status = data.status;
      this.model.description = data.description;
    },
    async save() {
      this.errorMessage = '';
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      const formData = new FormData();
      formData.append('id', this.model.id);
      formData.append('image', this.model.image);
      formData.append('icon', this.model.icon);
      formData.append('name', this.model.name);
      formData.append('size', this.model.size);
      formData.append('minimalDisplayedOccupiedSize', this.model.minimalDisplayedOccupiedSize);
      formData.append('dateSending', this.model.dateSending);
      formData.append('status', this.model.status);
      formData.append('description', this.model.description || '');
      formData.append('location', this.model.location);

      try {
        await (this.updateCapsulePromise = capsulesApi.update(formData));

        await this.$router.push({
          name: this.$constants.routes.admin.capsules,
        });
      } catch (error) {
        this.errorMessage = error?.response?.data?.errorMessages
          ? error?.response?.data?.errorMessages.join(', ')
          : errorMessages.unidentified;
      }
    },
    async remove() {
      await capsulesApi.remove(this.model.id);

      await this.$router.push({
        name: this.$constants.routes.admin.capsules,
      });
    },
    async handleImageUploads(e, validationProvider) {
      const file = await this.handleFileUploads(e, this.$refs.fileImage, validationProvider);
      this.model.image = file;
    },
    async handleIconUploads(e, validationProvider) {
      const file = await this.handleFileUploads(e, this.$refs.fileIcon, validationProvider);
      this.model.icon = file;
    },
    async handleFileUploads(e, el, validationProvider) {
      const { valid } = await this.$refs[validationProvider].validate(e);
      if (valid) {
        const file = el.files[0];
        return file;
      }
      return null;
    },
  },
};
</script>
