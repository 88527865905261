/* ============
* Entry Point
* ============
*
* The entry point of the application
*/

<template>
  <component :is="layout">
    <div class="app-wrapper">
      <router-view />
    </div>
  </component>
</template>

<script>
import '@assets/sass/app.scss';
import Vue from 'vue';
import VueMeta from 'vue-meta';
import layoutResolver from '@layouts';
import { OVERRIDE_LAYOUT_CONFIG } from '@services/store/config.module';
import { layouts } from '@constants';

Vue.use(VueMeta);

export default {
  name: 'IndexPage',
  metaInfo() {
    return {
      title: 'It-eoN - your chance to become a part of history!',
    };
  },
  computed: {
    layout() {
      return layoutResolver.resolve(this.$route.meta.layout);
    },
    portal() {
      return this.$route.meta.portal;
    },
  },
  watch: {
    '$route.meta.layout': {
      handler(value) {
        if (value) {
          if (value === layouts.admin || value === layouts.adminBlank) {
            document.body.classList.remove('user-portal');
          } else {
            document.body.classList.add('user-portal');
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>
