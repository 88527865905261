import {
  routes,
  roles,
  layouts,
} from '@app/constants';
import index from './capsules.page.admin';
import create from './capsules-create.page.admin';
import edit from './capsules-edit.page.admin';
import breadcrumbs from './capsules.routes.breadcrumbs.admin';

export default [
  {
    path: '/admin/capsules',
    name: routes.admin.capsules,
    component: index,
    meta: {
      auth: true,
      title: 'Capsules',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.capsules.builders,
    },
  },
  {
    path: '/admin/capsules/create',
    name: routes.admin.capsuleCreate,
    component: create,
    meta: {
      auth: true,
      title: 'Capsule Create',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.capsuleCreate.builders,
    },
  },
  {
    path: '/admin/capsules/:id',
    name: routes.admin.capsuleEdit,
    component: edit,
    meta: {
      auth: true,
      title: 'Capsule Edit',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.capsuleEdit.builders,
    },
  },
];
