import {
  createCapsuleResult, paymentProviders,
} from '@constants';
import { user as userApi } from '../api';

// action types
export const INITIATE_PAYMENT = 'time-capsule-payments.initiatePayment';
export const CAPTURE_PAYMENT = 'time-capsule-payments.capturePayment';
export const RESET_PAYMENTS = 'time-capsule-payments.reset';

// mutation types
export const SET_TIME_CAPSULE = 'time-capsule-payments.setTimeCapsule';
export const SET_PAYPAL_EXTERNAL_KEY = 'time-capsule-payments.setPayPalExternalKey';
export const SET_STRIPE_EXTERNAL_KEY = 'time-capsule-payments.setStripeExternalKey';
export const SET_DEFAULT_STATE = 'time-capsule-payments.setDefaultState';

const initState = {
  timeCapsule: {
    id: null,
  },
  payPalTimeCapsule: {
    externalKey: null,
  },
  stripeTimeCapsule: {
    externalKey: null,
  },
};

const gettersDeclaration = {
  timeCapsule: (data) => data.timeCapsule,
  payPalTimeCapsule: (data) => data.payPalTimeCapsule,
  stripeTimeCapsule: (data) => data.stripeTimeCapsule,
};

function isTimeCapsulesEquals(first, second) {
  return first.id === second.id;
}

async function createTimeCapsule(commit, timeCapsuleId, paymentProvider) {
  try {
    const { data: { data } } = await userApi.userTimeCapsules.initiatePayment(timeCapsuleId, {
      paymentProvider,
    });

    switch (paymentProvider) {
      case paymentProviders.payPal.key: {
        commit(SET_PAYPAL_EXTERNAL_KEY, { externalKey: data.externalKey });
        break;
      }
      case paymentProviders.stripe.key: {
        commit(SET_STRIPE_EXTERNAL_KEY, { externalKey: data.externalKey });
        break;
      }
      default: {
        throw new Error(`Unknown payment provider ${paymentProvider}`);
      }
    }
    commit(SET_TIME_CAPSULE, { id: timeCapsuleId });
    return {
      status: createCapsuleResult.created,
      id: data.id,
      externalKey: data.externalKey,
    };
  } catch (err) {
    return {
      status: createCapsuleResult.unexpectedError,
      message: err.response?.data?.errorMessages.join('. '),
    };
  }
}

const actions = {
  async [INITIATE_PAYMENT]({ commit, getters }, { paymentProvider, timeCapsuleId }) {
    if (getters.timeCapsule.id) {
      if ((paymentProvider === paymentProviders.payPal.key
          && getters.stripeTimeCapsule.externalKey)
        || (paymentProvider === paymentProviders.stripe.key
          && getters.payPalTimeCapsule.externalKey)) {
        await userApi.userTimeCapsules.failPayment(getters.timeCapsule.id, {
          reason: `Provider changed to ${paymentProvider}`,
        });
        commit(SET_DEFAULT_STATE);
        return createTimeCapsule(commit, timeCapsuleId, paymentProvider);
      }

      if (!isTimeCapsulesEquals(getters.timeCapsule, { id: timeCapsuleId })) {
        await userApi.userTimeCapsules.failPayment(getters.timeCapsule.id, {
          reason: 'User changed capsule after payment initiated',
        });
        commit(SET_DEFAULT_STATE);
        return createTimeCapsule(commit, timeCapsuleId, paymentProvider);
      }

      return {
        status: createCapsuleResult.created,
        id: getters.timeCapsule.id,
        externalKey: paymentProvider === paymentProviders.payPal.key
          ? getters.payPalTimeCapsule.externalKey
          : getters.stripeTimeCapsule.externalKey,
      };
    }

    return createTimeCapsule(commit, timeCapsuleId, paymentProvider);
  },
  async [CAPTURE_PAYMENT]({ commit, getters }) {
    await userApi.userTimeCapsules.capturePayment(getters.timeCapsule.id);
    commit(SET_DEFAULT_STATE);
  },
  async [RESET_PAYMENTS]({ commit, getters }, { failPayment }) {
    if (getters.timeCapsule.id && failPayment) {
      await userApi.userTimeCapsules.failPayment(getters.timeCapsule.id, {
        reason: 'User closed the modal',
      });
    }

    commit(SET_DEFAULT_STATE);
  },
};

/* eslint-disable no-param-reassign */
const mutations = {
  [SET_TIME_CAPSULE](data, { id }) {
    data.timeCapsule.id = id;
  },
  [SET_PAYPAL_EXTERNAL_KEY](data, { externalKey }) {
    data.payPalTimeCapsule.externalKey = externalKey;
  },
  [SET_STRIPE_EXTERNAL_KEY](data, { externalKey }) {
    data.stripeTimeCapsule.externalKey = externalKey;
  },
  [SET_DEFAULT_STATE](data) {
    data.timeCapsule.id = null;
    data.payPalTimeCapsule.externalKey = null;
    data.stripeTimeCapsule.externalKey = null;
  },
};
/* eslint-enable no-param-reassign */
export default {
  state: initState,
  actions,
  mutations,
  getters: gettersDeclaration,
};
