export default {
  paging: {
    page: 1,
    totalItems: 5,
    pageSize: 25,
  },
  filtering: {
    placeholder: 'Search',
    searchBtnText: 'Search',
    isClearBtn: true,
    clearBtnText: 'Clear',
    key: 'term',
    isComplex: false,
  },
};
