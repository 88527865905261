import Vue from 'vue';
import Vuex from 'vuex';

import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import profile from './profile.module';
import timeCapsulePayments from './time-capsule-payments.module';
import cloudCapsulePayments from './cloud-capsule-payments.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    breadcrumbs,
    profile,
    timeCapsulePayments,
    cloudCapsulePayments,
  },
});
