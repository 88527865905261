<template>
  <ul class="menu-nav">
    <router-link
      v-for="menuItem in menuItems"
      :key="menuItem.title"
      v-slot="{ href, navigate, isActive, isExactActive }"
      :to="menuItem.route"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-exact-active'
        ]"
      >
        <a
          :href="href"
          class="menu-link"
          @click="navigate"
        >
          <i :class="menuItem.iconClass" />
          <span class="menu-text">{{ menuItem.title }}</span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
function getMenuItems() {
  return {
    capsules: {
      title: 'Capsules',
      route: { name: 'admin.capsules' },
      iconClass: 'menu-icon flaticon-signs',
    },
    users: {
      title: 'Users',
      route: { name: 'admin.users' },
      iconClass: 'menu-icon flaticon-users',
    },
  };
}

export default {
  name: 'AsideMenu',
  data() {
    return {
      menuItems: getMenuItems.call(this),
    };
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";

.aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon {
  .menu-icon--img {
    height: 18px;
    width: 20px;

    g {
      fill: $color-white;
    }
  }
}
</style>
