<template>
  <div class="topbar-item w-100 justify-content-between">
    <div
      id="kt_quick_user_toggle"
      class="btn btn-icon w-auto btn-clean d-flex flex-row-reverse
      flex-lg-row align-items-center btn-lg px-2"
    >
      <span class="text-dark font-weight-bold font-size-base d-none d-lg-inline">
        Hi,
      </span>

      <span class="text-dark font-weight-bolder font-size-base mx-2">
        {{ userPersonalInfo.FirstName }}
      </span>

      <span class="symbol symbol-35 symbol-primary">
        <span class="symbol-label font-size-h5 font-weight-bold text-white">
          {{ initial }}
        </span>
      </span>
    </div>

    <b-btn
      variant="primary"
      class="btn-bold d-lg-none"
      @click="signout"
    >
      Sign out
    </b-btn>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          User Profile
        </h3>

        <a
          id="kt_quick_user_close"
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
        >
          <i class="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex w-100 pt-5">
          <div class="symbol symbol-100 symbol-light-primary mr-5">
            <span class="symbol-label font-size-h2 font-weight-bold">
              {{ initial }}
            </span>
          </div>

          <div class="flex-auto">
            <router-link
              to=""
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary mb-1"
            >
              {{ getFullName }}
            </router-link>

            <div class="text-muted mb-1">
              {{ role }}
            </div>

            <div class="navi mb-4">
              <a
                href="#"
                class="navi-item"
              >
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="require('@assets/images/icons/Mail-notification.svg')"
                      />
                    <!--end::Svg Icon-->
                    </span>
                  </span>

                  <span class="navi-text text-muted text-hover-primary">
                    {{ userEmail }}
                  </span>
                </span>
              </a>
            </div>

            <b-btn
              variant="primary"
              @click="signout"
            >
              Sign Out
            </b-btn>
          </div>
        </div>
        <!--end::Header-->

        <div class="separator separator-dashed mt-8 mb-5" />
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script lang="babel">
import { mapGetters } from 'vuex';
import KTLayoutQuickUser from '@assets/js/layout/extended/quick-user';
import { SIGNOUT } from '@services/store/profile.module';
import store from '@services/store';
import { roles } from '@constants';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['userPersonalInfo', 'userRole', 'userEmail']),
    getFullName() {
      return (
        `${this.userPersonalInfo.FirstName} ${this.userPersonalInfo.LastName}`
      );
    },
    initial() {
      return this.userPersonalInfo.FirstName ? this.userPersonalInfo.FirstName.charAt(0).toUpperCase() : '';
    },
    role() {
      return roles.admin;
    },
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs.kt_quick_user);
  },
  methods: {
    signout() {
      store.dispatch(SIGNOUT);
      this.$router.push({ name: this.$constants.routes.admin.signin });
    },
  },
};

</script>
<style lang="scss">
@import "@assets/sass/_variables.scss";

#kt_quick_user {
  overflow: hidden;
}
</style>
