<template>
  <div class="topbar-item">
    <div class="topbar-item__row">
      <div class="topbar-item__text">
        <label class="topbar-item__label">
          Account
        </label>

        <h3
          v-if="showFullName"
          class="topbar-item__name"
        >
          {{ userFullName }}
        </h3>

        <button
          class="topbar-item__name"
          @click="showPopup"
        >
          {{ userEmail }}
        </button>

        <b-link
          class="topbar-item__link"
          @click="signout"
        >
          Log Out
        </b-link>
      </div>

      <div
        class="topbar-item__initial"
        @click="showPopup"
      >
        {{ initial }}
      </div>
    </div>

    <div

      class="topbar-item__popup"
      :class="{ '_active': isPopupActive }"
    >
      <ul
        v-click-outside="hidePopup"
        class="topbar-item__popup-menu"
      >
        <li>
          <button @click="showProfileModal">
            Edit Profile
          </button>
        </li>
        <li>
          <button @click="showNotificationSettingsModal">
            Notification Settings
          </button>
        </li>
        <li>
          <button @click="showChangePasswordModal">
            Change Password
          </button>
        </li>
        <li class="mobile-only">
          <router-link
            :to="{
              name: routes.user.capsules.time
            }"
          >
            Time Capsules
          </router-link>
        </li>
        <li class="mobile-only">
          <router-link
            :to="{
              name: profileInfo.hasCloudCapsules
                ? routes.user.capsules.cloudPersonalCabinet
                : routes.user.capsules.cloud
            }"
          >
            Cloud Capsules
          </router-link>
        </li>
        <li class="mobile-only">
          <router-link
            :to="{
              name: routes.user.capsules.index
            }"
          >
            Prices
          </router-link>
        </li>
      </ul>
    </div>

    <profile-settings-modal ref="profileSettingsModal" />

    <notification-settings-modal ref="notificationSettingsModal" />

    <update-password-modal ref="updatePasswordModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SIGNOUT } from '@services/store/profile.module';
import store from '@services/store';
import { roles, routes } from '@constants';
import ClickOutside from 'vue-click-outside';
import ProfileSettingsModal from './modals/profile-settings.modal';
import NotificationSettingsModal from './modals/notification-settings.modal';
import UpdatePasswordModal from './modals/update-password.modal';

export default {
  name: 'HeaderQuickUser',
  components: {
    ProfileSettingsModal,
    NotificationSettingsModal,
    UpdatePasswordModal,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showFullName: false,
      isPopupActive: false,
      routes,
    };
  },
  computed: {
    ...mapGetters(['profileInfo', 'userRole', 'userEmail']),
    userFullName() {
      return (
        `${this.profileInfo.firstName} ${this.profileInfo.lastName}`
      );
    },
    initial() {
      return this.profileInfo.firstName ? this.profileInfo.firstName.charAt(0).toUpperCase() : '';
    },
    role() {
      return roles.user;
    },
  },
  mounted() {
    this.popupItem = this.$el;
  },
  methods: {
    showPopup() {
      this.isPopupActive = true;
    },
    hidePopup() {
      this.isPopupActive = false;
    },
    showProfileModal() {
      this.$refs.profileSettingsModal.show();
    },
    hideProfileModal() {
      this.$refs.profileSettingsModal.hide();
    },
    showNotificationSettingsModal() {
      this.$refs.notificationSettingsModal.show();
    },
    hideNotificationSettingsModal() {
      this.$refs.notificationSettingsModal.hide();
    },
    showChangePasswordModal() {
      this.$refs.updatePasswordModal.show();
    },
    hideChangePasswordModal() {
      this.$refs.updatePasswordModal.hide();
    },
    signout() {
      store.dispatch(SIGNOUT);
      this.$router.push({ name: this.$constants.routes.user.signin });
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";

.topbar-item {
  position: relative;

  &__row {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__text {
    text-align: right;
  }

  &__label {
    font-size: 10px;
    font-weight: 400;
  }

  &__name {
    display: block;
    margin-bottom: 0;
    border: none;
    padding: 0;
    background: none;
    font-size: 12px;
    font-weight: 600;
    color: inherit;
    cursor: pointer;
    transition: color 0.3s;

    &:hover,
    &:focus,
    &:active {
      color: $color-primary;
    }
  }

  &__link {
    font-size: 10px;
    font-weight: 400;
  }

  &__initial {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.125rem solid $color-primary;
    border-radius: 50%;
    flex: 0 0 4rem;
    width: 4rem;
    height: 4rem;
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 4.5rem;
    border-radius: 1rem;
    padding: 2rem 0.5rem 1rem;
    background-color: rgba($color-black, 0.85);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;

    &._active {
      visibility: visible;
      opacity: 1;
    }

    &-menu {
      list-style: none;
      margin-bottom: 0;
      padding-left: 0;

      > li {
        > button,
        > a {
          display: inline-block;
          border: none;
          width: 100%;
          padding: 0.35rem 0.25rem;
          background: none;
          color: $color-white;
          text-align: left;
          transition: color 0.3s;

          &:hover,
          &:active,
          &:focus {
            color: $color-primary;
          }

          &._active {
            color: $color-primary;
          }
        }
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
    border: none;
    background: none;
    color: rgba($color-white, 0.75);
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      color: $color-primary;
    }
  }
}

#kt_quick_user {
  overflow: hidden;
}
</style>
