export default {
  validation: {
    messages: {
      password: 'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and one special character',
      email: '{_field_} must be a valid email',
      max: '{_field_} may not be greater than {length} characters',
      min: '{_field_} must be at least {length} characters',
      numeric: '{_field_} may only contain numeric characters',
      required: '{_field_} is required',
      confirmed: 'New Password and Confirmation should match',
    },
    fields: {
      answerCheckbox: {
        required: 'Please, choose your answer',
      },
      answerSelect: {
        required: 'Please, choose your answer',
      },
      answerShortText: {
        required: 'Please, type your answer',
        min: 'The answer must be at least {length} characters',
      },
      commuteDistance: {
        required: 'Please, type your answer',
      },
      locationZipCode: {
        required: 'Please, type your zip-code',
      },
    },
  },
};
