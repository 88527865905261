<template>
  <div class="topbar justify-content-between">
    <KTQuickUser />
  </div>
</template>

<script>
import KTQuickUser from './quick-user';

export default {
  name: 'ToolbarLayout',
  components: {
    KTQuickUser,
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";

.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &::after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
