import Axios from 'axios';
import Store from '@services/store';
import { messages, roles } from '@app/constants';
import { SIGNOUT, REAUTHENTICATE } from '@services/store/profile.module';
import {
  admin as adminRoutes, user as userRoutes,
} from '@constants/route-names';
import router from '../router';

const signinURL = '/api/auth/signin';
const signinAdminURL = '/api/admin/auth/signin';
const reauthenticateURL = 'auth/reauthenticate';
const reauthenticateAdminURL = 'admin/auth/reauthenticate';

// Axios setup
// taking api path from appsettings.json that injected in js global variable in _Layout.cshtml
// eslint-disable-next-line no-undef
Axios.defaults.baseURL = '/api/';
Axios.defaults.headers.common.Accept = 'application/json';

let authTokenRequest;

function resetAuthTokenRequest() {
  authTokenRequest = null;
}

function getAuthToken() {
  if (!authTokenRequest) {
    authTokenRequest = Store.dispatch(REAUTHENTICATE, {
      accessToken: Store.getters.userToken,
      refreshToken: Store.getters.userRefreshToken,
    });
    authTokenRequest.finally(resetAuthTokenRequest);
  }
  return authTokenRequest;
}

function errorHandler(error) {
  const { response } = error;
  if (error?.response?.status === 401) {
    if ((error?.config?.url === reauthenticateURL)
      || (error?.config?.url === reauthenticateAdminURL)) {
      const isAdmin = Store.getters.userRole === roles.admin;
      Store.dispatch(SIGNOUT).then(() => {
        router.getRouter().push({
          name: isAdmin
            ? adminRoutes.signin
            : userRoutes.signin,
          params: { infoMessage: messages.signoutMessage },
        });
      });
    } else if ((error?.config?.url !== signinURL || error?.config?.url !== signinAdminURL)
      && !response.config.isRetryRequest) {
      return getAuthToken().then(() => {
        response.config.isRetryRequest = true;
        return Axios(response.config);
      });
    }
  }
  return Promise.reject(error);
}

function responseHandler(response) {
  return response;
}

function requestHandler(request) {
  const token = Store.getters.userToken;
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
}

Axios.interceptors.response.use(responseHandler, errorHandler);
Axios.interceptors.request.use(requestHandler);
