<template>
  <div class="start-capsule-card">
    <h3 class="start-capsule-card__title">
      {{ capsule.title }}
    </h3>

    <div class="start-capsule-card__img">
      <img
        :src="capsule.img"
        :alt="capsule.title"
      >
    </div>

    <ul
      v-if="capsule.type === 'time'"
      class="start-capsule-card__list"
    >
      <li>
        <div>
          <span class="text-primary">1MB -</span> $10
        </div>

        <div class="text-primary">
          min 10MB
        </div>
      </li>
      <li>
        <div class="text-primary">
          > 100MB
        </div>

        <div>
          -10%
        </div>
      </li>
      <li>
        <div class="text-primary">
          > 500MB
        </div>

        <div>
          -20%
        </div>
      </li>
    </ul>

    <ul
      v-else
      class="start-capsule-card__list"
    >
      <li>
        <div>
          <span class="text-primary">1MB -</span> $1
        </div>

        <div class="text-primary">
          min 50MB
        </div>
      </li>
      <li>
        <div>
          +$0.07/day
        </div>
      </li>
      <li>
        <div class="text-primary">
          > 500MB
        </div>

        <div>
          -20%
        </div>
      </li>
    </ul>

    <button
      class="start-capsule-card__btn landing-btn"
      @click="createCapsule"
    >
      {{ capsule.detailsText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CapsuleCard',
  props: {
    type: {
      type: String,
      default: 'time',
    },
    capsule: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async createCapsule() {
      await this.$router.push({
        name: this.capsule.detailsLink,
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";
@import "@assets/sass/base/_functions.scss";

.start-capsule-card {
  border: rem(2) solid $color-primary;
  border-radius: rem(24);
  padding: rem(24) rem(20);

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    color: $color-primary;
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    height: 18rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 1.75rem;
    padding-left: 0;

    > li {
      flex: 0 0 33.33%;
      text-align: center;
      font-weight: 700;
      line-height: 1.2;

      &:not(:last-child) {
        border-right: 0.125rem solid $color-primary;
      }
    }
  }

  &__btn {
    width: 100%;
  }
}
</style>
