import { layouts } from '@constants';

const component = () => import('./privacy-policy.page');

export default [
  {
    path: '/privacy',
    name: 'privacy-policy.index',
    component,
    meta: {
      auth: false,
      layout: layouts.landing,
      dark: true,
    },
  },
];
