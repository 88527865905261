<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-table-auto
            show-empty
            responsive
            hover
            outlined
            caption-top
            clickable
            show-total-items
            :fields="fields"
            :load-data-callback="loadDataCallback"
            @row-clicked="openDetails"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { notificationsApi } from '@services/api/admin';

export default {
  name: 'NotificationsPage',
  data() {
    return {
      fields: [
        {
          key: 'subject',
          label: 'Name',
        },
      ],
    };
  },
  methods: {
    loadDataCallback(payload) {
      return notificationsApi.get(payload);
    },
    async openDetails(item) {
      await this.$router.push({
        name: this.$constants.routes.admin.notificationEdit,
        params: { id: item.id },
      });
    },
  },
};
</script>
