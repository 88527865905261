<template>
  <div>
    <capsules-cards />
  </div>
</template>

<script>
import CapsulesCards from './components/capsule-cards';

export default {
  name: 'UserCapsulesPage',
  components: {
    CapsulesCards,
  },
};
</script>
