<template>
  <section class="section section--testimonials">
    <div class="section__container container">
      <div class="section__wrap">
        <h2
          class="section__title text-center"
          data-aos="fade-up"
        >
          Testimonials
        </h2>

        <div
          ref="swiper"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(testimonial, index) in testimonials"
              :key="index"
              class="swiper-slide"
            >
              <review-card
                :testimonial="testimonial"
                data-aos="fade-left"
                :data-aos-delay="200 + index * 100"
              />
            </div>
          </div>

          <div class="swiper-pagination" />

          <div class="swiper-button-prev" />
          <div class="swiper-button-next" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import ReviewCard from './components/review-card';

Swiper.use([Navigation, Pagination]);

export default {
  name: 'TestimonialsSection',
  components: {
    ReviewCard,
  },
  props: {
    testimonials: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    setTimeout(() => {
      // eslint-disable-next-line no-new
      new Swiper(this.$refs.swiper, {
        spaceBetween: 20,
        slidesPerView: 1,
        speed: 600,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
        },
        breakpoints: {
          760: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          980: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        },
      });
    }, 500);
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.swiper {
  --swiper-pagination-bullet-horizontal-gap: 14px;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-navigation-size: 7.5rem;
  --swiper-navigation-sides-offset: 0;

  width: 100%;
  height: 100%;

  @include respond-above(md) {
    padding: 0 4rem;
  }

  .swiper-wrapper {
    padding-bottom: 4rem;
  }

  .swiper-pagination {
    &-bullet {
      position: relative;
      border-radius: 0.25rem;
      width: 1.25rem;
      height: 1.25rem;
      background-color: rgba(#9e9e9e, 0.32);

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 0.15rem;
        width: 0.75rem;
        height: 0.75rem;
        background-color: $color-white;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s;
      }

      &-active {
        &::after {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 0.25rem;
    width: 2.75rem;
    background-color: rgba(#9e9e9e, 0.32);
    color: $color-white;
    transition: background-color 0.35s;

    @include respond-below(md) {
      display: none;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $landing-color-primary;
    }

    &::after {
      font-size: 1.5rem;
    }
  }

  .swiper-slide {
    width: 100%;
    height: auto;

    .review-card {
      min-height: 100%;
    }
  }
}
</style>
