<template>
  <div>
    <v-hero />

    <scroll-progress />

    <v-video />

    <v-img-text-02 />

    <v-img-text />

    <v-testimonials
      v-if="testimonials.length > 0"
      :testimonials="testimonials"
    />

    <scroll-progress />

    <v-faqs
      v-if="timeCapsuleFaqs.length > 0 || cloudCapsuleFaqs.length > 0"
      title="FAQ - Time Capsule"
      :time-capsule-faqs="timeCapsuleFaqs"
      :cloud-capsule-faqs="cloudCapsuleFaqs"
    />
  </div>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import { ScrollProgress } from '@/app/components';

import {
  Hero,
  Video,
  ImgText,
  ImgText02,
  Testimonials,
  Faqs,
} from './sections';

export default {
  name: 'LandingPage',
  components: {
    VHero: Hero,
    VVideo: Video,
    VImgText: ImgText,
    VImgText02: ImgText02,
    VTestimonials: Testimonials,
    VFaqs: Faqs,
    ScrollProgress,
  },
  data() {
    return {
      timeCapsuleFaqs: [
        {
          question: 'What is the minimum amount of MB I can buy?',
          answer: 'You can buy from 10 MB, from 100 MB - 10% discount, from 500 MB - 20% discount.',
          open: false,
        },
        {
          question: 'What kind of confirmation will I get after buying a Time Capsule?',
          answer: 'You will get an e-certificate on participation in the project, in which capsule and what date it was sent.',
          open: false,
        },
        {
          question: 'Can I change the capsule after purchasing a place in it?',
          answer: 'No. You select the capsule right before purchasing a place.',
          open: false,
        },
        {
          question: 'How do I support the project?',
          answer: 'You can support the project by clicking <a href="https://paypal.me/IteoNio">here</a>.',
          open: false,
        },
        {
          question: 'Can I purchase a capsule for myself?',
          answer: 'Yes, you can. You just need to contact our support service <a href="mailto:support@it-eon.io">support@it-eon.io</a>, our specialists will check capsule and digital drive availability and contact you.',
          open: false,
        },
        {
          question: 'How will the capsule be delivered to the destination point?',
          answer: 'A lottery will be held among participants, and one of the participants together with our team will deliver a capsule to the specified place.',
          open: false,
        },
      ],
      cloudCapsuleFaqs: [
        {
          question: 'What is the minimum number of MB I can buy and how can I use Cloud Capsule?',
          answer: 'You can buy from 50 MB of memory and can use Cloud Capsule to preserve and transmit important moments of your life to future generations. It could be a message for your children, an anniversary greeting for a loved one or even a message for your great-grandchildren. The possibilities are endless!',
          open: false,
        },
        {
          question: 'How long can my message be stored?',
          answer: 'We guarantee storage of your information on our secure servers for the time period you specify. You can choose any length of time, from several months to several decades.',
          open: false,
        },
        {
          question: 'Can I change or delete a message after I send it?',
          answer: 'Unfortunately, once a message has been sent, there is no way to make changes in the future. We strive to maintain the authenticity and integrity of messages so that they reach recipients as intended. You can delete a message delivery from your personal account and no refunds will be given.',
          open: false,
        },
        {
          question: 'How will my message be delivered to the recipient?',
          answer: 'Your message will arrive at the email address you provide on the date and time you specify.',
          open: false,
        },
        {
          question: 'What happens if the recipient\'s email address changes?',
          answer: 'If the recipient\'s email address changes, you can change it in your personal account.',
          open: false,
        },
      ],
      testimonials: [
        {
          name: 'John Doe',
          title: 'CEO',
          text: 'I have always thought about how to leave my mark on this earth. When I found out about the It-eoN project, I immediately decided to become a member. I believe that this is not only an opportunity to save my information for millennia, but also to do something meaningful for future generations.',
          date: '16.04.2023',
          rating: 4.5,
        },
        {
          name: 'Jane',
          title: 'CTO',
          text: 'The It-eoN project is a really unique idea. I bought space on the time capsule to save my scientific work. I\'m sure that in 10,000 years my work will Also as relevant and important as it is today.',
          date: '16.04.2023',
          rating: 4.5,
        },
        {
          name: 'Robert',
          title: 'COO',
          text: 'I was amazed by the novelty of the idea of the It-eoN project. I decided to purchase space on the disk of the time capsule in order to preserve the legacy of my family for millennia. I am sure that my descendants will be proud of their lineage and the legacy that I will preserve in this time capsule.',
          date: '16.04.2023',
          rating: 4.0,
        },
        {
          name: 'Alicon',
          title: 'CFO',
          text: 'I am very glad that I became a member of the It-eoN project. This is truly a unique opportunity to leave my mark on the ages. I have purchased space on the disk of the time capsule and will keep my valuable information there. I am sure that my descendants will be grateful for such a gift from the past .',
          date: '16.04.2023',
          rating: 5,
        },
        {
          name: 'Rocky',
          title: 'CFO',
          text: 'The It-eoN time capsule project is a real novelty in the world. I thought for a long time how to make my mark on this earth, and when I heard about the It-eoN project, I realized that this is exactly what I need. I bought space on the disk of the time capsule and I think that this is the best investment in the future!',
          date: '16.04.2023',
          rating: 5,
        },
        {
          name: 'John',
          title: 'CFO',
          text: 'It-eoN is not just a project, it\'s makes me feel like a part of history! I bought space on the disk of the time capsule and placed on it information that is very important to me. I am confident that my information will be available to future generations.',
          date: '16.04.2023',
          rating: 5,
        },
        {
          name: 'Nick',
          title: 'CFO',
          text: 'I\'ve been thinking about how to leave my mark on the earth for a long time, and when I found out about the It-eoN project, I realized that this is exactly what I need. I bought a place on the disk of the time capsule and put information about myself on it , about my family and my accomplishments. My information will be seen by future generations, and it makes me feel like a part of history!',
          date: '16.04.2023',
          rating: 5,
        },
      ],
    };
  },
  mounted() {
    AOS.init({
      duration: 1200,
      disable: 'mobile',
      mirror: true,
    });
  },
};
</script>
