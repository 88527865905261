export default {
  draft: {
    key: 'Draft',
    name: 'Draft',
  },
  awaitingPayment: {
    key: 'AwaitingPayment',
    name: 'Awaiting Payment',
  },
  paid: {
    key: 'Paid',
    name: 'Paid',
  },
  expired: {
    key: 'Expired',
    name: 'expired',
  },
};
