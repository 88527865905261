import { notFound } from '@constants/route-names';
import { layouts } from '@constants';
import page from './not-found.page';

export default [
  {
    path: '/not-found',
    alias: '*',
    name: notFound.index,
    component: page,
    meta: {
      layout: layouts.unauthorized,
    },
  },
];
