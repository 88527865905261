<template>
  <b-card v-loader="createCapsulePromise">
    <b-row>
      <b-col>
        <validation-observer
          ref="observer"
          tag="div"
        >
          <b-form @submit.prevent="save">
            <b-row v-if="errorMessage">
              <b-col
                class="d-flex justify-content-center"
                md="12"
              >
                <b-alert
                  variant="danger"
                  show
                >
                  {{ errorMessage }}
                </b-alert>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:100"
                  name="Name"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="name"
                    class="l-required"
                  >
                    Name
                  </label>

                  <b-form-input
                    id="name"
                    v-model="model.name"
                    type="text"
                    placeholder="Name"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:100"
                  name="Location"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="location"
                    class="l-required"
                  >
                    Location
                  </label>

                  <b-form-input
                    id="location"
                    v-model="model.location"
                    type="text"
                    placeholder="Location"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|min_value:100000"
                  name="Size"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="size"
                    class="l-required"
                  >
                    Size, bytes
                  </label>

                  <b-form-input
                    id="size"
                    v-model="model.size"
                    type="number"
                    placeholder="Size"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  name="MinimalDisplayedOccupiedSize"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="MinimalDisplayedOccupiedSize"
                  >
                    Minimal Displayed Occupied Size, bytes
                  </label>

                  <b-form-input
                    id="MinimalDisplayedOccupiedSize"
                    v-model="model.minimalDisplayedOccupiedSize"
                    type="number"
                    placeholder="Minimal Displayed Occupied Size"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Sending Date"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="sendingDate"
                    class="l-required"
                  >
                    Sending Date
                  </label>

                  <b-form-datepicker
                    id="sendingDate"
                    v-model="model.dateSending"
                    :min="minSendingDate"
                    :date-format-options="dateFormat"
                    placeholder="Sending Date"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="3"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="Status"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="status"
                    class="l-required"
                  >
                    Status
                  </label>

                  <v-select
                    id="status"
                    v-model="model.status"
                    placeholder="Status"
                    :options="capsuleStatusesOptions"
                    label="text"
                    :reduce="status => status.value"
                    :clearable="false"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :rules="`max:${$constants.fieldLengths.extraLarge}`"
                  name="Description"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="description"
                  >
                    Description
                  </label>

                  <b-form-textarea
                    id="description"
                    v-model="model.description"
                    placeholder="Description"
                    rows="3"
                    max-rows="6"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  ref="iconValidationProvider"
                  rules="image|size:5000"
                  name="Icon"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="iconInput"
                  >
                    Icon
                  </label>

                  <b-form-file
                    id="iconInput"
                    ref="fileIcon"
                    type="file"
                    accept="image/*"
                    @change="handleIconUploads($event, 'iconValidationProvider')"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                class="mb-5"
              >
                <validation-provider
                  v-slot="{ errors }"
                  ref="imageValidationProvider"
                  rules="image|size:5000"
                  name="Image"
                  tag="div"
                  mode="eager"
                  class="position-relative form-group mb-0"
                >
                  <label
                    for="imageInput"
                  >
                    Image
                  </label>

                  <b-form-file
                    id="imageInput"
                    ref="fileImage"
                    type="file"
                    accept="image/*"
                    @change="handleImageUploads($event, 'imageValidationProvider')"
                  />

                  <span class="position-absolute error">
                    {{ errors[0] }}
                  </span>
                </validation-provider>
              </b-col>

              <b-col cols="12">
                <div class="d-flex justify-content-end border-top pt-5">
                  <b-btn
                    variant="secondary"
                    class="mr-2"
                    @click="$router.back()"
                  >
                    Cancel
                  </b-btn>

                  <b-btn
                    type="submit"
                    variant="primary"
                  >
                    Save
                  </b-btn>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { capsulesApi } from '@services/api/admin';
import { capsuleStatuses, errorMessages } from '@app/constants';
import { selectHelper } from '@app/utils';

export default {
  name: 'AdminCapsuleCreate',
  data() {
    return {
      model: {
        name: '',
        size: '',
        minimalDisplayedOccupiedSize: '',
        dateSending: '',
        location: '',
        status: '',
        description: '',
        image: null,
        icon: null,
      },
      capsuleStatusesOptions: selectHelper.createSelectOptions(Object.values(capsuleStatuses), { valueKey: 'key', textKey: 'name' }),
      createCapsulePromise: null,
      dateFormat: { year: 'numeric', month: 'numeric', day: 'numeric' },
      errorMessage: '',
    };
  },
  computed: {
    minSendingDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const minDate = new Date(today);
      minDate.setDate(minDate.getDate() + 1);
      return minDate;
    },
  },
  methods: {
    async save() {
      this.errorMessage = '';
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      const formData = new FormData();
      formData.append('image', this.model.image);
      formData.append('icon', this.model.icon);
      formData.append('name', this.model.name);
      formData.append('size', this.model.size);
      formData.append('minimalDisplayedOccupiedSize', this.model.minimalDisplayedOccupiedSize);
      formData.append('status', this.model.status);
      formData.append('dateSending', this.model.dateSending);
      formData.append('description', this.model.description || '');
      formData.append('location', this.model.location);

      try {
        await (this.createCapsulePromise = capsulesApi.create(formData));
        await this.$router.push({ name: this.$constants.routes.admin.capsules });
      } catch (error) {
        this.errorMessage = error?.response?.data?.errorMessages
          ? error?.response?.data?.errorMessages.join(', ')
          : errorMessages.unidentified;
      }
    },
    async handleImageUploads(e, validationProvider) {
      const file = await this.handleFileUploads(e, this.$refs.fileImage, validationProvider);
      this.model.image = file;
    },
    async handleIconUploads(e, validationProvider) {
      const file = await this.handleFileUploads(e, this.$refs.fileIcon, validationProvider);
      this.model.icon = file;
    },
    async handleFileUploads(e, el, validationProvider) {
      const { valid } = await this.$refs[validationProvider].validate(e);
      if (valid) {
        const file = el.files[0];
        return file;
      }
      return null;
    },
  },
};
</script>
