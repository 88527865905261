import {
  routes,
  roles,
  layouts,
} from '@app/constants';
import index from './notifications.page';
import edit from './notifications-edit.page';
import breadcrumbs from './notifications.routes.breadcrumbs.admin';

export default [
  {
    path: '/admin/notifications',
    name: routes.admin.notifications,
    component: index,
    meta: {
      auth: true,
      title: 'Notifications',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.notifications.builders,
    },
  },
  {
    path: '/admin/notifications/:id',
    name: routes.admin.notificationEdit,
    component: edit,
    meta: {
      auth: true,
      title: 'Notification Edit',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.notificationEdit.builders,
    },
  },
];
