/* ============
 * Main File
 * ============
 *
 * Will initialize the application
 */
import Vue from 'vue';
import { localStorageKeys } from '@constants';
import { SET_TOKENS } from '@store/profile.module';
import App from './app/index';
import router from './router';
import store from './app/services/store';
import './bootstrap';
import i18n from './internationalization';

// Global 3rd party plugins
// eslint-disable-next-line
import PerfectScrollbar from 'perfect-scrollbar';

function initVue() {
  // eslint-disable-next-line no-new
  new Vue({
    el: '#app',
    router: router.getRouter(),
    store,
    components: { App },
    template: '<App/>',
    i18n,
  });
}
window.PerfectScrollbar = PerfectScrollbar;

const accessToken = window.localStorage.getItem(localStorageKeys.userToken);
const refreshToken = window.localStorage.getItem(localStorageKeys.userRefreshToken);

if (accessToken && refreshToken) {
  store.dispatch(SET_TOKENS, { accessToken, refreshToken }).then(() => {
    initVue();
  });
} else {
  initVue();
}
