<template>
  <div>
    <form
      id="payment-form"
      v-loader="loadDataPromise"
      @submit.prevent="submit"
    >
      <div class="mb-5">
        <div
          id="card-element"
          class="stripe-card"
        >
        <!-- Elements will create input elements here -->
        </div>
      </div>

      <!-- We'll put the error messages in this element -->
      <div
        id="card-errors"
        role="alert"
      />

      <div class="mb-10">
        <slot name="paypal-btn" />
      </div>
    </form>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { messages, paymentProviders, createCapsuleResult } from '@app/constants';
import { INITIATE_PAYMENT } from '@store/cloud-capsule-payments.module';
import store from '@store';

const styles = {
  base: {
    backgroundColor: 'transparent',
    iconColor: '#c4f0ff',
    color: '#fff',
    fontWeight: '400',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '16',
    letterSpacing: '0.1em',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
      color: '#fce883',
    },
    '::placeholder': {
      color: '#898989',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
};

export default {
  name: 'StripeElements',
  props: {
    capsuleId: {
      type: String,
      required: true,
    },
  },
  emits: ['succeeded', 'failed', 'insufficient-space', 'card-valid', 'throttling'],
  data() {
    return {
      stripe: null,
      card: null,
      createdCapsuleId: null,
      externalKey: null,
      loadDataPromise: null,
      stripeRequest: null,
      paymentDisabled: true,
    };
  },
  async mounted() {
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    const elements = this.stripe.elements();
    this.card = elements.create('card', {
      style: styles,
    });
    const self = this;
    this.card.addEventListener('change', (event) => {
      self.$emit('card-valid', event.complete);
    });
    this.card.mount('#card-element');
  },
  methods: {
    async submit() {
      this.loadDataPromise = store.dispatch(INITIATE_PAYMENT, {
        cloudCapsuleId: this.capsuleId,
        paymentProvider: paymentProviders.stripe.key,
      });

      const data = await this.loadDataPromise;
      switch (data.status) {
        case createCapsuleResult.created: {
          this.createdCapsuleId = data.id;
          this.externalKey = data.externalKey;
          break;
        }
        case createCapsuleResult.insufficientSpace: {
          this.$emit('insufficient-space', data.message);
          return;
        }
        case createCapsuleResult.createCapsuleThrottling: {
          this.$emit('throttling', data.message);
          return;
        }
        case createCapsuleResult.unexpectedError: {
          this.$emit('failed', data.message);
          return;
        }
        default: {
          this.$emit('failed', messages.unexpectedError);
          return;
        }
      }

      this.stripeRequest = {
        secret: this.externalKey,
      };

      this.loadDataPromise = this.stripe.confirmCardPayment(this.stripeRequest.secret, {
        payment_method: {
          card: this.card,
        },
      });

      const result = await this.loadDataPromise;

      if (result.error) {
        this.$emit('failed', result.error.message);
        return;
      }

      this.$emit('succeeded', this.createdCapsuleId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_functions.scss";
@import "@assets/sass/base/_mixins.scss";

.stripe-card {
  border: 0.125rem solid $color-primary;
  border-radius: rem(28);
  padding: rem(16) rem(24);
}
</style>
