<template>
  <div>
    <b-row>
      <b-col>
        <b-tabs lazy>
          <b-tab title="Main Info">
            <b-card class="border-left-0">
              <validation-observer
                ref="observer"
                tag="div"
              >
                <b-form @submit.prevent="save">
                  <b-row>
                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="FirstName"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="firstName"
                          class="l-required"
                        >
                          First Name
                        </label>

                        <b-form-input
                          id="firstName"
                          v-model="model.firstName"
                          type="text"
                          placeholder="First Name"
                        />
                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>

                      <template v-if="isResetPasswordEnabled">
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|password"
                          name="Password"
                          vid="password"
                          tag="div"
                          mode="eager"
                          class="position-relative form-group mb-0"
                        >
                          <label
                            for="password"
                            class="l-required"
                          >
                            Password
                          </label>

                          <b-form-input
                            id="password"
                            v-model="model.password"
                            type="password"
                            placeholder="Password"
                          />

                          <span class="position-absolute error">
                            {{ errors[0] }}
                          </span>
                        </validation-provider>

                        <validation-provider
                          v-slot="{ errors }"
                          rules="required|confirmed:password"
                          name="ConfirmPassword"
                          tag="div"
                          mode="eager"
                          class="position-relative form-group mb-0"
                        >
                          <label
                            for="confirmPassword"
                            class="l-required"
                          >
                            Confirm Password
                          </label>

                          <b-form-input
                            id="confirmPassword"
                            v-model="model.confirmPassword"
                            type="password"
                            placeholder="Confirm Password"
                          />

                          <span class="position-absolute error">
                            {{ errors[0] }}
                          </span>
                        </validation-provider>
                      </template>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100"
                        name="LastName"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="lastName"
                          class="l-required"
                        >
                          Last Name
                        </label>

                        <b-form-input
                          id="lastName"
                          v-model="model.lastName"
                          type="text"
                          placeholder="Last Name"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col
                      md="3"
                      class="mb-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:100|email"
                        name="Email"
                        tag="div"
                        mode="eager"
                        class="position-relative form-group mb-0"
                      >
                        <label
                          for="email"
                          class="l-required"
                        >
                          Email
                        </label>

                        <b-form-input
                          id="email"
                          v-model="model.email"
                          type="text"
                          placeholder="Email"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>
                    </b-col>

                    <b-col cols="12">
                      <div class="d-flex justify-content-end border-top pt-5">
                        <b-btn
                          variant="secondary"
                          class="mr-2"
                          @click="resetPassword"
                        >
                          <span v-if="!isResetPasswordEnabled">Reset Password</span>
                          <span v-else>Cancel Password Reset</span>
                        </b-btn>
                        <b-btn
                          variant="secondary"
                          class="mr-2"
                          @click="$router.back()"
                        >
                          Cancel
                        </b-btn>

                        <b-btn
                          type="submit"
                          variant="primary"
                        >
                          Save
                        </b-btn>
                      </div>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </b-card>
          </b-tab>

          <b-tab title="Notification Settings">
            <b-card>
              <user-notifications :user="model" />
            </b-card>
          </b-tab>

          <b-tab title="Capsules">
            <b-card>
              <user-capsules :user-id="model.id" />
            </b-card>
          </b-tab>

          <b-tab title="Cloud Capsules">
            <b-card>
              <user-cloud-capsules :user-id="model.id" />
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { usersApi } from '@services/api/admin';

export default {
  name: 'UserEdit',
  components: {
    // eslint-disable-next-line import/extensions
    UserCapsules: () => import('./components/user-capsules.vue'),
    // eslint-disable-next-line import/extensions
    UserCloudCapsules: () => import('./components/user-cloud-capsules.vue'),
    // eslint-disable-next-line import/extensions
    UserNotifications: () => import('./components/user-notifications.vue'),
  },
  data() {
    return {
      model: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        userNotifications: [],
      },
      isResetPasswordEnabled: false,
    };
  },
  async mounted() {
    this.model.id = Number(this.$route.params.id);
    await this.loadUserInfo();
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      await usersApi.update(this.model);

      await this.$router.push({
        name: this.$constants.routes.admin.users,
      });
    },
    async loadUserInfo() {
      const { data: { data } } = await usersApi.getById(this.model.id);
      this.model.firstName = data.firstName;
      this.model.lastName = data.lastName;
      this.model.email = data.email;
      this.model.userNotifications = data.userNotifications;
    },
    resetPassword() {
      this.model.password = '';
      this.isResetPasswordEnabled = !this.isResetPasswordEnabled;
    },
  },
};
</script>
