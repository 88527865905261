export default {
  payPal: {
    key: 'PayPal',
    name: 'PayPal',
  },
  stripe: {
    key: 'Stripe',
    name: 'Stripe',
  },
};
