import Axios from 'axios';

const baseControllerRoute = 'user-capsules/cloud';

function getAll() {
  return Axios.get(`${baseControllerRoute}`);
}
function getById(userCapsuleId) {
  return Axios.get(`${baseControllerRoute}/${userCapsuleId}`);
}

function create(payload) {
  return Axios.post(`${baseControllerRoute}`, payload);
}

function update(id, payload) {
  return Axios.put(`${baseControllerRoute}/${id}`, payload);
}

function calculatePrice(userCapsuleId) {
  return Axios.get(`${baseControllerRoute}/${userCapsuleId}/price`);
}

function initiatePayment(userCapsuleId, payload) {
  return Axios.post(`${baseControllerRoute}/${userCapsuleId}/payments`, payload);
}

function capturePayment(userCapsuleId) {
  return Axios.put(`${baseControllerRoute}/${userCapsuleId}/payments`);
}

function failPayment(userCapsuleId, payload) {
  return Axios.put(`${baseControllerRoute}/${userCapsuleId}/payments/fail`, payload);
}

function getFiles(userCapsuleId) {
  return Axios.get(`${baseControllerRoute}/${userCapsuleId}/files`);
}

function postFiles(userCapsuleId, payload) {
  return Axios.post(`${baseControllerRoute}/${userCapsuleId}/files`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function getCertificate(capsuleId) {
  return Axios.get(`${baseControllerRoute}/${capsuleId}/certificate`, {
    responseType: 'blob',
  });
}

export default {
  getById,
  create,
  update,
  getAll,
  calculatePrice,
  capturePayment,
  initiatePayment,
  failPayment,
  getFiles,
  postFiles,
  getCertificate,
};
