<template>
  <section
    id="video"
    class="section pt-20"
  >
    <div class="section__container container">
      <div class="section__wrap">
        <div
          class="section__body section__body--lg text-block text-center"
          data-aos="fade-up"
        >
          <p>
            You can upload various images, videos, sounds, text documents, and projects to capture
            your thoughts, ideas, and important events and share them with future generations.
          </p>
        </div>

        <b-row class="justify-content-center">
          <b-col md="8">
            <div class="video-block">
              <img
                v-if="!isPlaying"
                src="@/assets/images/landing/video-01.jpg"
                alt=""
              >

              <div
                ref="playerWrap"
                class="player-wrap"
              >
                <youtube-media
                  v-if="isPlaying"
                  ref="player"
                  :video-id="videoId"
                  player-width="100%"
                  player-height="100%"
                  class="video-block__media"
                  host="https://www.youtube-nocookie.com"
                  :player-vars="{ autoplay: 1 }"
                  :mute="isMobile"
                  @ready="ready"
                  @ended="onEnded"
                />
              </div>

              <button
                v-if="!isPlaying"
                ref="btn"
                class="video-block__btn-play"
                @click="playVideo"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.use(VueYouTubeEmbed, { global: true, componentId: 'youtube-media' });

export default {
  name: 'VideoSection',
  data() {
    return {
      videoId: 'iWEuNWyQXz8',
      isPlaying: false,
      player: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 992;
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playVideo() {
      this.isPlaying = true;

      if (this.player && this.player.playVideo) {
        this.player.playVideo();
      }
    },
    pauseVideo() {
      if (this.player && this.player.pauseVideo) {
        this.player.pauseVideo();
      }

      this.isPlaying = false;
    },
    onEnded() {
      this.isPlaying = false;
    },
  },
};
</script>

<style lang="scss">
@import '@assets/sass/_variables.scss';
@import '@assets/sass/base/_mixins.scss';

.video-block {
  position: relative;
  display: block;
  border-radius: 2.5rem;
  padding-bottom: 56%;
  overflow: hidden;

  @include respond-below(md) {
    border-radius: 1.5rem;
  }

  img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__btn-play {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 0.65rem solid $color-white;
    border-radius: 50%;
    width: 7.5rem;
    height: 7.5rem;
    background: rgba($color-black, 0.6) url('@/assets/images/landing/icons/play.svg') no-repeat
      center / 40%;
    transition: background-color 0.5s;

    @include respond-below(md) {
      border-width: 0.5rem;
      width: 6rem;
      height: 6rem;
      background-size: 30%;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: $color-black;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.html5-video-player {
  border-radius: 2.5rem !important;
  overflow: hidden;
}
</style>
