var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"user-header",class:{ '_scrolled': _vm.isScrolled }},[_c('div',{staticClass:"user-header__container container"},[_c('div',{staticClass:"user-header__wrap"},[_c('div',{staticClass:"user-header__wrap-element-left"},[_c('router-link',{staticClass:"user-header__logo user-logo",attrs:{"to":{ name: _vm.routes.landing.index }}},[_c('img',{attrs:{"src":require("@/assets/images/landing/logo.svg"),"alt":"IT EON"}}),_c('span',[_vm._v("It-eoN")])])],1),_c('nav',{staticClass:"user-header__nav user-header__wrap-element-center"},[_c('ul',{staticClass:"user-header__menu"},[_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routes.landing.index
              }}},[_vm._v(" Home ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routes.user.capsules.time
              }}},[_vm._v(" Time Capsules ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.isAuthorized
                  ? _vm.profileInfo.hasCloudCapsules
                    ? _vm.routes.user.capsules.cloudPersonalCabinet
                    : _vm.routes.user.capsules.cloud
                  : _vm.routes.user.capsules.cloud
              }}},[_vm._v(" Cloud Capsules ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                name: _vm.routes.user.capsules.index
              }}},[_vm._v(" Prices ")])],1)])]),_c('div',{staticClass:"d-flex user-header__social-and-login user-header__wrap-element-right"},[(!_vm.isAuthorized)?_c('social-networks-list',{staticClass:"landing-header__soc"}):_vm._e(),(!_vm.isAuthorized)?[_c('router-link',{staticClass:"landing-btn",attrs:{"to":{ name: _vm.routes.user.signin,
                   query: {
                     returnUrl: _vm.$route.name !== _vm.routes.landing.index && !_vm.$route.query.returnUrl ?
                       _vm.$route.path : _vm.$route.query.returnUrl
                   } }}},[_vm._v(" Sign in ")])]:[(_vm.isUser)?_c('Topbar'):_c('router-link',{staticClass:"landing-btn",attrs:{"to":"/admin/capsules"}},[_vm._v(" Dashboard ")])]],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }