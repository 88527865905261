<template>
  <div class="table-auto">
    <b-table
      v-if="!slotBody"
      ref="table"
      v-loader="loadDataPromise"
      :responsive="responsive"
      class="manage-table kt-datatable kt-datatable--default
       kt-datatable--brand kt-datatable--loaded table-responsive table-vertical-middle mb-0"
      :striped="striped"
      :bordered="bordered"
      :outlined="outlined"
      :show-empty="showEmpty"
      :hover="hover"
      :items="table.items"
      :fields="decoratedFields"
      :class="classes"
      :thead-tr-class="theadTrClass"
      :tbody-tr-class="tbodyTrClass"
      :table-class="tableClass"
      :thead-class="theadClass"
      :tbody-class="tbodyClass"
      :caption-top="captionTop"
      selectable
      selected-variant=""
      select-mode="single"
      :per-page="tableProcessor.pager.settings.pageSize"
      :current-page="currentPage"
      :no-local-sorting="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortByDesc"
      @row-selected="rowClicked"
      @sort-changed="sortChangeHandler"
    >
      <template
        v-for="(_, key) in scopedSlots"
        #[`cell(${key})`]="props"
      >
        <slot
          :name="key"
          v-bind="props"
        />
      </template>

      <template #empty="">
        <div class="w-100 table-empty">
          {{ emptyTableMessage }}
        </div>
      </template>

      <template slot="HEAD_select">
        <label class="kt-checkbox kt-checkbox--single kt-checkbox--all kt-checkbox--solid">
          <input
            v-model="headerSelect"
            type="checkbox"
            :disabled="table.items.length < 1"
            @change="toggleMassSelect"
          >
          &nbsp;
          <span />
        </label>
      </template>

      <template
        v-if="selectable"
        slot="select"
        slot-scope="data"
      >
        <label class="kt-checkbox kt-checkbox--single kt-checkbox--all kt-checkbox--solid">
          <input
            v-model="data.item.selected"
            type="checkbox"
            @change="selected"
          >
          &nbsp;
          <span />
        </label>
      </template>

      <template
        v-if="caption"
        slot="table-caption"
      >
        <h5 class="m-0 pl-2">
          {{ caption }}
        </h5>
      </template>
    </b-table>

    <template v-else>
      <slot
        :data="table.items"
        :message="emptyTableMessage"
      />
    </template>

    <template v-if="paginate">
      <slot name="pagination-top" />

      <div class="kt-portlet__space-x kt-portlet__space-y">
        <div class="kt-pagination kt-pagination--brand">
          <div
            v-if="showTotalItems"
            class="kt-pagination__items"
          >
            <div>
              {{ getPaginationText }}
            </div>

            <div>
              Show

              <b-form-select
                v-model="tableProcessor.pager.settings.pageSize"
                :options="pageOptions"
                class="kt-pagination__items__select"
              />

              entries
            </div>
          </div>

          <b-pagination
            v-model="tableProcessor.pager.settings.page"
            :total-rows="tableProcessor.pager.settings.totalItems"
            :per-page="tableProcessor.pager.settings.pageSize"
            :first-text="tableProcessor.pager.settings.firstText"
            :prev-text="tableProcessor.pager.settings.prevText"
            :next-text="tableProcessor.pager.settings.nextText"
            :last-text="tableProcessor.pager.settings.lastText"
            @input="tableProcessor.pager.events.pageChanged"
          />
        </div>
      </div>
    </template>

    <template v-if="isLazyLoad">
      <b-btn
        v-if="showLoadMoreButton"
        block
        variant="outline-primary"
        @click="loadMore"
      >
        <i class="las la-redo-alt" />
        Load more
      </b-btn>
    </template>
  </div>
</template>

<script>
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import { messages, sortingDirections } from '@constants';
import {
  TableProcessor,
  TableEventBus,
} from '@utils/table';

const FirstPage = 1;

function processFields(newValue) {
  if (this.selectable) {
    const selectConfiguration = {
      select: {
        label: 'Select',
        sortable: false,
      },
    };

    this.decoratedFields = Object.assign(selectConfiguration, newValue);

    return;
  }

  this.decoratedFields = newValue;
}

export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    customClass: {
      type: String,
      required: false,
      default: '',
    },
    selectable: {
      type: Boolean,
      required: false,
    },
    striped: {
      type: Boolean,
      required: false,
    },
    bordered: {
      type: Boolean,
      required: false,
    },
    outlined: {
      type: Boolean,
      required: false,
    },
    showEmpty: {
      type: Boolean,
      required: false,
    },
    responsive: {
      type: Boolean,
      required: false,
    },
    hover: {
      type: Boolean,
      required: false,
    },
    captionTop: {
      type: Boolean,
      required: false,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
    tbodyTrClass: {
      type: [String, Array, Function],
      default: '',
    },
    theadTrClass: {
      type: String,
      default: '',
    },
    tableClass: {
      type: String,
      default: '',
    },
    theadClass: {
      type: String,
      default: '',
    },
    tbodyClass: {
      type: String,
      default: '',
    },
    tdClass: {
      type: String,
      default: '',
    },
    thClass: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
    },
    caption: {
      type: String,
      default: '',
    },
    loadDataCallback: {
      type: Function,
      required: false,
      default: () => {
      },
    },
    toTableDataItem: {
      type: Function,
      default: (item) => item,
    }, // replace to no-pagination with default value 'false'
    paginate: {
      type: Boolean,
      default: false,
    },
    filterSettings: {
      type: Object,
      default: null,
    },
    pagingParams: {
      type: Object,
      default: null,
    },
    filterModel: {
      type: Object,
      default: null,
    },
    totalItems: {
      type: Number,
      default: 0,
      required: false,
    },
    dataTransformFn: {
      type: Function,
      default: undefined,
    },
    isLazyLoad: {
      type: Boolean,
      default: false,
    },
    payloadAsQueryParams: {
      type: Boolean,
      default: true,
    },
    showTotalItems: {
      type: Boolean,
      default: false,
    },
    slotBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      table: {
        processor: new TableProcessor(
          this.loadTableDataCallback,
          this.filterSettings,
          this.pagingParams,
          this.payloadAsQueryParams,
        ),
        title: '',
        fields: this.fields,
        items: [],
      },
      emptyTableMessage: null,
      scopedSlots: this.$scopedSlots || {},
      decoratedFields: [],
      headerSelect: false,
      loadDataPromise: null,
      pageOptions: ['5', '10', '25', '50', '100'],
      sortBy: '',
      isSortByDesc: false,
    };
  },
  computed: {
    classes() {
      const classes = [this.customClass];
      if (this.clickable) {
        classes.push('cursor-pointer');
      }

      return classes;
    },
    tableProcessor() {
      return this.table.processor;
    },
    showLoadMoreButton() {
      const { totalItems } = this.tableProcessor.pager.settings;
      return this.table.items.length && totalItems > this.table.items.length;
    },
    getPaginationText() {
      const total = this.tableProcessor.pager.settings.totalItems;
      const itemsOnPage = this.tableProcessor.pager.settings.pageSize;
      const { page } = this.tableProcessor.pager.settings;

      const start = (page - 1) * itemsOnPage + 1;
      const end = Math.min(page * itemsOnPage, total);
      return `Showing ${start} to ${end} of ${total} entries`;
    },
  },
  watch: {
    fields(newValue) {
      processFields.call(this, newValue);
    },
    'table.items': {
      deep: false,
      handler(newItems) {
        this.headerSelect = newItems.length > 0
          && newItems.length === (newItems.filter((_) => _.selected)).length;
      },
    },
    // eslint-disable-next-line func-names
    'tableProcessor.pager.settings.pageSize': async function () {
      await this.loadData();
    },
  },
  created() {
    processFields.call(this, this.fields);
  },
  mounted() {
    this.scopedSlots = this.$scopedSlots;

    const sortingField = this.fields.find((f) => f.defaultSorting !== undefined);
    if (sortingField) {
      this.sortBy = sortingField.key;
      this.isSortByDesc = sortingField.defaultSorting === sortingDirections.descending;
      this.tableProcessor
        .updateSortingSettings({ sortDesc: this.isSortByDesc, sortBy: this.sortBy });
    }

    if (this.filterModel) {
      this.tableProcessor.filter.filter = cloneDeep(this.filterModel);
    }

    this.initEvents();
    this.tableProcessor.init();
  },
  beforeDestroy() {
    TableEventBus.$off();
  },
  methods: {
    toggleMassSelect() {
      this.table.items.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.selected = this.headerSelect;
      });
      this.$emit('row-selected', this.table.items.filter((_) => _.selected));
    },
    rowClicked(items) {
      if (this.clickable && items.length) {
        const item = items[0];
        this.$refs.table.clearSelected();
        this.$emit('row-clicked', item);
      }
    },
    selected() {
      this.$nextTick().then(() => {
        this.headerSelect = this.table.items.length > 0
          && this.table.items.length === (this.table.items.filter((_) => _.selected)).length;

        this.$emit('row-selected', this.table.items.filter((_) => _.selected));
      });
    },
    async loadData() {
      const { data } = await this.loadTableData(
        this.tableProcessor.buildPayload({
          page: this.table.processor.pager.settings.page,
          pageSize: this.table.processor.pager.settings.pageSize,
        }),
      );
      this.setTableData(data);
    },
    async loadTableDataCallback(payload) {
      const { data } = await this.loadTableData(payload);
      this.setTableData(data);
    },
    async loadTableData(payload) {
      try {
        this.emptyTableMessage = null;
        this.loadDataPromise = this.loadDataCallback(payload);
        const { data } = await this.loadDataPromise;
        this.emptyTableMessage = messages.emptyTableMessage;
        return data;
      } catch (e) {
        this.emptyTableMessage = messages.tableErrorMessage;
        throw e;
      } finally {
        this.loadDataPromise = null;
      }
    },
    setTableData(data) {
      if (!data.data && isArray(data)) {
        this.table.items = this.isLazyLoad && data.pagingHeader.page !== 1
          ? [...this.table.items, ...data.map(this.toTableDataItem)]
          : data.map(this.toTableDataItem);
      } else {
        this.table.items = this.isLazyLoad && data.pagingHeader.page !== 1
          ? [...this.table.items, ...data.data.map(this.toTableDataItem)]
          : data.data.map(this.toTableDataItem);
      }

      if (this.dataTransformFn) {
        this.dataTransformFn(this.table.items);
      }

      if (data.pagingHeader) {
        this.tableProcessor.updatePagingHeader(data.pagingHeader);
        this.$emit('update:totalItems', data.pagingHeader.totalItems);
      }
    },
    async sortChangeHandler(sortingContext) {
      this.resetPaging();
      this.tableProcessor.updateSortingSettings(sortingContext);
      await this.loadData();
    },
    initEvents() {
      TableEventBus.$on('search', async (term) => {
        this.resetPaging();
        this.tableProcessor.filter.term = term;
        await this.loadData();
      });

      TableEventBus.$on('updateFilterModel', async (filterModel) => {
        this.resetPaging();
        this.tableProcessor.filter.filter = cloneDeep(filterModel);
        await this.loadData();
      });

      TableEventBus.$on('refreshTable', async () => {
        await this.loadData();
      });
    },
    resetPaging() {
      this.tableProcessor.pager.settings.page = FirstPage;
    },
    loadMore() {
      // eslint-disable-next-line no-plusplus
      this.tableProcessor.pager.events.pageChanged(++this.tableProcessor.pager.settings.page);
    },
  },
};

</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.table {
  .kt-checkbox {
    &.kt-checkbox--single {
      height: 10px;
    }
  }

  th[aria-sort] {
    > div {
      display: inline-block;
    }

    &::before {
      content: "↕";
      display: inline-block;
      margin-right: 0.25em;
      font-size: inherit;
      line-height: 1;
      opacity: 0.4;
      transition: $transition-base;
    }
  }

  th[aria-sort][aria-sort=ascending]::before {
    content: "↓";
    opacity: 1;
  }

  th[aria-sort][aria-sort=descending]::before {
    content: "↑";
    opacity: 1;
  }

  &.b-table {
    > thead {
      > tr {
        > th[aria-sort] {
          background-image: none;
        }
      }
    }
  }
}

.table-empty {
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-auto {
  width: -webkit-fill-available;
  color: $color-black;

  .manage-table {
    tr {
      outline: none;
    }

    td,
    th {
      color: $color-black;

      &.content-width {
        width: 1%;
        white-space: nowrap;
      }
    }

    &.table-vertical-middle {
      td,
      th {
        vertical-align: middle;
      }
    }

    th {
      white-space: nowrap;
    }
  }

  .kt-pagination {
    padding: 34px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include respond-below(xs) {
      flex-direction: column;
      text-align: center;
    }

    &__items {
      margin: 10px 25px 10px 0;
      flex: 0 0 calc(42% - 25px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-weight: normal;
      font-size: 13px;
      white-space: nowrap;

      @include respond-below(xs) {
        margin: 10px 0 10px 0;
        flex-direction: column;
        text-align: center;
      }

      div {
        padding: 10px 0;

        &:first-child {
          margin-right: 20px;

          @include respond-below(xs) {
            margin-right: 0;
          }
        }
      }

      &__select {
        width: fit-content;
        display: inline-block;
      }
    }

    .pagination {
      flex: 0 0 58%;
      margin: 0;

      @include respond-below(lg) {
        flex: 0 0 100%;
        justify-content: center;
      }

      .page-item {
        margin: 0 2px;
        text-align: center;

        .page-link {
          border: none;
          border-radius: 6px;
          width: 30px;
          height: 30px;
          font-size: 13px;
          font-weight: 500;
          line-height: 1;
          color: $color-primary;
          transition: $transition-base;
        }

        &.active {
          .page-link {
            background-color: $color-primary;
            color: $color-white;
          }
        }

        &:first-child,
        &:nth-child(2),
        &:nth-last-child(2),
        &:last-child {
          .page-link {
            font-size: 16px;
            background-color: #f3f6f9;
          }
        }

        &.disabled {
          &:first-child,
          &:nth-child(2),
          &:nth-last-child(2),
          &:last-child {
            .page-link {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
</style>
