export default {
  pending: {
    key: 'Pending',
    name: 'Pending',
  },
  success: {
    key: 'Success',
    name: 'Success',
  },
  failed: {
    key: 'Failed',
    name: 'Failed',
  },
};
