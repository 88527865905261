export default {
  builders: [
    () => ({
      text: 'Dashboard',
      to: { name: 'admin.dashboard' },
    }),
  ],
  build() {
    return [
      {
        text: 'Dashboard',
        to: { name: 'admin.dashboard' },
      },
    ];
  },
};
