<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="border-left-0">
          <validation-observer
            ref="observer"
            tag="div"
          >
            <b-form @submit.prevent="save">
              <b-row>
                <b-col
                  md="3"
                  class="mb-5"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:100"
                    name="Subject"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-4"
                  >
                    <label
                      for="subject"
                      class="l-required"
                    >
                      Subject
                    </label>

                    <b-form-input
                      id="subject"
                      v-model="model.subject"
                      type="text"
                      placeholder="Subject"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:500"
                    name="Message"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-0"
                  >
                    <label
                      for="message"
                      class="l-required"
                    >
                      Message
                    </label>

                    <b-form-textarea
                      id="message"
                      v-model="model.message"
                      type="text"
                      placeholder="Message"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>
                </b-col>

                <b-col cols="12">
                  <div class="d-flex justify-content-end border-top pt-5">
                    <b-btn
                      variant="secondary"
                      class="mr-2"
                      @click="$router.back()"
                    >
                      Cancel
                    </b-btn>

                    <b-btn
                      type="submit"
                      variant="primary"
                    >
                      Save
                    </b-btn>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { notificationsApi } from '@services/api/admin';

export default {
  name: 'NotificationEditPage',
  data() {
    return {
      model: {
        id: '',
        name: '',
        subject: '',
        message: '',
      },
    };
  },
  async mounted() {
    this.model.id = this.$route.params.id;
    await this.loadNotificationDetails();
  },
  methods: {
    async loadNotificationDetails() {
      const { data: { data } } = await notificationsApi.getById(this.model.id);

      this.model.name = data.name;
      this.model.subject = data.subject;
      this.model.message = data.message;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      await notificationsApi.update(this.model);

      await this.$router.push({
        name: this.$constants.routes.admin.notifications,
      });
    },
  },
};
</script>
