<template>
  <section class="section">
    <div class="section__container container">
      <div class="section__wrap">
        <b-row class="justify-content-between align-items-center">
          <b-col
            md="4"
            class="mb-5 d-none d-md-block"
          >
            <div
              class="section__img"
              data-aos="fade-right"
            >
              <img
                src="@/assets/images/landing/img-text-01.png"
                alt=""
              >
            </div>
          </b-col>

          <b-col
            md="7"
            class="mb-5"
          >
            <div
              class="section__body section__body--md text-block"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              <p>
                Do you want to leave your mark on the Earth or even the Universe?
              </p>

              <p>
                Do you want to take part in a project that will become a real-world
                event and open up new possibilities for future generations?
              </p>

              <p>
                Then welcome to It-eoN - a project that will surprise you with
                its novelty and relevance!
              </p>
            </div>

            <div class="section__btn-box">
              <div
                data-aos="fade-left"
                data-aos-delay="400"
              >
                <router-link
                  to="/signup"
                  class="landing-btn"
                >
                  Get Started
                </router-link>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ImgTextSection',
};
</script>
