import Axios from 'axios';

const baseControllerRoute = 'notifications';

function get() {
  return Axios.get(`${baseControllerRoute}`);
}

export default {
  get,
};
