import Axios from 'axios';

const baseControllerRoute = 'admin/capsules';

function get(payload) {
  return Axios.get(`${baseControllerRoute}`, { params: payload });
}

function getPurchased() {
  return Axios.get(`${baseControllerRoute}/purchased`);
}

function getById(id) {
  return Axios.get(`${baseControllerRoute}/${id}`);
}

function create(payload) {
  return Axios.post(`${baseControllerRoute}`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function update(payload) {
  return Axios.patch(`${baseControllerRoute}`, payload);
}

function remove(id) {
  return Axios.delete(`${baseControllerRoute}/${id}`);
}

export default {
  get,
  getById,
  create,
  update,
  remove,
  getPurchased,
};
