<template>
  <b-modal
    v-model="isShow"
    title="Profile"
    hide-footer
    centered
    no-close-on-backdrop
    modal-class="_secondary"
    @close="onClose"
  >
    <div
      v-loader="loadDataPromise"
    >
      <b-alert
        variant="danger"
        :show="!!messages.errorMessage"
      >
        {{ messages.errorMessage }}
      </b-alert>

      <b-alert
        variant="light-primary"
        :show="!!messages.infoMessage"
      >
        {{ messages.infoMessage }}
      </b-alert>

      <validation-observer
        id="validation-update-profile"
        ref="observer"
        tag="div"
      >
        <b-form
          :v-loader:="loadDataPromise"
          @submit.prevent="updateProfile"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Fist Name"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="fistName"
              v-model="profile.firstName"
              type="text"
              placeholder="First Name"
            />

            <span class="position-absolute error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Last Name"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="profileSurname"
              v-model="profile.lastName"
              type="text"
              placeholder="Last Name"
            />

            <span class="position-absolute error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            name="Email"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="profileEmail"
              v-model="profile.email"
              type="text"
              placeholder="Email"
            />

            <span class="position-absolute error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <b-row class="flex-wrap-reverse align-items-center">
            <b-col
              md="6"
              class="text-center text-md-left mb-3"
            >
              <button
                type="reset"
                class="link"
                @click="hide"
              >
                Cancel
              </button>
            </b-col>

            <b-col
              md="6"
              class="mb-3"
            >
              <button
                type="submit"
                class="landing-btn w-100"
              >
                Update
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { UPDATE_PROFILE_INFO } from '@services/store/profile.module';
import store from '@store';
import { messages } from '@app/constants';

export default {
  name: 'ProfileSettingsModal',
  data() {
    return {
      loadDataPromise: null,
      isShow: false,
      profile: {
        firstName: '',
        lastName: '',
        email: '',
        userNotifications: [],
      },
      messages: {
        errorMessage: '',
        infoMessage: '',
      },
    };
  },
  computed: {
    ...mapGetters(['profileInfo']),
  },
  methods: {
    show() {
      this.isShow = true;
      this.profile = {
        ...this.profileInfo,
      };
    },
    hide() {
      this.clearMessages();
      this.isShow = false;
    },
    async updateProfile() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      try {
        this.clearMessages();
        this.loadDataPromise = store.dispatch(UPDATE_PROFILE_INFO, this.profile);
        const updatedProfile = await this.loadDataPromise;
        this.messages.infoMessage = 'Profile info was updated';
        this.profile = {
          ...updatedProfile,
        };
        this.hide();
      } catch (err) {
        if (err.response?.data?.errorMessages) {
          this.messages.errorMessage = err.response?.data?.errorMessages.join('. ');
        } else {
          this.messages.errorMessage = messages.unexpectedError;
        }
      }
    },
    onClose() {
      this.clearMessages();
      this.$refs.observer.reset();
    },
    clearMessages() {
      this.messages.errorMessage = '';
      this.messages.infoMessage = '';
    },
  },
};
</script>
