<template>
  <section class="d-flex flex-column py-10">
    <div class="container">
      <h1 class="font-weight-bold">
        404
      </h1>

      <p class="h3">
        OOPS! Something went wrong here
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFoundPage',
};
</script>
