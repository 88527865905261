<template>
  <footer class="landing-footer">
    <div class="landing-footer__container container">
      <div class="landing-footer__wrap">
        <div class="landing-footer__row">
          <div class="landing-footer__col text-center text-lg-left">
            <div class="d-inline-flex align-items-center flex-column flex-lg-row">
              <router-link
                :to="{ name: 'user.capsules.index' }"
                class="user-logo mb-3 mb-lg-0 mr-lg-5"
              >
                <img
                  src="@/assets/images/landing/logo.svg"
                  alt="IT-EON"
                >
                <span>It-eoN</span>
              </router-link>

              <small class="landing-footer__copyright">
                © 2023 All Rights Reserved
              </small>
            </div>
          </div>

          <div class="landing-footer__col">
            <nav class="landing-footer__nav">
              <ul class="landing-footer__menu">
                <li>
                  <router-link :to="{ name: 'terms-and-conditions.index' }">
                    Terms of Use
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'privacy-policy.index' }">
                    Privacy Policy
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>

          <div class="landing-footer__col align-self-start text-center text-lg-left">
            <h6 class="landing-footer__heading mb-1">
              Contact Us
            </h6>

            <nav class="landing-footer__nav">
              <ul class="landing-footer__menu">
                <li>
                  Email:
                  <a
                    href="mailto:support@it-eon.io"
                    class="text-primary"
                  >
                    support@it-eon.io
                  </a>
                </li>
                <!-- <li>
                  Phone:
                  <a
                    href="tel:+17542742824"
                    class="text-primary"
                  >
                    754.274.2824
                  </a>
                </li> -->
              </ul>
            </nav>
          </div>

          <div class="landing-footer__col align-self-end text-center text-lg-left">
            <h6 class="landing-footer__heading mb-1">
              Follow Us
            </h6>

            <social-networks-list class="landing-footer__soc" />
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LandingFooter',
};
</script>
