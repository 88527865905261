<template>
  <div
    id="kt_aside"
    ref="kt_aside"
    class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
  >
    <KTBrand />
    <div
      id="kt_aside_menu_wrapper"
      class="aside-menu-wrapper flex-column-fluid"
    >
      <div
        id="kt_aside_menu"
        ref="kt_aside_menu"
        class="aside-menu my-4"
        data-menu-vertical="1"
        data-menu-dropdown-timeout="500"
      >
        <perfect-scrollbar
          class="aside-menu scroll"
          style="max-height: 90vh; position: relative;"
        >
          <KTMenu />
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script lang="babel">
import { mapGetters } from 'vuex';
import KTLayoutAside from '@assets/js/layout/base/aside';
import KTLayoutAsideMenu from '@assets/js/layout/base/aside-menu';
import KTMenu from './menu';
import KTBrand from './brand';

export default {
  name: 'AsideLayout',
  components: {
    KTBrand,
    KTMenu,
  },
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
    };
  },
  computed: {
    ...mapGetters(['getClasses']),
  },
  mounted() {
    this.$nextTick(() => {
      KTLayoutAside.init(this.$refs.kt_aside);
      KTLayoutAsideMenu.init(this.$refs.kt_aside_menu);
    });
  },
};

</script>
