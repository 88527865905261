<template>
  <div
    id="paypal-button-container"
    class="d-flex"
  />
</template>

<script >
import { createCapsuleResult, messages, paymentProviders } from '@app/constants';
import { INITIATE_PAYMENT, CAPTURE_PAYMENT } from '@store/time-capsule-payments.module';
import store from '@store';

export default {
  name: 'PayPalElements',
  props: {
    capsuleId: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
  },
  emits: ['succeeded', 'failed', 'insufficient-space', 'throttling'],
  data() {
    return {
      createdCapsuleId: null,
      externalKey: null,
    };
  },
  methods: {
    init() {
      const self = this;

      // eslint-disable-next-line no-undef
      paypal.Buttons({
        createOrder() {
          return store.dispatch(INITIATE_PAYMENT, {
            paymentProvider: paymentProviders.payPal.key,
            timeCapsuleId: self.capsuleId,
          }).then((data) => {
            switch (data.status) {
              case createCapsuleResult.created: {
                self.createdCapsuleId = data.id;
                self.externalKey = data.externalKey;
                break;
              }
              case createCapsuleResult.insufficientSpace: {
                self.$emit('insufficient-space', data.message);
                return '';
              }
              case createCapsuleResult.createCapsuleThrottling: {
                self.$emit('throttling', data.message);
                return '';
              }
              case createCapsuleResult.unexpectedError: {
                self.$emit('failed', data.message);
                return '';
              }
              default: {
                self.$emit('failed', messages.unexpectedError);
                return '';
              }
            }

            return data.externalKey;
          });
        },
        onApprove() {
          return store.dispatch(CAPTURE_PAYMENT)
            .then(() => {
              self.$emit('succeeded', 'Transaction completed.');
            });
        },
        onError() {
        },
        style: {
          layout: 'horizontal',
          color: 'blue',
          shape: 'rect',
          label: 'paypal',
          height: 54,
          borderRadius: '3rem',
          tagline: false,
        },
      }).render('#paypal-button-container');
    },
  },
};
</script>
