import VueScrollTo from 'vue-scrollto';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 992;
    },
  },
  methods: {
    scrollToFailedField(selector) {
      const container = selector ? document.querySelector(selector) : this.$el;
      const el = container.querySelector('.is-invalid');
      VueScrollTo.scrollTo(el, 300, { offset: -150 });
    },
    scrollToTop(onlyMobile = false) {
      if (onlyMobile) {
        if (this.isMobile) {
          window.scrollTo(0, 0);
        }
      } else {
        window.scrollTo(0, 0);
      }
    },
    scrollToElement(selector) {
      const element = document.querySelector(selector);
      VueScrollTo.scrollTo(element);
    },
    handleScroll() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
