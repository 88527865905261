export default {
  build(prefix, value) {
    if (!value) {
      return { [prefix]: null };
    }
    const result = {};
    Object.keys(value).forEach((key) => {
      if (value[key] instanceof Object && !Array.isArray(value[key])) {
        Object.keys(value[key]).forEach((subkey) => {
          result[`${prefix}.${key}.${subkey}`] = value[key][subkey];
        });
        return;
      }
      result[`${prefix}.${key}`] = value[key];
    });
    return result;
  },
};
