const signoutMessage = 'Your session has been expired. Please log in again.';
const unauthorizeErrorMessage = 'You have tried to signin using invalid credentials';
const emptyFileMessage = 'No file selected';
const multipleFilesMessage = 'Select only one file with the extension .xlsx';
const wrongFileExtensionMessage = 'The file must be with the extension .xlsx';
const somethingWentWrong = 'Something went wrong. Please contact your system administrator';
const assessmentSuccessfullyStarted = 'Assessment successfully started';
const emptyTableMessage = 'There are no records to show';
const tableErrorMessage = 'An error occurred. Records cannot be displayed';
const personaTypeChanged = 'Persona type was successfully updated';
const assessmentAlreadyFinished = 'This assessment is already finished!';
const assessmentSuccessfullyFinished = 'Assessment successfully completed!';
const assessmentLinkExpired = 'Assessment is no longer active!';
const unexpectedError = 'Oops... We are sorry, unexpected error occured. Please try again later or contact system administrator';

export default {
  signoutMessage,
  unauthorizeErrorMessage,
  emptyFileMessage,
  multipleFilesMessage,
  wrongFileExtensionMessage,
  somethingWentWrong,
  assessmentSuccessfullyStarted,
  emptyTableMessage,
  tableErrorMessage,
  personaTypeChanged,
  assessmentAlreadyFinished,
  assessmentSuccessfullyFinished,
  assessmentLinkExpired,
  unexpectedError,
};
