var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topbar-item"},[_c('div',{staticClass:"topbar-item__row"},[_c('div',{staticClass:"topbar-item__text"},[_c('label',{staticClass:"topbar-item__label"},[_vm._v(" Account ")]),(_vm.showFullName)?_c('h3',{staticClass:"topbar-item__name"},[_vm._v(" "+_vm._s(_vm.userFullName)+" ")]):_vm._e(),_c('button',{staticClass:"topbar-item__name",on:{"click":_vm.showPopup}},[_vm._v(" "+_vm._s(_vm.userEmail)+" ")]),_c('b-link',{staticClass:"topbar-item__link",on:{"click":_vm.signout}},[_vm._v(" Log Out ")])],1),_c('div',{staticClass:"topbar-item__initial",on:{"click":_vm.showPopup}},[_vm._v(" "+_vm._s(_vm.initial)+" ")])]),_c('div',{staticClass:"topbar-item__popup",class:{ '_active': _vm.isPopupActive }},[_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePopup),expression:"hidePopup"}],staticClass:"topbar-item__popup-menu"},[_c('li',[_c('button',{on:{"click":_vm.showProfileModal}},[_vm._v(" Edit Profile ")])]),_c('li',[_c('button',{on:{"click":_vm.showNotificationSettingsModal}},[_vm._v(" Notification Settings ")])]),_c('li',[_c('button',{on:{"click":_vm.showChangePasswordModal}},[_vm._v(" Change Password ")])]),_c('li',{staticClass:"mobile-only"},[_c('router-link',{attrs:{"to":{
            name: _vm.routes.user.capsules.time
          }}},[_vm._v(" Time Capsules ")])],1),_c('li',{staticClass:"mobile-only"},[_c('router-link',{attrs:{"to":{
            name: _vm.profileInfo.hasCloudCapsules
              ? _vm.routes.user.capsules.cloudPersonalCabinet
              : _vm.routes.user.capsules.cloud
          }}},[_vm._v(" Cloud Capsules ")])],1),_c('li',{staticClass:"mobile-only"},[_c('router-link',{attrs:{"to":{
            name: _vm.routes.user.capsules.index
          }}},[_vm._v(" Prices ")])],1)])]),_c('profile-settings-modal',{ref:"profileSettingsModal"}),_c('notification-settings-modal',{ref:"notificationSettingsModal"}),_c('update-password-modal',{ref:"updatePasswordModal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }