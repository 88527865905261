<template>
  <section class="signin-page">
    <div class="signin-page__container container">
      <div class="signin-page__wrap">
        <b-row class="justify-content-center">
          <b-col
            md="6"
            xl="4"
          >
            <validation-observer
              ref="observer"
              tag="div"
            >
              <b-form
                class="card"
                @submit.prevent="login"
              >
                <div
                  v-loader="loadDataPromise"
                  class="card-body"
                >
                  <h2 class="text-center text-primary font-weight-bold mb-5 mb-md-10">
                    Sign In
                  </h2>

                  <b-alert
                    variant="danger"
                    :show="!!errorMessage"
                  >
                    {{ errorMessage }}
                  </b-alert>

                  <b-alert
                    variant="light-primary"
                    :show="!!infoMessage"
                  >
                    {{ infoMessage }}
                  </b-alert>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="Email"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-6"
                  >
                    <b-form-input
                      id="email"
                      v-model="model.email"
                      type="text"
                      placeholder="Email address"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Password"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-6"
                  >
                    <b-form-input
                      id="password"
                      v-model="model.password"
                      type="password"
                      placeholder="Password"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <div class="mb-6">
                    <button
                      type="submit"
                      class="landing-btn w-100"
                    >
                      Sign In
                    </button>
                  </div>

                  <div class="d-flex align-items-center mb-8">
                    <google-signin-btn @failed="googleSigninFailed" />

                    <router-link
                      :to="{ name: $constants.routes.user.resetPassword }"
                      class="text-nowrap font-weight-bold ml-3"
                    >
                      Forgot Password
                    </router-link>
                  </div>

                  <div class="text-center">
                    <p class="mb-1">
                      Don`t have an account?
                      <router-link
                        class="text-white font-weight-bold"
                        :to="{ name: $constants.routes.user.signup,
                               query: { returnUrl: returnUrl } }"
                      >
                        Sign Up
                      </router-link>
                    </p>

                    <p class="mb-0" />
                  </div>
                </div>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script lang="babel">
import { SIGNIN_USER } from '@services/store/profile.module';
import store from '@store';
import { messages } from '@constants';
import { GoogleSigninBtn } from '@components';
import { mapGetters } from 'vuex';

export default {
  name: 'SignInPage',
  components: {
    GoogleSigninBtn,
  },
  data() {
    return {
      loadDataPromise: null,
      model: {
        email: '',
        password: '',
      },
      errorMessage: '',
      infoMessage: '',
      returnUrl: this.$route.query.returnUrl,
    };
  },
  computed: {
    ...mapGetters(['profileInfo']),
  },
  watch: {
    '$route.params.infoMessage': {
      handler(val) {
        this.infoMessage = val;
      },
      immediate: true,
    },
  },
  methods: {
    async login() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      try {
        this.clearMessages();
        this.loadDataPromise = store.dispatch(SIGNIN_USER, this.model);
        await this.loadDataPromise;

        if (this.returnUrl) {
          await this.$router.push(this.returnUrl);
        } else {
          await this.$router.push({ name: this.$constants.routes.user.capsules.index });
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          this.errorMessage = messages.unauthorizeErrorMessage;
        } else {
          this.errorMessage = messages.unexpectedError;
        }
      }
    },
    googleSigninFailed(errorMessage) {
      this.errorMessage = errorMessage;
    },
    clearMessages() {
      this.errorMessage = '';
      this.infoMessage = '';
    },
  },
};
</script>
