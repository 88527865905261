/* ============
 * Vue Router
 * ============
 *
 * The official Router for Vue.js. It deeply integrates with Vue.js core
 * to make building Single Page Applications with Vue.js a breeze.
 *
 * http://router.vuejs.org/en/index.html
 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  admin as adminRoutes,
  user as userRoutes,
} from '@constants/route-names';
import { messages, roles } from '@app/constants';
import { SIGNOUT, REAUTHENTICATE } from '@services/store/profile.module';
import routes from './app/routes';
import store from './app/services/store';

function setBreadcrumbs(to, next) {
  if (to.meta?.breadcrumbs && !to.params?.breadcrumbs) {
    const crumbs = to.meta.breadcrumbs.map((createBreadcrumb) => createBreadcrumb(to.params));
    Promise.all(crumbs).then((values) => {
      // eslint-disable-next-line no-param-reassign
      to.params.breadcrumbs = values;
      next();
    });
  } else {
    next();
  }
}

let router;
function createRouter() {
  router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, _, next) => {
    if (to.meta?.auth && store.getters.isAuthorized && store.getters.isExpiredToken()) {
      store.dispatch(REAUTHENTICATE, {
        accessToken: store.getters.userToken,
        refreshToken: store.getters.userRefreshToken,
      }).then((isReAuthSuccess) => {
        if (!isReAuthSuccess || store.getters.isExpiredToken()) {
          store.dispatch(SIGNOUT).then(() => {
            next({
              name: store.getters.userRole === roles.admin
                ? adminRoutes.signin
                : userRoutes.signin,
              params: { infoMessage: messages.signoutMessage },
            });
          });
        } else {
          setBreadcrumbs(to, next);
        }
      });
    } else if (to.meta?.auth && !store.getters.isAuthorized) {
      next({
        name: store.getters.userRole === roles.admin
          ? adminRoutes.signin
          : userRoutes.signin,
      });
    } else if (store.getters.isAuthorized
      && (to.name === adminRoutes.signin || to.name === userRoutes.signin
        || to.name === userRoutes.signup)) {
      next({
        name: store.getters.userRole === roles.admin
          ? adminRoutes.dashboard
          : userRoutes.capsules.index,
      });
    } else if (to.meta.roles && !to.meta.roles.includes(store.getters.userRole)) {
      next({
        name: store.getters.userRole === roles.admin
          ? adminRoutes.dashboard
          : userRoutes.capsules.index,
      });
    } else {
      setBreadcrumbs(to, next);
    }
  });

  Vue.use(VueRouter);

  return router;
}

export default {
  getRouter: () => router ?? createRouter(),
};
