<template>
  <header
    id="kt_header"
    ref="kt_header"
    class="header header-fixed py-2 py-lg-4"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap align-items-center">
        <div>
          <h1
            style="font-size: 1.15rem; line-height: 1;"
            class="font-weight-bold mb-0"
          >
            <span>
              {{ pageTitle }}
            </span>
          </h1>

          <b-breadcrumb
            v-if="$route.params.breadcrumbs"
            :items="$route.params.breadcrumbs"
          />
        </div>
      </div>

      <KTTopbar />
    </div>
  </header>
</template>

<script>
import KTLayoutHeader from '@assets/js/layout/base/header';
import KTLayoutHeaderMenu from '@assets/js/layout/base/header-menu';
import { mapGetters } from 'vuex';
import KTTopbar from './components/topbar';

export default {
  name: 'HeaderLayout',
  components: {
    KTTopbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'isAdmin',
    ]),
    pageTitle() {
      return this.$route.meta.title || '';
    },
  },
  async mounted() {
    KTLayoutHeader.init('kt_header', 'kt_header_mobile');
    KTLayoutHeaderMenu.init(
      this.$refs.kt_header_menu,
      this.$refs.kt_header_menu_wrapper,
    );
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";

.header {
  background-color: $color-white;
}
</style>
