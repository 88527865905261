<template>
  <div
    id="kt_header_mobile"
    class="header-mobile align-items-center"
    :class="headerClasses"
  >
    <router-link to="/">
      <span class="h3 text-white">IE</span>
    </router-link>

    <div class="d-flex align-items-center">
      <button
        v-if="asideEnabled"
        id="kt_aside_mobile_toggle"
        class="btn p-0 burger-icon burger-icon-left"
      >
        <span />
      </button>

      <button
        id="kt_header_mobile_topbar_toggle"
        ref="kt_header_mobile_topbar_toggle"
        class="btn btn-hover-text-primary p-0 ml-4"
      >
        <span class="svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            :src="require('@assets/images/icons/User.svg')"
          />
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="babel">
import { mapGetters } from 'vuex';
import KTLayoutHeaderTopbar from '@assets/js/layout/base/header-topbar';

export default {
  computed: {
    ...mapGetters(['layoutConfig', 'getClasses']),
    headerClasses() {
      const classes = this.getClasses('header_mobile');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },
  },
  mounted() {
    KTLayoutHeaderTopbar.init(this.$refs.kt_header_mobile_topbar_toggle);
  },
};

</script>
