<template>
  <div>
    <b-row class="mb-5">
      <b-col>
        <b-card>
          <b-form @submit.prevent="handleFilter">
            <b-row>
              <b-col md="3">
                <b-form-group label="First Name">
                  <b-form-input
                    id="firstName"
                    v-model="filterModel.firstName"
                    type="text"
                    placeholder="First Name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Last Name">
                  <b-form-input
                    id="lastName"
                    v-model="filterModel.lastName"
                    type="text"
                    placeholder="Last Name"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Email">
                  <b-form-input
                    id="email"
                    v-model="filterModel.email"
                    type="text"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group label="Capsule Purchased">
                  <b-form-select
                    id="capsulePurchased"
                    v-model="filterModel.capsuleIds"
                    :options="capsuleSelectOptions"
                    placeholder="Capsule Purchased"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="[]"
                        disabled
                      >
                        ANY
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <div class="d-flex flex-wrap border-top pt-5">
                  <b-btn
                    type="submit"
                    variant="primary"
                    class="mr-2"
                  >
                    Search
                  </b-btn>

                  <b-btn @click="clear">
                    Clear
                  </b-btn>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-table-auto
            show-empty
            responsive
            hover
            outlined
            caption-top
            clickable
            show-total-items
            :paginate="true"
            :fields="fields"
            :load-data-callback="loadDataCallback"
            :filter-model.sync="filterModel"
            :filter-settings="filterSettings"
            @row-clicked="openDetails"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { TableEventBus } from '@utils/table';
import { usersApi, capsulesApi } from '@services/api/admin';

function getDefaultFilterModel() {
  return {
    firstName: '',
    lastName: '',
    email: '',
    capsuleIds: [],
  };
}

const FilterSettings = {
  placeholder: 'Search',
  searchBtnText: 'Search',
  isClearBtn: true,
  clearBtnText: 'Clear',
  key: 'filter',
  isComplex: true,
};

export default {
  name: 'UsersPage',
  data() {
    return {
      filterModel: getDefaultFilterModel(),
      fields: [
        {
          key: 'firstName',
          label: 'First Name',
          sortable: true,
          order: 0,
        },
        {
          key: 'lastName',
          label: 'Last Name',
          sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
        },
      ],
      capsuleSelectOptions: [],
    };
  },
  computed: {
    filterSettings() {
      return FilterSettings;
    },
  },
  async mounted() {
    const { data } = await capsulesApi.getPurchased();
    this.capsuleSelectOptions = data.data.map((c) => ({
      value: c.id,
      text: c.name,
    }));
  },
  methods: {
    handleFilter() {
      TableEventBus.$emit('updateFilterModel', this.filterModel);
    },
    clear() {
      this.filterModel = getDefaultFilterModel();
      this.handleFilter();
    },
    loadDataCallback(payload) {
      return usersApi.get(payload);
    },
    async openDetails(item) {
      await this.$router.push({
        name: this.$constants.routes.admin.userEdit,
        params: { id: item.id },
      });
    },
  },
};
</script>
