export default (
  value,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  showZerosAfterDecimalPoint = true,
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: Number.isInteger(value) ? minimumFractionDigits : 2,
    maximumFractionDigits,
  });
  return showZerosAfterDecimalPoint
    ? formatter.format(value)
    : formatter.format(value).replace(/\D00(?=\D*$)/, '');
};
