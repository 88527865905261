import { capsulesApi } from '@services/api/admin';

export default {
  capsules: {
    builders: [
      () => ({
        text: 'Capsules',
        to: { name: 'admin.capsules' },
      }),
    ],
    build() {
      return [
        {
          text: 'Capsules',
          to: { name: 'admin.capsules' },
        },
      ];
    },
  },
  capsuleEdit: {
    builders: [
      () => ({
        text: 'Capsules',
        to: { name: 'admin.capsules' },
      }),
      async ({ id: capsuleId }) => {
        const { data: { data: capsule } } = await capsulesApi.getById(capsuleId);

        return {
          text: capsule.name,
          to: {
            name: 'admin.capsule-edit',
            params: {
              id: capsuleId,
            },
          },
        };
      },
    ],
    build(capsuleId, capsuleName) {
      return [
        {
          text: 'Capsules',
          to: { name: 'admin.capsules' },
        },
        {
          text: capsuleName,
          to: {
            name: 'admin.capsule-edit',
            params: {
              id: capsuleId,
            },
          },
        },
      ];
    },
  },
  capsuleCreate: {
    builders: [
      () => ({
        text: 'Capsules',
        to: { name: 'admin.capsules' },
      }),
      () => ({
        text: 'Capsule Create',
        to: { name: 'admin.capsule-create' },
      }),
    ],
    build() {
      return [
        {
          text: 'Capsules',
          to: { name: 'admin.capsules' },
        },
        {
          text: 'Capsule Create',
          to: { name: 'admin.capsule-create' },
        },
      ];
    },
  },
};
