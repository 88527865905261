import { layouts } from '@constants';
import component from './home.page';

export default [
  {
    path: '/',
    name: 'landing.index',
    component,
    meta: {
      auth: false,
      layout: layouts.landing,
    },
  },
];
