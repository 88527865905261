import { layouts } from '@constants/index';
import UserLayout from './user';
import UserBlankLayout from './user-blank';
import AdminLayout from './admin';
import AdminBlankLayout from './admin-blank';
import LandingLayout from './landing';
import BlankLayout from './blank';

const dictionary = {
  [layouts.default]: LandingLayout,
  [layouts.user]: UserLayout,
  [layouts.userBlank]: UserBlankLayout,
  [layouts.admin]: AdminLayout,
  [layouts.adminBlank]: AdminBlankLayout,
  [layouts.landing]: LandingLayout,
  [layouts.blank]: BlankLayout,
};

export default {
  resolve(key = layouts.default) {
    return dictionary[key];
  },
};
