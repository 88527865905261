import { gridConfig } from '@config';

export default class Pager {
  constructor(callback, settings) {
    this.settings = {};
    Object.assign(this.settings, settings || gridConfig.paging);
    this.callback = callback;
    this.preventCallback = false;
  }

  init() {
    this.callback(this.buildPayload(gridConfig.paging.page));
  }

  update(pagingHeader) {
    this.settings.totalItems = pagingHeader.totalItems;
    this.settings.pageSize = pagingHeader.pageSize;
    if (this.preventCallback) {
      this.settings.page = pagingHeader.page;
    }
  }

  get events() {
    const self = this;
    return {
      pageChanged(page) {
        if (!self.preventCallback) {
          self.callback(self.buildPayload(page));
        }
        self.preventCallback = false;
      },
    };
  }

  buildPayload(currentPage) {
    return {
      Page: currentPage,
      PageSize: this.settings.pageSize,
    };
  }
}
