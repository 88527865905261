const password = {
  name: 'password',
  schema: {
    validate: (value) => {
      // eslint-disable-next-line prefer-regex-literals
      const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})');
      return passwordRegex.test(value);
    },
  },
};

export default password;
