import { usersApi } from '@/app/services/api/admin';

export default {
  users: {
    builders: [
      () => ({
        text: 'Users',
        to: { name: 'admin.users' },
      }),
    ],
    build() {
      return [
        {
          text: 'Users',
          to: { name: 'admin.users' },
        },
      ];
    },
  },
  userEdit: {
    builders: [
      () => ({
        text: 'Users',
        to: { name: 'admin.users' },
      }),
      async ({ id: userId }) => {
        const { data: { data: user } } = await usersApi.getById(userId);

        return {
          text: user.fullName,
          to: {
            name: 'admin.userEdit',
            params: {
              id: userId,
            },
          },
        };
      },
    ],
    build(userId, capsuleName) {
      return [
        {
          text: 'Users',
          to: { name: 'admin.users' },
        },
        {
          text: capsuleName,
          to: {
            name: 'admin.userEdit',
            params: {
              id: userId,
            },
          },
        },
      ];
    },
  },
};
