export default {
  draft: {
    key: 'Draft',
    name: 'Draft',
  },
  inFormation: {
    key: 'InFormation',
    name: 'In Formation',
  },
  formed: {
    key: 'Formed',
    name: 'Formed. Recording in progress',
  },
  preparingForShipment: {
    key: 'PreparingForShipment',
    name: 'Preparing For Shipment',
  },
  onItsWayToDestination: {
    key: 'OnItsWayToDestination',
    name: 'On Its Way To Destination',
  },
  delivered: {
    key: 'Delivered',
    name: 'Delivered',
  },
};
