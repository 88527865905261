<template>
  <div class="review-card">
    <div class="review-card__header">
      <div class="review-card__info">
        <h3 class="review-card__name">
          {{ testimonial.name }}
        </h3>

        <div class="review-card__rating">
          <vue-star-rating
            :rating="testimonial.rating"
            :read-only="true"
            :star-size="12"
            :border-width="2"
            :border-color="'#fff'"
            :active-color="'#fff'"
            inactive-color=""
            :show-rating="false"
            :padding="4"
          />
        </div>
      </div>
    </div>

    <div class="review-card__body">
      <p>
        {{ testimonial.text }}
      </p>
    </div>
  </div>
</template>

<script>
import VueStarRating from 'vue-star-rating';

export default {
  name: 'ReviewCard',
  components: {
    VueStarRating,
  },
  props: {
    testimonial: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.review-card {
  border-radius: 2rem;
  padding: 2.5rem;
  background-color: rgba(#0a111a, 0.8);

  &__header {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__img {
    position: relative;
    flex: 0 0 6.25rem;
    border-radius: 1rem;
    width: 6.25rem;
    height: 6.25rem;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__date {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: #aeaeae;
  }

  &__name {
    margin-bottom: 0.25rem;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.1;
  }

  &__body {
    font-size: 1rem;
    font-weight: 400;

    p {
      margin-bottom: 0;
    }
  }
}
</style>
