<template>
  <b-modal
    v-model="isShow"
    title="Notification Settings"
    hide-footer
    centered
    no-close-on-backdrop
    modal-class="_secondary"
  >
    <div
      v-loader="loadDataPromise"
    >
      <b-alert
        variant="danger"
        :show="!!messages.errorMessage"
      >
        {{ messages.errorMessage }}
      </b-alert>

      <b-alert
        variant="light-primary"
        :show="!!messages.infoMessage"
      >
        {{ messages.infoMessage }}
      </b-alert>

      <validation-observer
        ref="observer-02"
        tag="div"
      >
        <b-form @submit.prevent="updateNotificationSettings">
          <div class="mb-7">
            <div
              v-for="notification in notifications"
              :key="notification.id"
              class="mb-2"
            >
              <b-form-checkbox
                :id="`capsule-status-updates-${notification.id}`"
                v-model="notification.enabled"
                :name="`capsule-status-updates-${notification.id}`"
              >
                {{ notification.displayName }}
              </b-form-checkbox>
            </div>
          </div>

          <b-row class="flex-wrap-reverse align-items-center">
            <b-col
              md="6"
              class="text-center text-md-left mb-3"
            >
              <button
                type="reset"
                class="link"
                @click="hide"
              >
                Cancel
              </button>
            </b-col>

            <b-col
              md="6"
              class="mb-3"
            >
              <button
                type="submit"
                class="landing-btn w-100"
              >
                Update
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import store from '@store/index';
import { UPDATE_PROFILE_INFO } from '@store/profile.module';
import { messages } from '@app/constants';
import { notifications as notificationsApi } from '@services/api/user';
import { mapGetters } from 'vuex';

export default {
  name: 'NotificationSettingsModal',
  data() {
    return {
      loadDataPromise: null,
      isShow: false,
      notifications: [],
      messages: {
        errorMessage: '',
        infoMessage: '',
      },
    };
  },
  computed: {
    ...mapGetters(['profileInfo']),
  },
  methods: {
    show() {
      this.clearMessages();
      this.isShow = true;
      this.loadNotifications(this.profileInfo.userNotifications);
    },
    hide() {
      this.isShow = false;
    },
    async loadNotifications(userNotifications) {
      this.loadDataPromise = notificationsApi.get();
      const { data: { data } } = await this.loadDataPromise;
      this.notifications = data.map((n) => ({
        id: n.id,
        userId: this.profileInfo.id,
        displayName: n.displayName,
        enabled: userNotifications.some((un) => un.notificationId === n.id),
      }));
    },
    async updateNotificationSettings() {
      try {
        this.clearMessages();
        this.loadDataPromise = store.dispatch(UPDATE_PROFILE_INFO, {
          userNotifications: this.notifications
            .filter((n) => n.enabled)
            .map((n) => ({
              userId: n.userId,
              notificationId: n.id,
            })),
        });

        const updatedProfile = await this.loadDataPromise;
        await this.loadNotifications(updatedProfile.userNotifications);
        this.messages.infoMessage = 'Notification settings was updated';
        this.hide();
      } catch (err) {
        if (err.response?.data?.errorMessages) {
          this.messages.errorMessage = err.response?.data?.errorMessages.join('. ');
        } else {
          this.messages.errorMessage = messages.unexpectedError;
        }
      }
    },
    onClose() {
      this.clearMessages();
    },
    clearMessages() {
      this.messages.errorMessage = '';
      this.messages.infoMessage = '';
    },
  },
};
</script>
