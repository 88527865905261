<template>
  <div class="spinner-wrapper">
    <b-spinner variant="primary" />
  </div>
</template>

<script lang="babel">
export default {
  name: 'SpinnerComponent',
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_functions.scss";
@import "@assets/sass/base/_mixins.scss";

.spinner-wrapper {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($color-white, 0.75);

  .user-layout &,
  .modal._secondary & {
    background-color: rgba($color-black, 0.75);
  }

  .text-primary {
    color: $color-primary;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.action-icon {
  i {
    opacity: 0;
  }

  .spinner-wrapper {
    background-color: rgba($color-black, 0);
  }
}

.table-auto,
.table-extend {
  .spinner-wrapper {
    padding-top: 28px;
  }

  tr td .spinner-wrapper {
    padding-top: 0;
  }
}
</style>
