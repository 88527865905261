<template>
  <div class="d-flex flex-column flex-root">
    <KTHeaderMobile />
    <Loader v-if="loaderEnabled" />
    <div class="d-flex flex-row flex-column-fluid page">
      <KTAside v-if="asideEnabled" />
      <div
        id="kt_wrapper"
        class="d-flex flex-column flex-row-fluid wrapper"
      >
        <KTHeader />
        <div
          id="kt_content"
          class="content d-flex flex-column flex-column-fluid position-relative"
        >
          <div class="d-flex flex-column-fluid">
            <div :class="{'container-fluid': contentFluid, container: !contentFluid}">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTScrollToTop />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@services/store/htmlclass.module';
import HtmlClass from '@services/htmlclass.service';
import Loader from '@components/loader';
import KTScrollToTop from '@components/scroll-to-top';
import KTAside from './components/aside';
import KTHeader from './components/header';
import KTHeaderMobile from './components/header-mobile';

export default {
  name: 'DefaultLayout',
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    Loader,
    KTScrollToTop,
  },
  computed: {
    ...mapGetters([
      'layoutConfig',
    ]),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 1000);
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

body {
  &.header-fixed.subheader-fixed.subheader-enabled {
    .wrapper {
      padding-top: 65px;

      @include respond-below(md) {
        padding-top: 55px;
      }

      .content {
        @include respond-below(md) {
          padding-top: 15px;
        }
      }
    }
  }
}
</style>
