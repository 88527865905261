import Axios from 'axios';

const baseControllerRoute = 'admin/users';

function get(payload) {
  return Axios.get(`${baseControllerRoute}`, { params: payload });
}

function getById(id) {
  return Axios.get(`${baseControllerRoute}/${id}`);
}

function getCapsules(id) {
  return Axios.get(`${baseControllerRoute}/${id}/capsules`);
}

function getPurchases(userId, capsuleId, payload) {
  return Axios.get(
    `${baseControllerRoute}/${userId}/capsules/${capsuleId}/purchases`,
    { params: payload },
  );
}

function getCloudCapsules(id) {
  return Axios.get(`${baseControllerRoute}/${id}/cloud-capsules`);
}

function update(payload) {
  return Axios.patch(`${baseControllerRoute}`, payload);
}

export default {
  get,
  getById,
  update,
  getCapsules,
  getPurchases,
  getCloudCapsules,
};
