import {
  routes,
  roles,
  layouts,
} from '@app/constants';
import index from './users.page.admin';
import edit from './user-edit';
import breadcrumbs from './users.routes.breadcrumbs.admin';

export default [
  {
    path: '/admin/users',
    name: routes.admin.users,
    component: index,
    meta: {
      auth: true,
      title: 'Users',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.users.builders,
    },
  },
  {
    path: '/admin/users/:id',
    name: routes.admin.userEdit,
    component: edit,
    meta: {
      auth: true,
      title: 'User Edit',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.userEdit.builders,
    },
  },
];
