<template>
  <b-modal
    v-model="isShow"
    title="Update Password"
    hide-footer
    centered
    no-close-on-backdrop
    modal-class="_secondary"
    @close="onClose"
  >
    <div
      v-loader="loadDataPromise"
    >
      <b-alert
        variant="danger"
        :show="!!messages.errorMessage"
      >
        {{ messages.errorMessage }}
      </b-alert>

      <b-alert
        variant="light-primary"
        :show="!!messages.infoMessage"
      >
        {{ messages.infoMessage }}
      </b-alert>

      <validation-observer
        id="validation-change-password"
        ref="observer"
        tag="div"
      >
        <b-form
          @submit.prevent="updatePassword"
        >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Current Password"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="currentPassword"
              v-model="updatePasswordModel.currentPassword"
              type="password"
              placeholder="Current Password"
            />

            <span class="error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            rules="required|password"
            name="New Password"
            vid="password"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="newPassword"
              v-model="updatePasswordModel.password"
              type="password"
              placeholder="New Password"
            />

            <span class="error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            rules="required|confirmed:password"
            name="Confirm New Password"
            tag="div"
            mode="eager"
            class="position-relative form-group mb-6"
          >
            <b-form-input
              id="confirmNewPassword"
              v-model="updatePasswordModel.confirmPassword"
              type="password"
              placeholder="Confirm New Password"
            />

            <span class="error">
              {{ errors[0] }}
            </span>
          </validation-provider>

          <b-row class="flex-wrap-reverse align-items-center">
            <b-col
              md="6"
              class="text-center text-md-left mb-3"
            >
              <button
                type="reset"
                class="link"
                @click="hide"
              >
                Cancel
              </button>
            </b-col>

            <b-col
              md="6"
              class="mb-3"
            >
              <button
                type="submit"
                class="landing-btn w-100"
              >
                Update
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import store from '@store/index';
import { UPDATE_PROFILE_INFO } from '@store/profile.module';
import { messages } from '@app/constants';

function getDefaultModel() {
  return {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  };
}

export default {
  name: 'PasswordSettingsModal',
  data() {
    return {
      isShow: false,
      loadDataPromise: null,
      updatePasswordModel: getDefaultModel(),
      messages: {
        errorMessage: '',
        infoMessage: '',
      },
    };
  },
  methods: {
    show() {
      this.updatePasswordModel = getDefaultModel();
      this.isShow = true;
    },
    hide() {
      this.clearMessages();
      this.isShow = false;
    },
    async updatePassword() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      try {
        this.clearMessages();
        this.loadDataPromise = store.dispatch(UPDATE_PROFILE_INFO, this.updatePasswordModel);
        await this.loadDataPromise;
        this.messages.infoMessage = 'Password was changed';
        this.updatePasswordModel = getDefaultModel();
        this.$refs.observer.reset();
        this.hide();
      } catch (err) {
        if (err.response?.data?.errorMessages) {
          this.messages.errorMessage = err.response?.data?.errorMessages.join('. ');
        } else {
          this.messages.errorMessage = messages.unexpectedError;
        }
      }
    },
    onClose() {
      this.clearMessages();
      this.updatePasswordModel = getDefaultModel();
      this.$refs.observer.reset();
    },
    clearMessages() {
      this.messages.errorMessage = '';
      this.messages.infoMessage = '';
    },
  },
};
</script>
