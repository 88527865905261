var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"start-capsule-card"},[_c('h3',{staticClass:"start-capsule-card__title"},[_vm._v(" "+_vm._s(_vm.capsule.title)+" ")]),_c('div',{staticClass:"start-capsule-card__img"},[_c('img',{attrs:{"src":_vm.capsule.img,"alt":_vm.capsule.title}})]),(_vm.capsule.type === 'time')?_c('ul',{staticClass:"start-capsule-card__list"},[_vm._m(0),_vm._m(1),_vm._m(2)]):_c('ul',{staticClass:"start-capsule-card__list"},[_vm._m(3),_vm._m(4),_vm._m(5)]),_c('button',{staticClass:"start-capsule-card__btn landing-btn",on:{"click":_vm.createCapsule}},[_vm._v(" "+_vm._s(_vm.capsule.detailsText)+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',{staticClass:"text-primary"},[_vm._v("1MB -")]),_vm._v(" $10 ")]),_c('div',{staticClass:"text-primary"},[_vm._v(" min 10MB ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"text-primary"},[_vm._v(" > 100MB ")]),_c('div',[_vm._v(" -10% ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"text-primary"},[_vm._v(" > 500MB ")]),_c('div',[_vm._v(" -20% ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',{staticClass:"text-primary"},[_vm._v("1MB -")]),_vm._v(" $1 ")]),_c('div',{staticClass:"text-primary"},[_vm._v(" min 50MB ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_vm._v(" +$0.07/day ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"text-primary"},[_vm._v(" > 500MB ")]),_c('div',[_vm._v(" -20% ")])])
}]

export { render, staticRenderFns }