import { admin } from '@constants/route-names';
import { layouts, roles } from '@constants';
import component from './signin.page';

export default [
  {
    path: '/admin/signin',
    name: admin.signin,
    component,
    meta: {
      layout: layouts.adminBlank,
      portal: roles.admin,
    },
  },
];
