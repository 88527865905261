const languages = {
  en: {
    text: 'English',
    value: 'en',
  },
  fr: {
    text: 'French',
    value: 'fr',
  },
};

export default languages;
