import isArray from 'lodash/isArray';
import Vue from 'vue';
import { Spinner } from '@components';

function removeLoader(el) {
  const spinner = el.querySelectorAll(':scope > .spinner-wrapper');
  spinner[0]?.remove();
  el.classList.remove('action-icon', 'position-relative', 'pointer-events-none');
}

function insertLoader(el, arg) {
  el.classList.add('position-relative', 'pointer-events-none');
  // eslint-disable-next-line no-unused-expressions,eqeqeq
  arg && arg == 'actionIcon' && el.classList.add('action-icon');
  const ComponentCtor = Vue.extend(Spinner);
  const componentInstance = new ComponentCtor().$mount();
  el.appendChild(componentInstance.$el);
}

function loader(el, binding) {
  // eslint-disable-next-line no-prototype-builtins
  if (!binding.hasOwnProperty('value')) {
    throw new TypeError('Please provide the Promise or array Promises as "v-loader" directive value.');
  }

  if (isArray(binding.value)) {
    // eslint-disable-next-line no-shadow
    const isPromises = binding.value.every((el) => el instanceof Promise);
    if (isPromises) {
      insertLoader(el, binding.arg);
      Promise.all(binding.value).finally(() => removeLoader(el));
    }
  } else if (binding.value instanceof Promise) {
    insertLoader(el, binding.arg);
    binding.value.finally(() => removeLoader(el));
  }
}

export default loader;
