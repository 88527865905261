import { gridConfig } from '@config';
import sortingDirections from '@constants/sorting-directions';
import Filter from './filter';
import Pager from './pager';
import ComplexDataBuilder from '../complex-data-builder';

export default class TableProcessor {
  constructor(callback, filterSettings, pagerSettings, payloadAsQueryParams = true) {
    this.callback = callback;
    this.payloadAsQueryParams = payloadAsQueryParams;

    this.filter = new Filter(((payload) => {
      this.beforeFilter(payload);
    }), filterSettings);

    this.pager = new Pager(((payload) => {
      this.beforePager(payload);
    }), pagerSettings);

    this.sortingSettings = {};
  }

  beforeFilter(payload) {
    this.pager.preventCallback = true; // this preventing additional pager callback
    this.callback(Object.assign(
      payload,
      this.getPagingParams(gridConfig.paging.page),
      this.getSortingParams(),
    ));
  }

  beforePager(payload) {
    this.callback(this.buildPayload(payload));
  }

  init() {
    this.callback(this.buildPayload());
  }

  update(filteringHeader, pagingHeader) {
    this.filter.update(filteringHeader);
    this.pager.update(pagingHeader);
  }

  updatePagingHeader(pagingHeader) {
    this.pager.update(pagingHeader);
  }

  updateSortingSettings(sortingContext) {
    if (!sortingContext) {
      return;
    }

    this.sortingSettings = {
      sortDirection: sortingContext.sortDesc
        ? sortingDirections.descending
        : sortingDirections.ascending,
      sortBy: sortingContext.sortBy,
    };
  }

  getPagingParams(pagingParams) {
    const pagingPayload = pagingParams || this.pager.buildPayload(gridConfig.paging.page);
    return this.payloadAsQueryParams ? ComplexDataBuilder.build('tablingParams.pagingParams', pagingPayload) : pagingPayload;
  }

  getSortingParams() {
    return this.payloadAsQueryParams
      ? ComplexDataBuilder.build('tablingParams.sortingParams', this.sortingSettings)
      : this.sortingSettings;
  }

  buildPayload(pagingParams) {
    if (this.payloadAsQueryParams) {
      return Object.assign(
        this.filter.buildPayload(this.filter[this.filter.settings.key]),
        this.getPagingParams(pagingParams),
        this.getSortingParams(),
      );
    }

    return {
      tablingParams: {
        pagingParams: this.getPagingParams(pagingParams),
        sortingParams: this.getSortingParams(),
      },
      filteringParams: { filter: this.filter.filter },
    };
  }
}
