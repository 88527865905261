export default {
  validation: {
    messages: {
      password: 'Le mot de passe doit contenir au moins : 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et un caractère spécial',
      email: 'Le champ {_field_} doit être un email valide',
      max: 'Le champ {_field_} ne doit pas dépasser {length} caractères',
      min: 'Le champ {_field_} doit contenir au moins {length} caractères',
      numeric: 'Le champ {_field_} ne peut contenir que des caractères numériques',
      required: 'Le champ {_field_} est obligatoire',
    },
    fields: {
      answerCheckbox: {
        required: 'S\'il vous plaît, choisissez votre réponse',
      },
      answerSelect: {
        required: 'S\'il vous plaît, choisissez votre réponse',
      },
      answerShortText: {
        required: 'S\'il vous plaît, tapez votre réponse',
        min: 'La réponse doit comporter au moins {length} caractères',
      },
      commuteDistance: {
        required: 'S\'il vous plaît, tapez votre réponse',
      },
      locationZipCode: {
        required: 'S\'il vous plaît saisir votre code postal',
      },
    },
  },
};
