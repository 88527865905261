<template>
  <section class="d-flex flex-column py-10">
    <div class="container">
      <h1 class="font-weight-bold">
        Error
      </h1>

      <p class="h3">
        {{ message }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage',
  data() {
    return {
      message: null,
    };
  },
  watch: {
    '$route.params.message': {
      handler(val) {
        this.message = val;
      },
      immediate: true,
    },
  },
};
</script>
