import JwtDecode from 'jwt-decode';
import { localStorageKeys, roles } from '@constants';
import { isEmpty } from 'lodash';
import { admin as adminApi, user as userApi } from '../api';

// action types
export const SIGNOUT = 'signout';
export const SIGNIN_ADMIN = 'signinAdmin';
export const SIGNIN_USER = 'signUser';

export const SIGNUP_USER = 'signupUser';
export const SIGNIN_USER_WITH_GOOGLE = 'signUserWithGoogle';
export const REAUTHENTICATE = 'reauthenticate';
export const SET_TOKENS = 'setTokens';
export const UPDATE_PROFILE_INFO = 'renewProfileInfo';
export const REFRESH_PROFILE_INFO = 'refreshProfileInfo';
export const SET_HAS_CLOUD_CAPSULE = 'setHasCloudCapsules';

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo';
export const SET_PROFILE_INFO = 'setProfileInfo';
export const RESET_PERSONAL_INFO = 'resetPersonalInfo';

function areTokensHasAlreadyRefreshed(currentTokens, storedTokens) {
  return storedTokens.accessToken !== currentTokens.accessToken
    && storedTokens.refreshToken !== currentTokens.refreshToken;
}

function updateStoreTokensIfAlreadyRefreshed(tokens, storedTokens, commit) {
  if (!areTokensHasAlreadyRefreshed(tokens, storedTokens)) {
    return false;
  }

  commit(SET_PERSONAL_INFO, storedTokens);
  return true;
}

const initState = {
  userPersonalInfo: {},
  userToken: null,
  userRefreshToken: null,
  profileInfo: {},
};
/* eslint-disable no-param-reassign */
const gettersDeclaration = {
  userToken: (data) => data.userToken,
  userRefreshToken: (data) => data.userRefreshToken,
  userPersonalInfo: (data) => data.userPersonalInfo,
  isAuthorized: (data) => data.userToken,
  userRole: (data) => data.userPersonalInfo.role,
  userEmail: (data) => data.userPersonalInfo.email,
  isExpiredToken: (data) => () => {
    if (!data.userPersonalInfo?.exp) {
      return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return data.userPersonalInfo?.exp < currentTime;
  },
  isAdmin: (data) => data.userPersonalInfo.role === roles.admin,
  isUser: (data) => data.userPersonalInfo.role === roles.user,
  profileInfo: (data) => data.profileInfo,
};

const actions = {
  async [SIGNIN_USER]({ commit }, payload) {
    const { data: { data: { accessToken, refreshToken } } } = await userApi.auth.signin(payload);
    window.localStorage.setItem(localStorageKeys.userToken, accessToken);
    window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
    commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
    const { data: { data: profileInfo } } = await userApi.auth.getProfileInfo();
    commit(SET_PROFILE_INFO, { profileInfo });
  },
  async [SIGNUP_USER]({ commit }, payload) {
    const { data: { data: { accessToken, refreshToken } } } = await userApi.auth.signup(payload);
    window.localStorage.setItem(localStorageKeys.userToken, accessToken);
    window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
    commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
    const { data: { data: profileInfo } } = await userApi.auth.getProfileInfo();
    commit(SET_PROFILE_INFO, { profileInfo });
  },
  async [SIGNIN_USER_WITH_GOOGLE]({ commit }, payload) {
    const { data: { data: { accessToken, refreshToken } } } = await userApi.auth
      .signinWithGoogle(payload);
    window.localStorage.setItem(localStorageKeys.userToken, accessToken);
    window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
    commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
    const { data: { data: profileInfo } } = await userApi.auth.getProfileInfo();
    commit(SET_PROFILE_INFO, { profileInfo });
  },
  async [SIGNIN_ADMIN]({ commit }, payload) {
    const { data: { data: { accessToken, refreshToken } } } = await adminApi.auth.signin(payload);
    window.localStorage.setItem(localStorageKeys.userToken, accessToken);
    window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
    commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
    const { data: { data: profileInfo } } = await adminApi.auth.getProfileInfo();
    commit(SET_PROFILE_INFO, { profileInfo });
  },
  async [REAUTHENTICATE]({ commit, state, getters }, payload) {
    const storedTokens = {
      accessToken: window.localStorage.getItem(localStorageKeys.userToken),
      refreshToken: window.localStorage.getItem(localStorageKeys.userRefreshToken),
    };

    if (!storedTokens.refreshToken || !storedTokens.refreshToken) {
      return false;
    }

    if (updateStoreTokensIfAlreadyRefreshed(payload, storedTokens, commit)) {
      return true;
    }

    try {
      const api = getters.userRole === roles.admin
        ? adminApi.auth
        : userApi.auth;
      const { data: { data: { accessToken, refreshToken, isAuthSuccess } } } = await api
        .reauthenticate(payload);
      if (!isAuthSuccess) {
        return false;
      }
      window.localStorage.setItem(localStorageKeys.userToken, accessToken);
      window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
      commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
      if (isEmpty(state.profileInfo)) {
        const { data: { data: profileInfo } } = await api.getProfileInfo();
        commit(SET_PROFILE_INFO, { profileInfo });
      }
      return true;
    } catch (_) {
      return false;
    }
  },
  [SIGNOUT]({ commit }) {
    window.localStorage.removeItem(localStorageKeys.userToken);
    window.localStorage.removeItem(localStorageKeys.userRefreshToken);
    commit(RESET_PERSONAL_INFO);
  },
  async [SET_TOKENS]({ commit, getters }, { accessToken, refreshToken }) {
    window.localStorage.setItem(localStorageKeys.userToken, accessToken);
    window.localStorage.setItem(localStorageKeys.userRefreshToken, refreshToken);
    commit(SET_PERSONAL_INFO, { accessToken, refreshToken });
    const api = getters.userRole === roles.admin
      ? adminApi.auth
      : userApi.auth;
    try {
      const { data: { data: profileInfo } } = await api.getProfileInfo();
      commit(SET_PROFILE_INFO, { profileInfo });
      // eslint-disable-next-line no-empty
    } catch (_) {
    }
  },
  async [UPDATE_PROFILE_INFO]({ commit, getters }, payload) {
    const api = getters.userRole === roles.admin
      ? adminApi.auth
      : userApi.auth;
    const { data: { data: profileInfo } } = await api.updateProfileInfo(payload);
    commit(SET_PROFILE_INFO, { profileInfo });
    return profileInfo;
  },
  async [REFRESH_PROFILE_INFO]({ commit, getters }) {
    const api = getters.userRole === roles.admin
      ? adminApi.auth
      : userApi.auth;
    const { data: { data: profileInfo } } = await api.getProfileInfo();
    commit(SET_PROFILE_INFO, { profileInfo });
  },
  [SET_HAS_CLOUD_CAPSULE]({ commit }) {
    commit(SET_HAS_CLOUD_CAPSULE);
  },
};

const mutations = {
  [SET_PERSONAL_INFO](data, { accessToken, refreshToken }) {
    const userPersonalInfo = JwtDecode(accessToken);
    data.userToken = accessToken;
    data.userRefreshToken = refreshToken;
    data.userPersonalInfo = userPersonalInfo;
  },
  [RESET_PERSONAL_INFO](data) {
    data.userPersonalInfo = {};
    data.userToken = null;
    data.userRefreshToken = null;
    data.profileInfo = {};
  },
  [SET_PROFILE_INFO](data, { profileInfo }) {
    data.profileInfo = profileInfo;
  },
  [SET_HAS_CLOUD_CAPSULE](data) {
    if (data.profileInfo) {
      data.profileInfo.hasCloudCapsules = true;
    }
  },
};
/* eslint-enable no-param-reassign */
export default {
  state: initState,
  actions,
  mutations,
  getters: gettersDeclaration,
};
