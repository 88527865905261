import Axios from 'axios';

const baseControllerRoute = 'admin/notifications';

function get() {
  return Axios.get(`${baseControllerRoute}`);
}

function getById(id) {
  return Axios.get(`${baseControllerRoute}/${id}`);
}

function update(payload) {
  return Axios.post(`${baseControllerRoute}`, payload);
}

export default {
  get,
  getById,
  update,
};
