<template>
  <div
    id="kt_brand"
    ref="kt_brand"
    class="brand flex-column-auto"
  >
    <div class="brand-logo">
      <router-link :to="{ name: $constants.routes.admin.capsules }">
        <span class="brand-logo-text" />
      </router-link>
    </div>

    <div class="brand-tools">
      <button
        id="kt_aside_toggle"
        ref="kt_aside_toggle"
        class="brand-toggle btn btn-sm px-0"
      >
        <span class="svg-icon svg-icon svg-icon-xl">
          <inline-svg
            class="svg-icon"
            :src="require('@assets/images/icons/Angle-double-left.svg')"
          />
        </span>
      </button>
    </div>
  </div>
</template>

<script lang="babel">
import KTLayoutBrand from '@assets/js/layout/base/brand';
import KTLayoutAsideToggle from '@assets/js/layout/base/aside-toggle';

export default {
  name: 'BrandComponent',
  mounted() {
    KTLayoutBrand.init(this.$refs.kt_brand);
    KTLayoutAsideToggle.init(this.$refs.kt_aside_toggle);
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";

.aside-toggle {
  outline: none;
}

.brand-logo-text {
  &::after {
    content: 'IT-EON';
    font-size: 1.5rem;
    font-weight: 500;
    color: $color-white;

    .aside-minimize & {
      content: 'IE';
    }
  }
}
</style>
