<template>
  <b-modal
    v-model="isShow"
    title="Create Digital Modal"
    size="lg"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    modal-class="_secondary"
    @hidden="onHidden"
  >
    <validation-observer
      ref="observer"
      tag="div"
    >
      <b-form @submit.prevent="createMessage">
        <h2 class="font-weight-boldest text-primary text-center mb-10">
          <span v-if="isAwaitingForSend">Update</span>
          <span v-else-if="isInDraft">Create</span>
          <span v-else>View</span>
          Cloud Capsule
        </h2>

        <b-row class="align-items-center mb-3">
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Message Title"
              tag="div"
            >
              <b-form-input
                id="messageTitle"
                v-model="model.capsule.messageTitle"
                :disabled="!isInDraft && !isAwaitingForSend"
                name="messageTitle"
                placeholder="Enter Message Title"
              />

              <span class="error">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="align-items-center mb-3">
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Receiver's Name"
              tag="div"
            >
              <b-form-input
                id="receiverName"
                v-model="model.capsule.receiverName"
                :disabled="!isInDraft && !isAwaitingForSend"
                name="receiverName"
                placeholder="Receiver's Name"
              />

              <span class="error">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="align-items-center mb-3">
          <b-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              mode="lazy"
              rules="required|emails"
              name="Receiver's Email"
              tag="div"
            >
              <b-form-input
                id="receiverEmail"
                v-model="model.capsule.receiverEmail"
                :disabled="!isInDraft && !isAwaitingForSend"
                name="receiverEmail"
                placeholder="Receiver's Email"
              />

              <span class="error">
                {{ errors[0] }}
              </span>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="mb-10">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Your Message"
            tag="div"
          >
            <b-form-textarea
              id="message"
              v-model="model.capsule.message"
              :disabled="!isInDraft && !isAwaitingForSend"
              name="message"
              rows="3"
              placeholder="Text Your Message"
            />

            <span class="error">
              {{ errors[0] }}
            </span>
          </validation-provider>
        </div>

        <!-- Files Block -->
        <div
          v-loader="filesPromise"
          class="pb-10"
        >
          <div v-if="model.files.length > 0">
            <h4 class="text-primary">
              Files
            </h4>

            <ul
              class="list-style-none m-0 mb-5 overflow-scroll"
              style="max-height: 15rem;"
            >
              <li
                v-for="file in model.files"
                :key="file.id"
                class="p-1"
              >
                <b-row class="justify-content-between">
                  <b-col
                    cols="7"
                    sm="6"
                    class="text-truncate"
                    :title="file.name"
                  >
                    {{ file.name }}
                  </b-col>

                  <b-col
                    cols="3"
                    sm="3"
                    class="text-right"
                  >
                    {{ HumanBytesFormat.humanize(file.size) }}
                  </b-col>

                  <b-col
                    v-if="isInDraft"
                    cols="2"
                    sm="3"
                    class="text-right"
                  >
                    <b-link
                      class="p-2 lh-1"
                      @click="removeFile(file.id)"
                    >
                      <i
                        class="text-danger flaticon2-cross"
                        style="font-size: 0.75rem;"
                      />
                    </b-link>
                  </b-col>
                </b-row>
              </li>
            </ul>
          </div>

          <div v-if="isInDraft">
            <div>
              <p class="disclaimer text-center">
                Before uploading video and images we suggest to compress them in order
                to reduce the size.
                You can use one of the services below: <br>
                <a
                  href="https://tinypng.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tinypng.com
                </a> &nbsp;
                <a
                  href="https://www.media.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Media.io
                </a> &nbsp;
                <a
                  href="https://www.freeconvert.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FreeConvert.com
                </a> &nbsp;
                <a
                  href="https://www.veed.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Veed.io
                </a> &nbsp;

                or any other service you like
              </p>

              <b-form-file
                id="fileInput"
                ref="fileInput"
                v-model="model.addedFiles"
                multiple
                placeholder="Drop Files or Click Here to select"
                drop-placeholder="Drop file here..."
                @change="filesSelected"
              />
            </div>
          </div>
        </div>

        <!-- Calculate Block -->
        <validation-observer
          ref="calculateObserver"
          tag="div"
        >
          <b-row class="justify-content-center mb-10">
            <b-col
              md="3"
              class="mb-3"
            >
              <validation-provider
                v-slot="{ errors }"
                rules="required|future"
                name="Date of Delivery"
                tag="div"
              >
                <label
                  for="dateOfDeliveryInput"
                  class="d-block font-weight-bold text-center"
                >
                  Date of Delivery
                </label>

                <date-picker
                  id="dateOfDeliveryInput"
                  v-model="model.revealDateTimeModel.date"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  :disabled="!isInDraft"
                />

                <span class="error">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </b-col>

            <b-col
              cols="6"
              md="3"
              class="mb-3"
            >
              <label
                for="timeOfDeliveryInput"
                class="d-block font-weight-bold text-center"
              >
                Time of Delivery
              </label>

              <time-picker
                id="timeOfDeliveryInput"
                v-model="model.revealDateTimeModel.time"
                format="hh-mm"
                placeholder="HH-MM"
                drop-direction="up"
                :disabled="!isInDraft"
              />
            </b-col>

            <b-col
              cols="6"
              md="3"
              class="mb-3"
            >
              <label
                for="timeOfDeliveryInput"
                class="d-block font-weight-bold text-center invisible"
              >
                Choose AM / PM
              </label>

              <div class="d-flex justify-content-center justify-content-md-end py-3">
                <b-form-radio-group
                  id="radio-group-1"
                  v-model="model.revealDateTimeModel.timeFormat"
                  :options="['AM', 'PM']"
                  name="radio-options"
                  :disabled="!isInDraft"
                />
              </div>
            </b-col>
          </b-row>
        </validation-observer>

        <!-- Price Block -->
        <b-row
          v-if="isInDraft && priceWasCalculated"
          class="align-items-end justify-content-center mb-10"
        >
          <b-col md="9">
            <div class="mb-1">
              <b-row v-if="model.priceInfoCalculated.spacePrice">
                <b-col cols="4">
                  <div class="text-primary">
                    Memory
                  </div>
                </b-col>

                <b-col cols="4">
                  <div class="text-primary">
                    {{ HumanBytesFormat.humanize(model.priceInfoCalculated.size) }}
                  </div>
                </b-col>

                <b-col
                  cols="4"
                  class="text-right"
                >
                  {{ CurrencyFormat(model.priceInfoCalculated.spacePrice) }}
                </b-col>
              </b-row>

              <b-row v-if="model.priceInfoCalculated.timePrice">
                <b-col cols="4">
                  <div class="text-primary">
                    Duration
                  </div>
                </b-col>

                <b-col cols="4">
                  <div class="text-primary text-nowrap">
                    {{ durationTime }}
                  </div>
                </b-col>

                <b-col
                  cols="4"
                  class="text-right"
                >
                  {{ CurrencyFormat(model.priceInfoCalculated.timePrice) }}
                </b-col>
              </b-row>

              <b-row v-if="model.priceInfoCalculated.spaceDiscountPercentage">
                <b-col cols="6">
                  <div class="text-primary">
                    Space Discount
                  </div>
                </b-col>

                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ shownSpaceDiscountPercentage }}
                </b-col>
              </b-row>

              <b-row v-if="!priceMoreThanMinimal">
                <b-col cols="6">
                  <div class="text-primary">
                    Minimal Price
                  </div>
                </b-col>

                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ CurrencyFormat(minimalPrice) }}
                </b-col>
              </b-row>
            </div>

            <b-row
              v-if="model.priceInfoCalculated.totalPrice"
              class="mb-5"
            >
              <b-col cols="6">
                <div class="h6 text-primary">
                  Total Price
                </div>
              </b-col>

              <b-col
                cols="6"
                class="text-right"
              >
                {{ CurrencyFormat(model.priceInfoCalculated.totalPrice) }}
              </b-col>
            </b-row>

            <!-- Terms Block -->
            <div
              v-if="priceWasCalculated"
              class="mb-0"
            >
              <b-form-checkbox
                id="agreeWithTerms"
                v-model="model.agreeWithTerms"
                name="agreeWithTerms"
              >
                I have read and agree to the
                <router-link
                  :to="{ name: 'terms-and-conditions.index' }"
                  target="_blank"
                >
                  Terms of Use
                </router-link>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <!-- Footer Block -->
        <b-row class="flex-wrap-reverse align-items-center">
          <b-col
            md="6"
            class="text-center text-md-left"
          >
            <button
              class="link py-5"
              type="reset"
              @click="hide"
            >
              {{ isAwaitingForSend || isInDraft ? 'Cancel': 'Close' }}
            </button>
          </b-col>

          <b-col
            v-if="isInDraft"
            md="6"
          >
            <button
              type="submit"
              :disabled="!priceWasCalculated && filesPromise || !model.agreeWithTerms"
              class="landing-btn w-100"
            >
              Create
            </button>
          </b-col>

          <b-col
            v-if="isAwaitingForSend"
            md="6"
          >
            <button
              type="button"
              class="landing-btn w-100"
              @click="update"
            >
              Update
            </button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div
      v-if="model.errorMessage"
      class="error"
    >
      {{ model.errorMessage }}
    </div>
  </b-modal>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isEqual } from 'lodash';

import { saveAs } from 'file-saver';
import { files as filesApi, userCloudCapsules } from '@services/api/user';
import { user as userApi } from '@/app/services/api';
import { DateFormat, CurrencyFormat, HumanBytesFormat } from '@/app/formatters';
import { cloudCapsuleStatuses, messages, routes } from '@/app/constants';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

function getDefaultCapsuleModel() {
  return {
    id: null,
    messageTitle: '',
    receiverName: '',
    receiverEmail: '',
    message: '',
    revealDateTime: '',
    files: [],
    status: '',
  };
}

function getStringDate(dateString) {
  if (!dateString) {
    return {
      date: null,
      time: null,
      timeFormat: null,
    };
  }

  const date = dateString ? dayjs(dateString).tz() : dayjs().tz();

  const extractedDate = date.toDate();
  const [hh, mm] = date.format('hh-mm').split('-');
  const extractedTimeFormat = date.format('A');

  return {
    date: extractedDate,
    time: {
      hh,
      mm,
    },
    timeFormat: extractedTimeFormat,
  };
}

function getDefaultPriceInfo() {
  return {
    size: 0,
    timePrice: 0,
    spacePrice: 0,
    totalPrice: 0,
    spaceDiscountPercentage: 0,
    durationDays: 0,
  };
}

function getDefaultModel() {
  return {
    agreeWithTerms: false,
    files: [],
    addedFiles: [],
    existingCapsuleId: null,
    revealDateTimeModel: getStringDate(),
    priceInfoCalculated: getDefaultPriceInfo(),
    capsule: getDefaultCapsuleModel(),
    errorMessage: '',
  };
}

export default {
  name: 'CloudCapsuleCreateEditModal',
  emits: ['message-created', 'capsule-updated'],
  data() {
    return {
      CurrencyFormat,
      DateFormat,
      HumanBytesFormat,
      isShow: false,
      filesPromise: null,
      calculatePromise: null,
      minimalPrice: 50,
      showDownloadFileLink: false,
      model: getDefaultModel(),
    };
  },
  computed: {
    priceMoreThanMinimal() {
      return (this.model.priceInfoCalculated.spacePrice
        + this.model.priceInfoCalculated.timePrice) >= this.minimalPrice;
    },
    revealDateTime() {
      const isDate = !!this.model.revealDateTimeModel.date
        && !!this.model.revealDateTimeModel.time
        && !!this.model.revealDateTimeModel.time?.hh
        && !!this.model.revealDateTimeModel.time?.mm
        && !!this.model.revealDateTimeModel.timeFormat;

      if (!isDate) return null;

      const convertedDate = dayjs(this.model.revealDateTimeModel.date).format('YYYY-MM-DD');
      const { hh, mm } = this.model.revealDateTimeModel.time;

      return dayjs(`${convertedDate} ${hh}:${mm} ${this.model.revealDateTimeModel.timeFormat}`, 'YYYY-MM-DD HH:mm A')
        .format('YYYY-MM-DDTHH:mm:ssZ');
    },
    durationTime() {
      const days = this.model.priceInfoCalculated.durationDays;

      const years = Math.floor(days / 365);
      const remainingDays = days % 365;

      let durationString = '';

      if (years > 0) {
        durationString += `${years} ${years === 1 ? 'year' : 'years'}`;
      }

      if (remainingDays > 0) {
        durationString += `${years > 0 ? ' and' : ''} ${remainingDays} ${remainingDays === 1 ? 'day' : 'days'}`;
      }

      return durationString.trim();
    },
    shownSpaceDiscountPercentage() {
      return `${this.model.priceInfoCalculated.spaceDiscountPercentage * 100}%`;
    },
    isInDraft() {
      return this.model.capsule.status === cloudCapsuleStatuses.draft.key;
    },
    isAwaitingForSend() {
      return this.model.capsule.status === cloudCapsuleStatuses.awaitingToSend.key;
    },
    priceWasCalculated() {
      return !isEqual(this.model.priceInfoCalculated, getDefaultPriceInfo());
    },
    canCalculatePrice() {
      return this.model.files.length > 0
        && this.model.revealDateTimeModel.date
        && this.model.revealDateTimeModel.time
        && this.model.revealDateTimeModel.timeFormat
        && this.model.capsule.status === cloudCapsuleStatuses.draft.key;
    },
  },
  watch: {
    'model.addedFiles': {
      async handler(value) {
        if (value.length > 0) {
          await this.uploadFiles();
        }
      },
    },
    'model.files': {
      async handler(value) {
        if (value && value.length > 0) {
          await this.calculatePrice();
        }
      },
    },
    'model.capsule.id': {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }

        if (!newValue) {
          if (this.$route.name === routes.user.capsules.cloudPersonalCabinetView) {
            await this.$router.push({
              name: routes.user.capsules.cloudPersonalCabinet,
            }).catch(() => {});
          }
          if (this.$route.name === routes.user.capsules.cloudView) {
            await this.$router.push({
              name: routes.user.capsules.cloud,
            }).catch(() => {});
          }
        } else {
          if (this.$route.name === routes.user.capsules.cloudPersonalCabinet) {
            await this.$router.push({
              name: routes.user.capsules.cloudPersonalCabinetView,
              params: {
                capsuleId: newValue,
              },
            }).catch(() => {});
          }
          if (this.$route.name === routes.user.capsules.cloud) {
            await this.$router.push({
              name: routes.user.capsules.cloudView,
              params: {
                capsuleId: newValue,
              },
            }).catch(() => {});
          }
        }
      },
    },
    'model.revealDateTimeModel': {
      async handler(value) {
        if (value) {
          await this.calculatePrice();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.model.existingCapsuleId = this.$route.params.capsuleId;

    if (this.model.existingCapsuleId) {
      this.show(this.model.existingCapsuleId);
    }
  },
  methods: {
    async show(capsuleId) {
      this.model.existingCapsuleId = capsuleId;
      this.isShow = true;
      await this.getData();
    },
    hide() {
      this.isShow = false;
    },
    async createMessage() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      const createMessageData = {
        ...this.model.capsule,
        price: this.model.priceInfoCalculated.totalPrice,
      };

      await this.update();

      this.$emit('message-created', createMessageData);
    },
    async getData() {
      if (this.model.existingCapsuleId) {
        await this.getExistingCapsule(this.model.existingCapsuleId);
        await this.getFiles(this.model.existingCapsuleId);
        await this.calculatePrice(true);
      } else {
        await this.createCapsule();
      }
    },
    async createCapsule() {
      const newCapsule = {};
      const { data: { data } } = await userApi.userCloudCapsules.create(newCapsule);
      this.model.capsule = data;

      this.model.revealDateTimeModel = getStringDate();
    },
    async getExistingCapsule(capsuleId) {
      const { data: { data } } = await userApi.userCloudCapsules.getById(capsuleId);
      this.model.capsule = data;

      this.model.revealDateTimeModel = getStringDate(data.revealDateTime);
    },
    onHidden() {
      this.model = getDefaultModel();
    },
    async update() {
      const isValid = await this.$refs.calculateObserver.validate();
      if (!isValid) return;

      const updatePayload = {
        receiverName: this.model.capsule.receiverName,
        receiverEmail: this.model.capsule.receiverEmail,
        message: this.model.capsule.message,
        messageTitle: this.model.capsule.messageTitle,
        revealDateTime: this.revealDateTime,
      };

      try {
        const { data: { data: updateData } } = await userApi
          .userCloudCapsules.update(this.model.capsule.id, updatePayload);
        this.model.capsule = updateData;
        this.$emit('capsule-updated');
        this.hide();
      } catch (err) {
        if (err.response?.data?.errorMessages) {
          this.model.errorMessage = err.response?.data?.errorMessages.join('. ');
        } else {
          this.model.errorMessage = messages.unexpectedError;
        }
      }
    },
    async calculatePrice(skipUpdate) {
      if (this.priceWasCalculated && !this.canCalculatePrice) {
        this.model.priceInfoCalculated = getDefaultPriceInfo();
      }

      if (!this.canCalculatePrice) return;

      const isValid = await this.$refs.calculateObserver.validate();
      if (!isValid) {
        this.model.priceInfoCalculated = getDefaultPriceInfo();
        return;
      }

      if (!skipUpdate) {
        const updatePayload = {
          receiverName: this.model.capsule.receiverName,
          receiverEmail: this.model.capsule.receiverEmail,
          revealDateTime: this.revealDateTime,
          message: this.model.capsule.message,
          messageTitle: this.model.capsule.messageTitle,
        };

        try {
          const { data: { data: updateData } } = await userApi
            .userCloudCapsules.update(this.model.capsule.id, updatePayload);

          this.model.capsule = updateData;
        } catch (err) {
          if (err.response?.data?.errorMessages) {
            this.model.errorMessage = err.response?.data?.errorMessages.join('. ');
          } else {
            this.model.errorMessage = messages.unexpectedError;
          }
        }
      }

      this.filesPromise = userApi
        .userCloudCapsules.calculatePrice(this.model.capsule.id);
      const { data: { data: calculatedData } } = await this.filesPromise;

      this.model.priceInfoCalculated.size = calculatedData.size;
      this.model.priceInfoCalculated.timePrice = calculatedData.timePrice;
      this.model.priceInfoCalculated.spacePrice = calculatedData.spacePrice;
      this.model.priceInfoCalculated.spaceDiscountPercentage = calculatedData
        .spaceDiscountPercentage;
      this.model.priceInfoCalculated.totalPrice = calculatedData.totalPrice;
      this.model.priceInfoCalculated.durationDays = calculatedData.durationDays;
    },
    filesSelected() {
      this.model.errorMessage = '';
    },
    async uploadFiles() {
      if (this.model.addedFiles.length === 0) return;

      const formData = new FormData();

      this.model.addedFiles.forEach((file) => formData.append('files', file));
      await (this.filesPromise = userCloudCapsules.postFiles(this.model.capsule.id, formData));

      await this.getFiles(this.model.capsule.id);
      if (this.priceWasCalculated) {
        await this.calculatePrice();
      }

      this.model.addedFiles = [];
      this.model.errorMessage = '';
    },
    async getFiles(capsuleId) {
      this.filesPromise = userCloudCapsules.getFiles(capsuleId);

      try {
        const { data: { data } } = await this.filesPromise;
        this.model.files = data;
      } catch (error) {
        throw new Error('Error fetching files:', error);
      } finally {
        this.filesPromise = null;
      }
    },
    async downloadFile(fileId) {
      this.filesPromise = filesApi.get(fileId);

      try {
        const { data: { data } } = await this.filesPromise;
        saveAs(data);
      } catch (error) {
        throw new Error('Error retrieving file:', error);
      } finally {
        this.filesPromise = null;
      }
    },
    async removeFile(fileId) {
      this.filesPromise = filesApi.remove(fileId);

      try {
        await this.filesPromise;
        await this.getFiles(this.model.capsule.id);
      } catch (error) {
        throw new Error('Error removing file:', error);
      } finally {
        this.filesPromise = null;
      }
    },
  },
};
</script>
