<template>
  <div
    class="scroll-progress"
    :class="{ 'scroll-progress--reversed': reversed }"
  >
    <div class="scroll-progress__track">
      <div
        class="scroll-progress__bar"
        :style="`width: ${progress}`"
      >
        <img
          src="@/assets/images/user-portal/capsule-silver.png"
          alt=""
          class="scroll-progress__icon"
          width="70"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollProgress',
  props: {
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      progress: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (winScroll / height) * 100;
      this.progress = `${scrolled}%`;
    });
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/_variables.scss';
@import '@/assets/sass/base/_mixins.scss';

.scroll-progress {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 3.5rem;
  pointer-events: none;

  &__track {
    position: relative;
    transform: rotate(2deg);
    width: 100%;
    height: 0.5rem;

    @include respond-below(md) {
      height: 0.35rem;
    }
  }

  &__bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
  }

  &__icon {
    position: absolute;
    z-index: 11;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    @include respond-below(md) {
      width: 3rem;
    }
  }

  &--reversed {
    .scroll-progress__track {
      transform: rotate(-2deg);
    }

    .scroll-progress__bar {
      left: auto;
      right: 0;
    }

    .scroll-progress__icon {
      right: auto;
      left: 0;
    }
  }
}
</style>
