var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section section-bg-01 pt-15"},[_c('div',{staticClass:"section__container container"},[_c('div',{staticClass:"section__wrap"},[_c('div',{staticClass:"mb-10"},[_c('h2',{staticClass:"section__title text-center"},[_vm._v(" What is Time Capsule? ")]),_vm._m(0),_vm._m(1),_c('b-row',{staticClass:"justify-content-between align-items-center"},[_c('b-col',{staticClass:"mb-5",attrs:{"md":"6"}},[_c('div',{staticClass:"section__body text-block"},[_c('p',[_vm._v(" The capsules will be sent: ")]),_c('ul',{staticClass:"styled-list"},[_c('li',[_vm._v(" to the Mariana Trench, a deep place in the ocean; ")]),_c('li',[_vm._v(" to Everest, the world's highest mountain, the Himalayas; ")]),_c('li',[_vm._v(" into Space; ")]),_c('li',[_vm._v(" to the Arctic ")])]),_c('p',[_vm._v(" and other hard-to-reach or inaccessible to humans places. ")]),_c('p',[_c('router-link',{staticClass:"landing-btn",attrs:{"to":{
                    name: _vm.routes.user.capsules.time
                  }}},[_vm._v(" Go to Capsules ")])],1)])]),_c('b-col',{staticClass:"mb-5 text-center text-md-left",attrs:{"md":"6"}},[_c('ul',{staticClass:"list-style-none d-inline-flex align-items-center pc-only"},[_c('li',{staticStyle:{"flex":"0 0 25%","max-width":"25%"}},[_c('img',{attrs:{"src":require("@/assets/images/user-portal/capsule-bronze.png"),"alt":""}})]),_c('li',{staticClass:"px-5",staticStyle:{"flex":"0 0 50%","max-width":"50%"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/user-portal/capsule-silver.png"),"alt":""}})]),_c('li',{staticStyle:{"flex":"0 0 25%","max-width":"25%"}},[_c('img',{attrs:{"src":require("@/assets/images/user-portal/capsule-gold.png"),"alt":""}})])])])],1)],1),_c('div',[_c('h2',{staticClass:"section__title text-center"},[_vm._v(" What is Cloud Capsule? ")]),_vm._m(2),_c('b-row',{staticClass:"align-items-center"},[_c('b-col',{staticClass:"mb-5",attrs:{"md":"4"}},[_c('img',{attrs:{"src":require("@/assets/images/user-portal/capsule-cloud-sm.png"),"alt":""}})]),_c('b-col',{staticClass:"mb-5",attrs:{"md":"7"}},[_c('div',{staticClass:"section__body text-block"},[_c('p',[_vm._v(" Upload letters, photos, videos or other materials you want to keep. We store them securely on secure servers until the scheduled delivery date. On the scheduled date, we will send your message to the recipient at the specified email address. ")]),_c('p',[_c('router-link',{staticClass:"landing-btn",attrs:{"to":{
                name: _vm.routes.user.capsules.cloud
              }}},[_vm._v(" Get Started ")])],1)])])],1)],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-style-none d-inline-flex align-items-center mb-5 mobile-only"},[_c('li',{staticStyle:{"flex":"0 0 25%","max-width":"25%"}},[_c('img',{attrs:{"src":require("@/assets/images/user-portal/capsule-bronze.png"),"alt":""}})]),_c('li',{staticClass:"px-5",staticStyle:{"flex":"0 0 50%","max-width":"50%"}},[_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/user-portal/capsule-silver.png"),"alt":""}})]),_c('li',{staticStyle:{"flex":"0 0 25%","max-width":"25%"}},[_c('img',{attrs:{"src":require("@/assets/images/user-portal/capsule-gold.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__body text-block"},[_c('p',[_vm._v(" The time capsule is a titanium ball that holds a drive containing your information. But it is not just a drive - it will preserve information for thousands of years, making it available for future generations. The capsule will be hermetically sealed in a vacuum chamber. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section__body mb-1"},[_c('p',[_vm._v(" Cloud Capsule is an online service that provides a unique opportunity to preserve valuable moments in your life and pass them on to future generations. After registering on our site, you can choose a date to deliver your message to the future. ")])])
}]

export { render, staticRenderFns }