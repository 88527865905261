import { email } from 'vee-validate/dist/rules';

const emails = {
  name: 'emails',
  schema: {
    validate: (value) => {
      // Split the string by commas and trim each email
      const emailAddresses = value.split(',').map((emailAddress) => emailAddress.trim());

      // Validate each email using the email rule from VeeValidate
      const invalidEmails = emailAddresses.filter((emailAddress) => !email.validate(emailAddress));

      // Return true if all emails are valid, false otherwise
      return invalidEmails.length === 0;
    },
    message: 'Please enter either a valid email or a list of comma-separated valid emails.',
  },
};

export default emails;
