import { user } from '@constants/route-names';
import { layouts, roles } from '@constants';
import component from './signup.page';

export default [
  {
    path: '/signup',
    name: user.signup,
    component,
    meta: {
      layout: layouts.userBlank,
      portal: roles.user,
    },
  },
];
