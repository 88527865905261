export default {
  tiny: 10,
  small: 20,
  smallToMedium: 60,
  mediumSmall: 100,
  medium: 200,
  mediumLarge: 500,
  large: 1000,
  extraLarge: 2000,
  giant: 5000,
  humongous: 20000,
};
