<template>
  <div class="user-layout">
    <v-header />

    <div class="user-layout__content">
      <slot />
    </div>

    <v-footer />
  </div>
</template>

<script>
import Header from '@components/user-header';
import Footer from './components/footer';

export default {
  name: 'UserBlankLayout',
  components: {
    VHeader: Header,
    VFooter: Footer,
  },
};
</script>
