import errorCodes from '@constants/error-codes';
import * as routes from './route-names';
import fieldLengths from './field-lengths';
import messages from './messages';
import toastOptions from './toast-options';
import roles from './roles';
import languages from './languages';
import layouts from './layouts';
import dateFormats from './date-formats';
import sortingDirections from './sorting-directions';
import localStorageKeys from './local-storage-keys';
import capsuleStatuses from './capsule-statuses';
import paymentProviders from './payment-providers';
import paymentStatuses from './payment-statuses';
import cloudCapsuleStatuses from './cloud-capsule-statuses';
import createCapsuleResult from './create-capsule-result';
import userTimeCapsuleStatuses from './user-time-capsule-statuses';
import errorMessages from './error-messages';

const inject = {
  install(vue) {
    // eslint-disable-next-line no-param-reassign
    vue.prototype.$constants = {
      routes,
      fieldLengths,
    };
  },
};

export {
  createCapsuleResult,
  localStorageKeys,
  inject,
  routes,
  messages,
  toastOptions,
  roles,
  layouts,
  languages,
  dateFormats,
  sortingDirections,
  capsuleStatuses,
  paymentProviders,
  paymentStatuses,
  errorCodes,
  cloudCapsuleStatuses,
  userTimeCapsuleStatuses,
  errorMessages,
};
