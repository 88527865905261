import { user } from '@constants/route-names';
import { layouts, roles } from '@constants';
import component from './signin.page';

export default [
  {
    path: '/signin',
    name: user.signin,
    component,
    meta: {
      layout: layouts.userBlank,
      portal: roles.user,
    },
  },
];
