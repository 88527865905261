<template>
  <!-- begin::Scrolltop -->
  <div
    id="kt_scrolltop"
    ref="kt_scrolltop"
    class="scrolltop"
  >
    <span class="svg-icon">
      <inline-svg :src="require('@assets/images/icons/Up.svg')" />
    </span>
  </div>
  <!-- end::Scrolltop -->
</template>
<script lang="babel">
import KTLayoutScrolltop from '@assets/js/layout/extended/scrolltop';

export default {
  mounted() {
    KTLayoutScrolltop.init(this.$refs.kt_scrolltop);
  },
};

</script>
<style lang="scss">
@import "@assets/sass/_variables.scss";
</style>
