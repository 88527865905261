import { render, staticRenderFns } from "./review-card.vue?vue&type=template&id=5c2c8afe&"
import script from "./review-card.vue?vue&type=script&lang=js&"
export * from "./review-card.vue?vue&type=script&lang=js&"
import style0 from "./review-card.vue?vue&type=style&index=0&id=5c2c8afe&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports