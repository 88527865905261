import {
  routes,
  roles,
  layouts,
} from '@app/constants';

import component from './capsules.page.user';

const timeCapsulesComponent = () => import('./time-capsules/time-capsules.page.user');
const cloudCapsulesComponent = () => import('./cloud-capsules/cloud-capsules.page.user');
const cloudCapsulesCabinetComponent = () => import('./cloud-capsules-cabinet/cloud-capsules-cabinet.page.user');

export default [
  {
    path: '/capsules',
    name: routes.user.capsules.index,
    component,
    meta: {
      auth: false,
      title: 'Capsules',
      layout: layouts.user,
    },
  },
  {
    path: '/capsules/time-capsules',
    name: routes.user.capsules.time,
    component: timeCapsulesComponent,
    meta: {
      auth: false,
      title: 'Time Capsules',
      layout: layouts.user,
    },
  },
  {
    path: '/capsules/time-capsules/:capsuleId',
    name: routes.user.capsules.timeSelected,
    component: timeCapsulesComponent,
    meta: {
      auth: false,
      title: 'Time Capsules',
      layout: layouts.user,
    },
  },
  {
    path: '/capsules/time-capsules/:capsuleId/user-time-capsules/:userCapsuleId',
    name: routes.user.capsules.timeView,
    component: timeCapsulesComponent,
    meta: {
      auth: true,
      title: 'Time Capsules',
      roles: [roles.user],
      layout: layouts.user,
    },
  },
  {
    path: '/capsules/cloud-capsules',
    name: routes.user.capsules.cloud,
    component: cloudCapsulesComponent,
    meta: {
      auth: false,
      title: 'Cloud Capsules',
      layout: layouts.user,
    },
  },
  {
    path: '/capsules/cloud-capsules/:capsuleId',
    name: routes.user.capsules.cloudView,
    component: cloudCapsulesComponent,
    meta: {
      auth: true,
      title: 'Cloud Capsules',
      roles: [roles.user],
      layout: layouts.user,
    },
  },
  {
    path: '/personal/capsules/cloud-capsules',
    name: routes.user.capsules.cloudPersonalCabinet,
    component: cloudCapsulesCabinetComponent,
    meta: {
      auth: true,
      title: 'Cloud Capsules Cabinet',
      roles: [roles.user],
      layout: layouts.user,
    },
  },
  {
    path: '/personal/capsules/cloud-capsules/:capsuleId',
    name: routes.user.capsules.cloudPersonalCabinetView,
    component: cloudCapsulesCabinetComponent,
    meta: {
      auth: true,
      title: 'Cloud Capsules Cabinet',
      roles: [roles.user],
      layout: layouts.user,
    },
  },
];
