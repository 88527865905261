import { notificationsApi } from '@services/api/admin';

export default {
  notifications: {
    builders: [
      () => ({
        text: 'Notifications',
        to: { name: 'admin.notifications' },
      }),
    ],
    build() {
      return [
        {
          text: 'Notifications',
          to: { name: 'admin.notifications' },
        },
      ];
    },
  },
  notificationEdit: {
    builders: [
      () => ({
        text: 'Notifications',
        to: { name: 'admin.notifications' },
      }),
      async ({ id: notificationId }) => {
        const { data: { data: notification } } = await notificationsApi.getById(notificationId);

        return {
          text: notification.name,
          to: {
            name: 'admin.notification-edit',
            params: {
              id: notificationId,
            },
          },
        };
      },
    ],
    build(notificationId, notificationName) {
      return [
        {
          text: 'Notifications',
          to: { name: 'admin.notifications' },
        },
        {
          text: notificationName,
          to: {
            name: 'admin.notification-edit',
            params: {
              id: notificationId,
            },
          },
        },
      ];
    },
  },
};
