import { error } from '@constants/route-names';
import { layouts } from '@constants';
import page from './error.page';

export default [
  {
    path: '/error',
    name: error.index,
    component: page,
    meta: {
      layout: layouts.unauthorized,
    },
  },
];
