import { gridConfig } from '@config';
import complexDataBuilder from '../complex-data-builder';

export default class Filter {
  constructor(callback, settings) {
    this.settings = {};
    Object.assign(this.settings, settings || gridConfig.filtering);
    this.callback = callback;
    this[this.settings.key] = null;
    this.lastSearch = null;
  }

  init() {
    this.callback(this.buildPayload());
  }

  update(filteringHeader) {
    this[this.settings.key] = filteringHeader[this.settings.key];
    this.lastSearch = filteringHeader[this.settings.key];
  }

  get events() {
    const self = this;
    return {
      onSearch() {
        self.callback(self.buildPayload(self[self.settings.key]));
      },
      onCleared() {
        // eslint-disable-next-line eqeqeq
        if (self.lastSearch == self[self.settings.key]) {
          self.callback(self.buildPayload());
        } else { self[self.settings.key] = null; }
      },
    };
  }

  buildPayload(value) {
    return this.settings.isComplex ? complexDataBuilder.build('filteringParams.filter', value)
      : {
        [this.settings.key]: value,
      };
  }
}
