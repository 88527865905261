import {
  routes,
  roles,
  layouts,
} from '@app/constants';
import index from './dashboard.page.admin';
import breadcrumbs from './dashboard.routes.breadcrumbs.admin';

export default [
  {
    path: '/admin/dashboard',
    name: routes.admin.dashboard,
    component: index,
    meta: {
      auth: true,
      title: 'Dashboard',
      roles: [roles.admin],
      layout: layouts.admin,
      breadcrumbs: breadcrumbs.builders,
    },
  },
];
