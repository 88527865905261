<template>
  <div class="user-layout">
    <Loader v-if="loaderEnabled" />

    <v-header />

    <div class="user-layout__content">
      <slot />
    </div>

    <v-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@services/store/htmlclass.module';
import Loader from '@components/loader';
import Header from '@components/user-header';
import Footer from './components/footer';

export default {
  name: 'UserLayout',
  components: {
    VHeader: Header,
    VFooter: Footer,
    Loader,
  },
  computed: {
    ...mapGetters([
      'layoutConfig',
    ]),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
  },
  mounted() {
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 1000);
  },
};
</script>
