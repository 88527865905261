<template>
  <div class="signin-page">
    <div class="signin-page__container container">
      <div class="signin-page__wrap">
        <b-row class="justify-content-center">
          <b-col
            md="6"
            xl="4"
          >
            <ValidationObserver
              ref="observer"
              tag="div"
            >
              <b-form
                class="card"
                @submit.prevent="login"
              >
                <div
                  v-loader="loadDataPromise"
                  class="card-body"
                >
                  <h2 class="h3 text-center mb-3">
                    Admin Sign In
                  </h2>

                  <b-alert
                    variant="danger"
                    :show="!!errorMessage"
                  >
                    {{ errorMessage }}
                  </b-alert>

                  <b-alert
                    variant="light-primary"
                    :show="!!infoMessage"
                  >
                    {{ infoMessage }}
                  </b-alert>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    name="Email"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-5"
                  >
                    <label for="email">
                      Email
                    </label>

                    <b-form-input
                      id="email"
                      v-model="model.email"
                      type="text"
                      placeholder="Enter your email"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Password"
                    tag="div"
                    mode="eager"
                    class="position-relative form-group mb-8"
                  >
                    <label for="password">
                      Password
                    </label>

                    <b-form-input
                      id="password"
                      v-model="model.password"
                      type="password"
                      placeholder="Enter your password"
                    />

                    <span class="position-absolute error">
                      {{ errors[0] }}
                    </span>
                  </validation-provider>

                  <div class="mb-6">
                    <b-btn
                      type="submit"
                      variant="primary"
                      class="w-100"
                    >
                      Sign In
                    </b-btn>
                  </div>

                  <div class="w-100 text-center">
                    <router-link :to="{ name: $constants.routes.admin.resetPassword }">
                      Forgot Password?
                    </router-link>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@services/store';
import { messages } from '@constants';
import { SIGNIN_ADMIN } from '@services/store/profile.module';

export default {
  name: 'SignInPage',
  data() {
    return {
      loadDataPromise: null,
      model: {
        email: '',
        password: '',
      },
      errorMessage: '',
      infoMessage: '',
    };
  },
  watch: {
    '$route.params.infoMessage': {
      handler(val) {
        this.infoMessage = val;
      },
      immediate: true,
    },
  },
  methods: {
    async login() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      try {
        this.clearMessages();
        this.loadDataPromise = store.dispatch(SIGNIN_ADMIN, this.model);
        await this.loadDataPromise;
        await this.$router.push({ name: this.$constants.routes.admin.capsules });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          this.errorMessage = messages.unauthorizeErrorMessage;
        } else {
          this.errorMessage = messages.unexpectedError;
        }
      }
    },
    clearMessages() {
      this.errorMessage = '';
      this.infoMessage = '';
    },
  },
};
</script>
<style lang="scss">
@import '@assets/sass/_variables.scss';
@import '@assets/sass/base/_mixins.scss';

.signin-page {
  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    padding: 1rem 0;

    @include respond-below(md) {
      min-height: 0;
      padding: 9rem 0 3rem;
    }
  }
}
</style>
