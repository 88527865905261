const landing = {
  index: 'landing.index',
};

const user = {
  capsules: {
    index: 'user.capsules.index',
    time: 'user.capsules.time',
    timeSelected: 'user.capsules.time.selected',
    timeView: 'user.capsules.time.view',
    cloud: 'user.capsules.cloud',
    cloudView: 'user.capsules.cloud.view',
    cloudPersonalCabinet: 'routes.user.capsules.cloud.cabinet',
    cloudPersonalCabinetView: 'routes.user.capsules.cloud.cabinet.view',
  },
  signin: 'user.signin',
  signup: 'user.signup',
  resetPassword: 'user.reset-password',
};

const admin = {
  dashboard: 'admin.dashboard',
  signin: 'admin.signin',
  resetPassword: 'admin.reset-password',
  capsules: 'admin.capsules',
  capsuleCreate: 'admin.capsule-create',
  capsuleEdit: 'admin.capsule-edit',
  users: 'admin.users',
  userEdit: 'admin.userEdit',
  notifications: 'admin.notifications',
  notificationEdit: 'admin.notification-edit',
};

const notFound = {
  index: 'not-found.index',
};

const error = {
  index: 'error.index',
};

export {
  landing,
  user,
  admin,
  notFound,
  error,
};
