<template>
  <header
    class="user-header"
    :class="{ '_scrolled': isScrolled }"
  >
    <div class="user-header__container container">
      <div class="user-header__wrap">
        <div class="user-header__wrap-element-left">
          <router-link
            :to="{ name: routes.landing.index }"
            class="user-header__logo user-logo"
          >
            <img
              src="@/assets/images/landing/logo.svg"
              alt="IT EON"
            >

            <span>It-eoN</span>
          </router-link>
        </div>

        <nav
          class="user-header__nav user-header__wrap-element-center"
        >
          <ul class="user-header__menu">
            <li>
              <router-link
                :to="{
                  name: routes.landing.index
                }"
              >
                Home
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: routes.user.capsules.time
                }"
              >
                Time Capsules
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: isAuthorized
                    ? profileInfo.hasCloudCapsules
                      ? routes.user.capsules.cloudPersonalCabinet
                      : routes.user.capsules.cloud
                    : routes.user.capsules.cloud
                }"
              >
                Cloud Capsules
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: routes.user.capsules.index
                }"
              >
                Prices
              </router-link>
            </li>
          </ul>
        </nav>

        <div
          class="d-flex user-header__social-and-login user-header__wrap-element-right"
        >
          <social-networks-list
            v-if="!isAuthorized"
            class="landing-header__soc"
          />
          <template
            v-if="!isAuthorized"
          >
            <router-link
              :to="{ name: routes.user.signin,
                     query: {
                       returnUrl: $route.name !== routes.landing.index && !$route.query.returnUrl ?
                         $route.path : $route.query.returnUrl
                     } }"
              class="landing-btn"
            >
              Sign in
            </router-link>
          </template>
          <template
            v-else
          >
            <Topbar
              v-if="isUser"
            />
            <router-link
              v-else
              to="/admin/capsules"
              class="landing-btn"
            >
              Dashboard
            </router-link>
          </template>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { routes } from '@app/constants';
import { mapGetters } from 'vuex';
import Topbar from './topbar';

export default {
  name: 'UserHeaderLayout',
  components: {
    Topbar,
  },
  data() {
    return {
      routes,
      isScrolled: false,
    };
  },
  computed: {
    ...mapGetters([
      'profileInfo', 'isAuthorized', 'isUser',
    ]),
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.isScrolled = scrollPosition > 0;
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.user-header {
  font-size: 14px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba($color-black, 0.1);
  transition: background-color 0.35s;

  &._scrolled {
    background-color: rgba($color-black, 0.85);

    .user-header__wrap {
      padding: 0.5rem 0;
    }
  }

  @include respond-below(md) {
    background-color: rgba($color-black, 0.85);
  }

  &__container {
    max-width: 1580px !important;
  }

  &__wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    transition: padding 0.35s;

    @include respond-below(md) {
      padding: 0.5rem 0;
    }
  }

  &__wrap-element-center {
    align-self: center;
  }

  &__wrap-element-left {
    width: 30rem;
  }

  &__wrap-element-right {
    width: 30rem;
    justify-content: flex-end;
  }

  &__social-and-login {
    gap: 1rem 2.5rem;
  }

  &__logo {
    margin-right: auto;
  }

  &__menu {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    > li {
      margin: 0.5rem 2rem;

      > a {
        position: relative;
        padding: 0.75rem 0.25rem;
        font-weight: 700;
        color: $color-white;
        transition: color 0.35s;

        &:hover,
        &:focus,
        &:active,
        &.router-link-exact-active {
          color: $color-primary;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          height: 0.25rem;
          background-color: $color-white;
          transition: all 0.5s;
        }
      }
    }

    @include respond-below(md) {
      position: fixed;
      z-index: 100;
      left: -100vw;
      top: 0;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
      padding: 32px 20px;
      background-color: $color-black;
      color: $color-white;
    }
  }
}
</style>
