<template>
  <section class="signin-page">
    <div class="signin-page__container container">
      <div class="signin-page__wrap">
        <b-row class="justify-content-center">
          <b-col
            md="6"
            xl="4"
          >
            <div class="card">
              <div class="card-body">
                <h2 class="text-primary text-center font-weight-bold mb-5">
                  Reset password
                </h2>

                <b-alert
                  variant="light-danger"
                  :show="!!errorMessage"
                >
                  {{ errorMessage }}
                </b-alert>

                <template v-if="step === 1">
                  <validation-observer
                    ref="observerForgotPassword"
                    tag="div"
                  >
                    <b-form @submit.prevent="sendForgotRequest">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|email"
                        name="Email"
                        tag="div"
                        class="position-relative form-group mb-6"
                      >
                        <b-form-input
                          id="email"
                          v-model="model.email"
                          type="text"
                          name="email"
                          placeholder="Enter your email"
                        />

                        <span class="position-absolute error">
                          {{ errors[0] }}
                        </span>
                      </validation-provider>

                      <div>
                        <b-btn
                          variant="user"
                          type="submit"
                          class="w-100"
                        >
                          Submit
                        </b-btn>
                      </div>
                    </b-form>
                  </validation-observer>
                </template>

                <!--begin::Confirm send token-->
                <template v-if="step === 2">
                  <p class="text-center">
                    If your email address exists in our database,
                    you will receive a password recovery link at
                    your email address in a few minutes.
                  </p>
                </template>
                <!--end::Confirm send token-->

                <!--begin::Validate token-->
                <template v-if="step === 0">
                  <validation-observer
                    ref="observerToken"
                    tag="div"
                  >
                    <b-form
                      class="opacity-0"
                      @submit.prevent="sendValidateToken"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Token"
                        tag="div"
                        class="position-relative form-group mb-6"
                      >
                        <b-form-input
                          id="token"
                          v-model="model.token"
                          type="text"
                          name="token"
                          placeholder="Enter token"
                          @blur="firstRender = false"
                        />

                        <span
                          class="position-absolute error"
                          :class="{ 'd-none': firstRender }"
                        >
                          {{ errors[0] }}
                        </span>
                      </validation-provider>

                      <div>
                        <b-btn
                          type="submit"
                          variant="user"
                          class="w-100"
                        >
                          Submit
                        </b-btn>
                      </div>
                    </b-form>
                  </validation-observer>
                </template>
                <!--end::Validate token-->

                <!--begin::ResetPassword-->
                <template v-if="step === 4">
                  <validation-observer
                    ref="observerResetPassword"
                    tag="div"
                  >
                    <b-form @submit.prevent="sendResetPassword">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|min:6|password"
                        name="Password"
                        tag="div"
                        class="position-relative form-group mb-6"
                      >
                        <b-form-input
                          id="password"
                          v-model="model.password"
                          type="password"
                          name="password"
                          placeholder="Enter your new password"
                          @blur="firstRender = false"
                        />

                        <span
                          class="position-absolute error"
                          :class="{ 'd-none': firstRender }"
                        >
                          {{ errors[0] }}
                        </span>
                      </validation-provider>

                      <div>
                        <b-btn
                          variant="user"
                          type="submit"
                          class="w-100"
                        >
                          Submit
                        </b-btn>
                      </div>
                    </b-form>
                  </validation-observer>
                </template>
                <!--end::Reset password-->

                <!--begin::Confirm reset password-->
                <template v-if="step === 5">
                  <p class="text-center">
                    Password changed successfully. <br>
                    You can try to sign in now with your new password.
                  </p>
                </template>
                <!--end::Confirm reset password-->

                <div class="w-100 pt-6 text-center">
                  <router-link
                    :to="{ name: $constants.routes.user.signin }"
                    class="text-white font-weight-bold"
                  >
                    Sign In
                  </router-link>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { auth as AuthApi } from '@services/api/user';

export default {
  name: 'ForgotPasswordPage',
  data() {
    return {
      model: {
        email: null,
        token: null,
        password: null,
      },
      errorMessage: '',
      step: 1,
      firstRender: true,
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.email && val.query.token) {
          this.model.email = val.query.email;
          this.model.token = val.query.token;
          this.step = 3;
          this.sendValidateToken();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async sendForgotRequest() {
      this.errorMessage = '';

      const isValid = await this.$refs.observerForgotPassword.validate();
      if (!isValid) return;

      const request = {
        email: this.model.email,
      };

      AuthApi.forgotPassword(request)
        .then(() => {
          this.step = 2;
        })
        .catch(() => {
          this.errorMessage = 'Email does not match any account';
        });
    },
    sendValidateToken() {
      this.errorMessage = '';
      this.firstRender = false;

      const request = {
        email: this.model.email,
        token: this.model.token,
      };

      AuthApi.validateResetToken(request)
        .then((response) => {
          if (!response.data.data) {
            this.errorMessage = 'Token is not valid';
            return;
          }
          this.step = 4;
          this.firstRender = true;
        })
        .catch(() => {
          this.errorMessage = 'Token is not valid';
        });
    },
    async sendResetPassword() {
      this.errorMessage = '';
      this.firstRender = false;

      const isValid = await this.$refs.observerResetPassword.validate();
      if (!isValid) return;

      const request = {
        email: this.model.email,
        token: this.model.token,
        newPassword: this.model.password,
      };

      AuthApi.resetPassword(request)
        .then(() => {
          this.step = 5;
        })
        .catch(() => {
          this.errorMessage = 'Password is not valid';
        });
    },
  },
};
</script>
