<template>
  <div class="page-loader page-loader-logo">
    <span class="h1 text-primary">
      IT EON
    </span>

    <div
      class="spinner"
      :class="spinnerClass || 'spinner-primary'"
    />
  </div>
</template>

<script>
export default {
  name: 'LoaderComponent',
  props: {
    spinnerClass: {
      type: String,
      default: '',
    },
  },
};
</script>
