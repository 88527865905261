<template>
  <section class="section">
    <div class="section__container container">
      <div class="section__wrap">
        <h2
          class="section__title text-center"
          data-aos="fade-up"
        >
          FAQ
        </h2>

        <b-tabs>
          <b-tab title="Time Capsule">
            <div class="faqs-card">
              <ul class="faqs-list">
                <li
                  v-for="(faq, faqIndex) in timeCapsuleFaqs"
                  :key="faqIndex"
                >
                  <div class="toggle">
                    <button
                      class="toggle__btn"
                      @click="toggleTimeCapsuleFAQ(faqIndex)"
                    >
                      {{ faq.question }}

                      <span>
                        {{ faq.open ? '-' : '+' }}
                      </span>
                    </button>

                    <b-collapse
                      :id="'faq-' + faqIndex"
                      v-model="faq.open"
                    >
                      <div
                        class="pb-6"
                        style="font-size: 1rem"
                        v-html="faq.answer"
                      />
                    </b-collapse>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>

          <b-tab title="Cloud Capsule">
            <div class="faqs-card">
              <ul class="faqs-list">
                <li
                  v-for="(faq, faqIndex) in cloudCapsuleFaqs"
                  :key="faqIndex"
                >
                  <div class="toggle">
                    <button
                      class="toggle__btn"
                      @click="toggleCloudCapsuleFAQ(faqIndex)"
                    >
                      {{ faq.question }}

                      <span>
                        {{ faq.open ? '-' : '+' }}
                      </span>
                    </button>

                    <b-collapse
                      :id="'faq-' + faqIndex"
                      v-model="faq.open"
                    >
                      <div
                        class="pb-6"
                        style="font-size: 1rem"
                        v-html="faq.answer"
                      />
                    </b-collapse>
                  </div>
                </li>
              </ul>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FAQsSection',
  props: {
    timeCapsuleFaqs: {
      type: Array,
      required: true,
    },
    cloudCapsuleFaqs: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'FAQ',
    },
  },
  methods: {
    toggleTimeCapsuleFAQ(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.timeCapsuleFaqs[index].open = !this.timeCapsuleFaqs[index].open;
    },
    toggleCloudCapsuleFAQ(index) {
      // eslint-disable-next-line vue/no-mutating-props
      this.cloudCapsuleFaqs[index].open = !this.cloudCapsuleFaqs[index].open;
    },
  },
};
</script>

<style lang="scss">
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.toggle {
  border-bottom: 0.125rem solid rgba(#9e9e9e, 0.32);

  &__btn {
    position: relative;
    border: none;
    width: 100%;
    padding: 1.5rem 0.125rem;
    padding-right: 2.5rem;
    background: none;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5;
    color: inherit;
    text-align: left;

    @include respond-below(md) {
      padding: 1rem 0.125rem;
      padding-right: 2.5rem;
      font-size: 1.125rem;
    }

    span {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.faqs-card {
  border-radius: 2.5rem;
  padding: 2.25rem 3.75rem;
  background-color: rgba($landing-color-blue-dark, 0.8);

  @include respond-below(md) {
    border-radius: 1.5rem;
    padding: 1.5rem 1rem;
  }
}

.faqs-list {
  list-style: none;
  padding-left: 0;
}
</style>
