import {
  extend, localize, ValidationObserver, ValidationProvider,
} from 'vee-validate';
import {
  // eslint-disable-next-line camelcase
  required, min, max, numeric, email, min_value, confirmed, image, size,
} from 'vee-validate/dist/rules';
import * as customRules from '@utils/validation-rules';
import Vue from 'vue';
import dictionaries from '../internationalization/dictionaries';

localize({
  en: {
    ...dictionaries.en.validation,
  },
  fr: {
    ...dictionaries.fr.validation,
  },
});
localize('en');

extend('numeric', numeric);
extend('required', required);
extend('min', min);
extend('max', max);
extend('email', email);
extend('min_value', min_value);
extend('confirmed', confirmed);
extend('image', image);
extend('size', size);
extend(customRules.password.name, {
  ...customRules.password.schema,
});
extend(customRules.emails.name, {
  ...customRules.emails.schema,
});
extend(customRules.decimal.name, {
  ...customRules.decimal.schema,
});
extend(customRules.future.name, {
  ...customRules.future.schema,
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
