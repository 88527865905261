import Axios from 'axios';

const baseControllerRoute = 'admin/files';

function get(payload) {
  return Axios.get(`${baseControllerRoute}`, { params: payload });
}

function getLink(id) {
  return Axios.get(`${baseControllerRoute}/${id}/link`);
}

function remove(id) {
  return Axios.delete(`${baseControllerRoute}/${id}`);
}

export default {
  get,
  getLink,
  remove,
};
