<template>
  <section class="hero">
    <div class="hero__container container">
      <div class="hero__wrap">
        <div class="hero__img">
          <img
            src="@/assets/images/landing/hero/main.png"
            alt=""
            height="300"
          >
        </div>

        <h1
          class="hero__title"
          data-aos="fade-up"
        >
          Time erases the traces we leave behind.
        </h1>

        <div
          class="hero__body"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <p>
            Time Capsule is an opportunity to leave a heritage for future generations.
          </p>

          <p>
            It-eoN - is your chance to become a part of history!
          </p>
        </div>

        <div class="hero__btn-box">
          <div
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <router-link
              :to="{ name: isAuthorized
                ? routes.user.capsules.index
                : routes.user.signup
              }"
              class="landing-btn"
            >
              Get started
            </router-link>
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <button
              class="landing-btn landing-btn--outline"

              @click="scrollToElement('#video')"
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { scrollManager } from '@/app/mixins';
import { mapGetters } from 'vuex';
import { routes } from '@app/constants';

export default {
  name: 'HeroSection',
  mixins: [scrollManager],
  data() {
    return {
      routes,
    };
  },
  computed: {
    ...mapGetters([
      'isAuthorized',
    ]),
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/sass/_variables.scss";
@import "@assets/sass/base/_mixins.scss";

.hero {
  &__wrap {
    min-height: 100vh;
    padding: 0.5rem 0 3rem;

    @include respond-below(md) {
      min-height: 0;
    }
  }

  &__img {
    text-align: center;

    img {
      max-height: 65vh;
      object-fit: contain;
    }
  }

  &__title {
    margin-bottom: 1.75rem;
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;

    @include respond-below(md) {
      font-size: 1.25rem;
      font-weight: 600;
    }
  }

  &__body {
    margin-bottom: 2.5rem;
    font-size: 1.75rem;
    text-align: center;

    @include respond-below(md) {
      font-size: 1.125rem;
    }

    > *:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__btn-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem 2.5rem;
  }
}
</style>
