/* eslint-disable import/no-extraneous-dependencies */
/* ============
 * Routes File
 * ============
 *
 * The routes and redirects are defined in this file
 */

// General
import { routes as notFoundRoutes } from './pages/not-found';
import { routes as errorRoutes } from './pages/error';

// Admin Portal
import { routes as adminDashboardRoutes } from './pages/admin/dashboard';
import { routes as adminSigninRoutes } from './pages/admin/signin';
import { routes as adminResetPasswordRoutes } from './pages/admin/reset-password';
import { routes as adminCapsulesRoutes } from './pages/admin/capsules';
import { routes as adminUsersRoutes } from './pages/admin/users';
import { routes as adminNotificationsRoutes } from './pages/admin/notifications';

// User Portal
import { routes as userSigninRoutes } from './pages/user/signin';
import { routes as userSignupRoutes } from './pages/user/signup';
import { routes as userResetPasswordRoutes } from './pages/user/reset-password';
import { routes as userCapsulesRoutes } from './pages/user/capsules';

// Landing
import { routes as landingRoutes } from './pages/landing/home';
import { routes as termsAndConditionsRoutes } from './pages/landing/terms-and-conditions';
import { routes as privacyPolicyRoutes } from './pages/landing/privacy-policy';

export default [
  // admin portal
  ...adminDashboardRoutes,
  ...adminSigninRoutes,
  ...adminResetPasswordRoutes,
  ...adminCapsulesRoutes,
  ...adminUsersRoutes,
  ...adminNotificationsRoutes,

  // user portal
  ...userSignupRoutes,
  ...userSigninRoutes,
  ...userResetPasswordRoutes,
  ...userCapsulesRoutes,

  // landing
  ...landingRoutes,
  ...termsAndConditionsRoutes,
  ...privacyPolicyRoutes,
  ...errorRoutes,
  ...notFoundRoutes,
];
