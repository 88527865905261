import Axios from 'axios';

const baseControllerRoute = 'files';

function get(fileId) {
  return Axios.get(`${baseControllerRoute}/${fileId}/link`);
}

function remove(fileId) {
  return Axios.delete(`${baseControllerRoute}/${fileId}`);
}

export default {
  get,
  remove,
};
