<template>
  <b-modal
    v-model="isShow"
    :title="`${isEdit ? 'Create' : ''} Digital Modal`"
    size="lg"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    modal-class="_secondary"
    @hidden="onHidden"
  >
    <validation-observer
      ref="observer"
      tag="div"
    >
      <b-form @submit.prevent="goToPayment">
        <h2 class="font-weight-boldest text-primary text-center mb-10">
          {{ isEdit ? 'Create': '' }}
          Time Capsule
        </h2>

        <!-- Files Block -->
        <div
          v-loader="filesPromise"
          class="pb-10"
        >
          <div
            v-if="model.files.length > 0"
            class="mb-10"
          >
            <h4 class="text-primary">
              Files
            </h4>

            <ul
              class="list-style-none m-0 mb-5 overflow-scroll"
              style="max-height: 15rem;"
            >
              <li
                v-for="file in model.files"
                :key="file.id"
                class="p-1"
              >
                <b-row class="justify-content-between">
                  <b-col
                    cols="7"
                    sm="6"
                    class="text-truncate"
                    :title="file.name"
                  >
                    {{ file.name }}
                  </b-col>

                  <b-col
                    cols="3"
                    sm="3"
                    class="text-right"
                  >
                    {{ HumanBytesFormat.humanize(file.size) }}
                  </b-col>

                  <b-col
                    cols="2"
                    sm="3"
                    class="text-right"
                  >
                    <b-link
                      v-if="!isPaid"
                      class="p-2 lh-1"
                      @click="removeFile(file.id)"
                    >
                      <i
                        class="text-danger flaticon2-cross"
                        style="font-size: 0.75rem;"
                      />
                    </b-link>
                  </b-col>
                </b-row>
              </li>
            </ul>
          </div>

          <div
            v-if="!isPaid"
          >
            <h3 class="font-weight-normal text-center mb-10">
              Upload files you want to send to the capsule
            </h3>

            <div>
              <p class="disclaimer text-center">
                Before uploading video and images we suggest to compress them in order
                to reduce the size.
                You can use one of the services below: <br>
                <a
                  href="https://tinypng.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tinypng.com
                </a> &nbsp;
                <a
                  href="https://www.media.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Media.io
                </a> &nbsp;
                <a
                  href="https://www.freeconvert.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FreeConvert.com
                </a> &nbsp;
                <a
                  href="https://www.veed.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Veed.io
                </a> &nbsp;

                or any other service you like
              </p>

              <b-form-file
                id="fileInput"
                ref="fileInput"
                v-model="model.addedFiles"
                multiple
                placeholder="Drop Files or Click Here to select"
                drop-placeholder="Drop file here..."
                @change="filesSelected"
              />
            </div>
          </div>
        </div>

        <!-- Calculate Block -->
        <b-row
          v-if="!isPaid && priceWasCalculated"
          class="justify-content-center mb-15"
        >
          <b-col md="9">
            <b-row class="align-items-center">
              <b-col cols="6">
                <span class="text-primary">Memory Storage</span>
              </b-col>

              <b-col
                cols="6"
                class="text-right"
              >
                <span>
                  {{ size }}
                </span>
              </b-col>
            </b-row>

            <b-row
              v-if="model.priceInfoCalculated && model.priceInfoCalculated.spaceDiscountPercentage"
              class="align-items-center"
            >
              <b-col cols="6">
                <span class="text-primary">Discount</span>
              </b-col>

              <b-col
                cols="6"
                class="text-right"
              >
                <span>
                  {{ shownSpaceDiscountPercentage }}
                </span>
              </b-col>
            </b-row>

            <b-row v-if="!priceMoreThanMinimal">
              <b-col cols="6">
                <div class="text-primary">
                  Minimal Price
                </div>
              </b-col>

              <b-col
                cols="6"
                class="text-right"
              >
                {{ CurrencyFormat(minimalPrice) }}
              </b-col>
            </b-row>

            <b-row
              v-if="model.priceInfoCalculated && model.priceInfoCalculated.totalPrice
                || model.priceInfoCalculated && model.priceInfoCalculated.totalPrice === 0"
              class="align-items-center pt-1 mb-5"
            >
              <b-col cols="6">
                <span class="h6 mb-0 text-primary">Total Price</span>
              </b-col>

              <b-col
                cols="6"
                class="text-right"
              >
                <span>
                  {{ !priceMoreThanMinimal
                    ? CurrencyFormat(minimalPrice.toFixed(2))
                    : CurrencyFormat(model.priceInfoCalculated.totalPrice.toFixed(2))
                  }}
                </span>
              </b-col>
            </b-row>

            <!-- Terms Block -->
            <div
              v-if="!isPaid && priceWasCalculated"
              class="mb-0"
            >
              <b-form-checkbox
                id="agreeWithTerms"
                v-model="model.agreeWithTerms"
                name="agreeWithTerms"
              >
                I have read and agree to the
                <router-link
                  :to="{ name: 'terms-and-conditions.index' }"
                  target="_blank"
                >
                  Terms of Use
                </router-link>
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>

        <!-- Footer -->
        <b-row class="flex-wrap-reverse align-items-center">
          <b-col
            md="6"
            class="text-center text-md-left"
          >
            <button
              class="link py-5"
              type="reset"
              @click="hide"
            >
              {{ isEdit ? 'Cancel': 'Close' }}
            </button>
          </b-col>

          <b-col
            md="6"
          >
            <button
              v-if="!isPaid"
              type="submit"
              :disabled="filesPromise || !model.agreeWithTerms"
              class="landing-btn w-100"
            >
              Go To Payment
            </button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div
      v-if="model.errorMessage"
      class="error"
    >
      {{ model.errorMessage }}
    </div>
  </b-modal>
</template>

<script>
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isEqual } from 'lodash';

import { saveAs } from 'file-saver';
import { files as filesApi, userTimeCapsules } from '@services/api/user';
import { user as userApi } from '@/app/services/api';
import { DateFormat, CurrencyFormat, HumanBytesFormat } from '@/app/formatters';
import { routes, userTimeCapsuleStatuses } from '@/app/constants';

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);

function getDefaultCapsuleModel() {
  return {
    id: null,
    files: [],
    status: '',
  };
}

function getDefaultPriceInfo() {
  return {
    size: 0,
    spacePrice: 0,
    totalPrice: 0,
    spaceDiscountPercentage: 0,
  };
}

function megabytesToBytes(megabytes) {
  return megabytes * 1024 * 1024;
}

function getDefaultModel() {
  return {
    agreeWithTerms: false,
    capsule: getDefaultCapsuleModel(),
    files: [],
    addedFiles: [],
    existingCapsuleId: null,
    timeCapsuleId: null,
    generalCapsuleId: null,
    priceInfoCalculated: getDefaultPriceInfo(),
    errorMessage: '',
  };
}

export default {
  name: 'TimeCapsuleCreateEditModal',
  emits: ['message-created', 'capsule-updated'],
  data() {
    return {
      isShow: false,
      CurrencyFormat,
      DateFormat,
      HumanBytesFormat,
      filesPromise: null,
      calculatePromise: null,
      minimalPrice: 100,
      showDownloadFileLink: false,
      capsulePartMinValue: megabytesToBytes(10),
      model: getDefaultModel(),
    };
  },
  computed: {
    priceMoreThanMinimal() {
      return this.model.priceInfoCalculated.totalPrice >= this.minimalPrice;
    },
    shownSpaceDiscountPercentage() {
      return `${this.model.priceInfoCalculated.spaceDiscountPercentage * 100}%`;
    },
    isEdit() {
      return this.model.capsule.status !== userTimeCapsuleStatuses.paid.key;
    },
    isPaid() {
      return this.model.capsule.status === userTimeCapsuleStatuses.paid.key;
    },
    priceWasCalculated() {
      return !isEqual(this.model.priceInfoCalculated, getDefaultPriceInfo());
    },
    canCalculatePrice() {
      return this.model.files.length > 0 && this.isEdit;
    },
    capsulePartSize() {
      return this.model.files.reduce((total, f) => total + f.size, 0);
    },
    size() {
      return HumanBytesFormat.humanize(this.model.priceInfoCalculated.size);
    },
  },
  watch: {
    'model.addedFiles': {
      async handler(value) {
        if (value.length > 0) {
          await this.uploadFiles(this.model.capsule.id);
        }
      },
    },
    'model.capsule.id': {
      async handler(newValue, oldValue) {
        if (newValue === oldValue) return;

        if (!newValue) {
          if (this.$route.name === routes.user.capsules.timeView) {
            await this.$router.push({
              name: routes.user.capsules.timeSelected,
            }).catch(() => {});
          }
        } else if (this.$route.name === routes.user.capsules.timeSelected) {
          await this.$router.push({
            name: routes.user.capsules.timeView,
            params: {
              userCapsuleId: newValue,
            },
          }).catch(() => {});
        }
      },
    },
    capsulePartSize: {
      async handler(value) {
        if (value) {
          await this.calculatePrice();
        }
      },
    },
  },
  mounted() {
    this.model.existingCapsuleId = this.$route.params.userCapsuleId;
    this.model.generalCapsuleId = this.$route.params.capsuleId;

    if (this.model.existingCapsuleId) {
      this.show(this.model.generalCapsuleId);
    }
  },
  methods: {
    async show(capsuleId, isEdit) {
      if (isEdit) {
        this.model.existingCapsuleId = capsuleId;
      } else {
        this.model.timeCapsuleId = capsuleId;
      }

      await this.getData();

      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    async goToPayment() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      const data = {
        ...this.model.capsule,
        size: this.model.priceInfoCalculated.size,
        price: this.model.priceInfoCalculated.totalPrice,
      };

      this.$emit('capsule-created', data);
    },
    async getData() {
      if (this.model.existingCapsuleId) {
        const capsule = await this.getExistingTimeCapsule(this.model.existingCapsuleId);
        await this.getFiles(this.model.existingCapsuleId);
        await this.$nextTick();
        if (capsule.status !== userTimeCapsuleStatuses.paid.key) {
          await this.calculatePrice();
        }
      } else {
        await this.createTimeCapsule();
      }
    },
    async createTimeCapsule() {
      const newCapsule = {
        timeCapsuleId: this.model.timeCapsuleId,
      };

      const { data: { data } } = await userApi.userTimeCapsules.createTimeCapsule(newCapsule);
      this.model.capsule = data;
    },
    async getExistingTimeCapsule(capsuleId) {
      const { data: { data } } = await userApi.userTimeCapsules.get(capsuleId);
      this.model.capsule = data;
      return data;
    },
    onHidden() {
      this.model = getDefaultModel();
    },
    async calculatePrice() {
      if (this.priceWasCalculated && !this.canCalculatePrice) {
        this.model.priceInfoCalculated = getDefaultPriceInfo();
      }

      if (!this.canCalculatePrice) return;

      this.filesPromise = userApi.userTimeCapsules.calculateTimeCapsulePrice(this.model.capsule.id);
      const { data: { data: calculatedData } } = await this.filesPromise;

      this.model.priceInfoCalculated.size = calculatedData.size;
      this.model.priceInfoCalculated.spacePrice = calculatedData.spacePrice;
      this.model.priceInfoCalculated.totalPrice = calculatedData.totalPrice;
      this.model.priceInfoCalculated.spaceDiscountPercentage = calculatedData
        .spaceDiscountPercentage;

      this.filesPromise = null;
    },
    filesSelected() {
      this.model.errorMessage = '';
    },
    async uploadFiles(userCapsuleId) {
      if (this.model.addedFiles.length === 0) return;

      const formData = new FormData();

      this.model.addedFiles.forEach((file) => formData.append('files', file));
      await (this.filesPromise = userTimeCapsules.postFiles(userCapsuleId, formData));

      await this.getFiles(userCapsuleId);
      if (this.priceWasCalculated) {
        await this.calculatePrice();
      }
      this.model.addedFiles = [];
      this.model.errorMessage = '';
    },
    async getFiles(userCapsuleId) {
      this.filesPromise = userTimeCapsules.getFiles(userCapsuleId);

      try {
        const { data: { data } } = await this.filesPromise;
        this.model.files = data;
      } catch (error) {
        throw new Error('Error fetching files:', error);
      } finally {
        this.filesPromise = null;
      }
    },
    async downloadFile(fileId) {
      this.filesPromise = filesApi.get(fileId);

      try {
        const { data: { data } } = await this.filesPromise;
        saveAs(data);
      } catch (error) {
        throw new Error('Error retrieving file:', error);
      } finally {
        this.filesPromise = null;
      }
    },
    async removeFile(fileId) {
      this.filesPromise = filesApi.remove(fileId);

      try {
        await this.filesPromise;
        await this.getFiles(this.model.capsule.id);
      } catch (error) {
        throw new Error('Error removing file:', error);
      } finally {
        this.filesPromise = null;
      }
    },
  },
};
</script>
