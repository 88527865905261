<template>
  <b-modal
    v-if="model.capsule"
    id="buy-more-storage-modal"
    v-model="isShow"
    title=""
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    size="lg"
    modal-class="_secondary"
    @hidden="onHidden"
  >
    <!-- Payment methods -->
    <div v-show="model.step === 0">
      <h2 class="font-weight-boldest text-primary text-center mb-10">
        {{ model.successMessage ? 'Success': 'Payment Methods' }}
      </h2>

      <div v-show="showBuyButtons">
        <div v-show="!model.successMessage">
          <div class="position-relative">
            <template v-if="model.price">
              <div
                style="height: 54px;"
                class="mb-5"
              >
                <pay-pal-elements
                  ref="payPalElements"
                  :capsule-id="model.capsule.id"
                  @succeeded="onPaypalSucceeded"
                  @failed="onPaymentFailed"
                  @insufficient-space="onInsufficientCapsuleSpace"
                  @throttling="onThrottling"
                />
              </div>

              <p class="text-center">
                or
              </p>

              <div class="mb-5">
                <stripe-elements
                  ref="stripeElements"
                  :capsule-id="model.capsule.id"
                  @succeeded="onStripeSucceeded"
                  @failed="onPaymentFailed"
                  @insufficient-space="onInsufficientCapsuleSpace"
                  @card-valid="onStripeCardValid"
                  @throttling="onThrottling"
                />
              </div>
            </template>

            <!-- Footer Block -->
            <b-row class="flex-wrap-reverse align-items-center">
              <b-col
                md="6"
                class="mb-3 text-center text-md-left"
              >
                <a
                  href=""
                  class="font-weight-bold"
                  @click.prevent="prevStep"
                >
                  {{ model.step !== 0 ? 'Go back' : 'Cancel' }}
                </a>
              </b-col>

              <b-col
                md="6"
                class="mb-3"
              >
                <button
                  id="submit"
                  class="landing-btn w-100"
                  :disabled="model.paymentDisabled"
                  @click="submitStripe"
                >
                  Submit Payment
                </button>
              </b-col>
            </b-row>

            <div
              v-if="model.loadingMessage"
              class="loading-message"
            >
              {{ model.loadingMessage }}
            </div>
          </div>

          <template v-if="model.errorMessage">
            <div class="text-center text-danger">
              <p>
                {{ model.errorMessage }}
              </p>
            </div>
          </template>
        </div>

        <template v-if="model.successMessage">
          <div class="text-center">
            <p>
              {{ model.successMessage }}
            </p>

            <p>
              <button
                class="landing-btn"
                @click="goToCloudCapsules"
              >
                Go to Capsules
              </button>
            </p>
          </div>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { CurrencyFormat, HumanBytesFormat } from '@app/formatters';
import { user as userApi } from '@services/api';
import retry from 'async-retry';
import {
  paymentStatuses,
} from '@constants';
import { RESET_PAYMENTS } from '@store/cloud-capsule-payments.module';
import store from '@store';
import { SET_HAS_CLOUD_CAPSULE } from '@services/store/profile.module';
import StripeElements from './components/stripe-elements';
import PayPalElements from './components/pay-pal-elements';

function getDefaultModel() {
  return {
    step: 0,
    capsule: {
      id: null,
    },
    selectedCapsulePart: null,
    payPalRendered: false,
    agreeWithTerms: false,
    successMessage: '',
    errorMessage: '',
    loadingMessage: '',
    paymentDisabled: true,
    paymentEstablished: false,
    price: null,
  };
}

export default {
  name: 'UserPersonalCabinetBuyMoreStorageModal',
  components: {
    StripeElements,
    PayPalElements,
  },
  emits: ['hidden', 'to-capsules'],
  data() {
    return {
      isShow: false,
      CurrencyFormat,
      HumanBytesFormat,
      model: getDefaultModel(),
    };
  },
  computed: {
    ...mapGetters([
      'isAuthorized',
      'isUser',
    ]),
    showBuyButtons() {
      return this.isAuthorized && this.isUser;
    },
  },
  watch: {
    isShow: {
      handler(newValue) {
        if (this.model.payPalRendered) return;

        if (newValue === true) {
          this.$nextTick(() => {
            this.$refs.payPalElements?.init();
            this.model.payPalRendered = true;
          });
        }
      },
    },
  },
  methods: {
    async show(capsule) {
      this.model.capsule = capsule;
      this.model.price = capsule.price;

      await store.dispatch(RESET_PAYMENTS, { failPayment: false });
      this.isShow = true;
    },
    hide(ev) {
      ev?.preventDefault();
      this.isShow = false;
      this.model.capsule = null;
      this.model.price = null;
      this.model.step = 0;
      this.model.payPalRendered = false;
    },
    async goToCloudCapsules(ev) {
      this.hide(ev);
      this.model = getDefaultModel();
      await store.dispatch(RESET_PAYMENTS, { failPayment: false });
      this.$emit('to-capsules');
    },
    nextStep() {
      this.model.step += 1;
    },
    prevStep() {
      if (this.model.step === 0) {
        this.hide();
        return;
      }
      this.model.step -= 1;
    },
    async submitStripe() {
      this.model.errorMessage = null;
      await this.$refs.stripeElements.submit();
    },
    async onStripeSucceeded(createdCapsuleId) {
      this.model.paymentEstablished = true;
      this.model.loadingMessage = 'Give us a moment. We are verifying your payment';

      this.loadDataPromise = retry(async () => {
        const { data: { data } } = await userApi.userCloudCapsules.getById(createdCapsuleId);
        const status = data.payments[0].paymentStatus;
        if (status === paymentStatuses.pending.key) {
          throw Error(status);
        }

        if (status === paymentStatuses.success.key) {
          await store.dispatch(SET_HAS_CLOUD_CAPSULE);
          this.model.successMessage = 'Transaction completed.';
        }

        if (status === paymentStatuses.failed.key) {
          this.model.errorMessage = 'The payment has failed. Please check your card details and try again.';
          throw Error(status);
        }
      }, {
        retries: 5,
      });

      try {
        setTimeout(async () => {
          await this.loadDataPromise;
        }, 3000);
      } catch {
        this.model.errorMessage = 'We are still processing on your payment. Please check the payment status later on the dashboard.';
      }
    },
    onPaymentFailed(message) {
      this.model.errorMessage = message;
    },
    onInsufficientCapsuleSpace(message) {
      this.model.errorMessage = message;
    },
    onStripeCardValid(valid) {
      this.model.paymentDisabled = !valid;
    },
    async onPaypalSucceeded() {
      await store.dispatch(SET_HAS_CLOUD_CAPSULE);
      this.model.successMessage = 'Transaction completed.';
    },
    onThrottling(message) {
      this.model.errorMessage = message;
    },
    async onHidden() {
      await store.dispatch(RESET_PAYMENTS, { failPayment: !this.model.paymentEstablished });
      this.model = getDefaultModel();
      this.$emit('hidden');
    },
  },
};
</script>
