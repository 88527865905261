import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dictionaries from './dictionaries';
import languages from './languages';

const messages = {
  en: dictionaries.en,
  fr: dictionaries.fr,
};

Vue.use(VueI18n);

const defaultLocale = languages.en;
const fallbackLocale = languages.en;

export default new VueI18n({
  defaultLocale,
  fallbackLocale,
  messages,
});
